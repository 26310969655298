/* eslint-disable no-useless-constructor */
import React, { Component, useEffect } from 'react';
import {setCulture, setCurrencyCode} from '@syncfusion/ej2-base';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { loadCldr, L10n } from '@syncfusion/ej2-base';
import itNumberData from 'cldr-numbers-full/main/it/numbers.json';
import itTimeZoneData from 'cldr-dates-full/main/it/timeZoneNames.json';
import itCAGregorian from 'cldr-dates-full/main/it/ca-gregorian.json';
import itNumberSystems from 'cldr-core/supplemental/numberingSystems.json';
import itCurrencies from 'cldr-numbers-full/main/it/currencies.json';
import itWeekData from 'cldr-core/supplemental/weekData.json';
import itTranslations from '@syncfusion/ej2-locale/src/it.json';
import * as CustomJS from './Custom';
import PaginaRosibot from './components/PaginaRosibot';
import LogoALLC from './assets/AllConsultingGroupLogo.png';
import LogoDP from './assets/LogoDigitalPraesidium.png';
import ImgTestEnv from './assets/AmbienteTest.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import _ from 'underscore/underscore-min';
import { random, isUndefined } from 'underscore';
import Cookies from 'universal-cookie';

let bloccoApplicazione = false;
const cookies = new Cookies();
 
L10n.load(itTranslations);
setCulture('it');
setCurrencyCode('EUR');
loadCldr(itNumberData, itTimeZoneData, itCAGregorian, itNumberSystems, itCurrencies, itWeekData);

if (isUndefined(cookies.get("sipa_rosibot"))) {
    bloccoApplicazione = true;
}
else {    
    let cookieContent = decodeURI(cookies.get("sipa_rosibot"));
    let showCookieSplit = cookieContent.split(","); 
    let showArray = []; 
    if(cookieContent !== ''){
        for(let i=0;i<=3;i++){
            showArray[i] = showCookieSplit[i];
        }
    }
    CustomJS.getRemoteData3(CustomJS.APIUrlProd, 
        { "token": CustomJS.tokenProd, "azione": "checkCookie", "key": showArray[0] },
        'POST', { "Content-Type": "application/x-www-form-urlencoded" }, (retData) => {                                      
            if (retData === false)
                bloccoApplicazione = true;
            else {
                localStorage.setItem("IdReferente", showArray[1]);  
                localStorage.setItem("NominativoReferente", showArray[2].replace('+', ' '));   
            }
    }, false, false);
}

//bloccoApplicazione = false;  //SOLO per test. Commentare in Produzione!!!
//'51' Leone    '212' Rosignuolo     '350' Bruno     '270' Forbicini     '402' Guicciardi   'ADM' Supervisore (vede tutte le compagnie)
//localStorage.setItem("IdReferente", 'ADM');   //SOLO per test. Commentare in Produzione!!! 
//localStorage.setItem("NominativoReferente", 'Amministratore');   //SOLO per test. Commentare in Produzione!!!

CustomJS.getRemoteData2(CustomJS.APIUrlProd,
    `token=${CustomJS.tokenProd}&azione=referentiCompagnia`, 
    'POST', "application/x-www-form-urlencoded", (retData) => {         
        let compagnieReferente = [];
        if (localStorage.getItem("IdReferente") === 'ADM')
            compagnieReferente = _.filter(retData, elm => { return elm.IdCompagnia !== 'Totale Referente'; }).map(elm => elm.IdCompagnia);    
        else
            compagnieReferente = _.filter(retData, elm => { return elm.IdReferente === localStorage.getItem("IdReferente") && elm.IdCompagnia !== 'Totale Referente'; }).map(elm => 
                                 elm.IdCompagnia); 
        localStorage.setItem("CompagnieReferente", JSON.stringify(compagnieReferente));    
        CustomJS.getRemoteData2(CustomJS.APIUrlProd,
            `token=${CustomJS.tokenProd}&azione=compagnie`, 
            'POST', "application/x-www-form-urlencoded", (retData2) => {                  
                retData2 = _.filter(retData2, elm => { return !CustomJS.isNullEmpty(_.find(JSON.parse(localStorage.getItem("CompagnieReferente")), elm2 => { return elm2 ===  elm.IdCompagnia })) });
                if (localStorage.getItem("IdReferente") === 'ADM') 
                    retData2.unshift({ IdCompagnia: 999, DescrizioneCompagnia: 'Tutte le Compagnie'});
                localStorage.setItem("CompagnieSelezionate", JSON.stringify(retData2));      
        }, true, false);                     
}, true, false);

export default class App extends Component {
    constructor() {
        super(...arguments);              
    }  
    state = {                
    } 

    render() {
        if (bloccoApplicazione) {
            return(<React.Fragment>
                <div className='control-section card-control-section basic_card_layout'>
                    <ToastContainer style={{ fontSize: '2.1vw', color: 'purple', fontWeight: 'bold', width: '50%' }}></ToastContainer> 
                    <div className='row'>                                                                                               
                        <div className="col-xs-2 col-sm-2 col-md-2 col-lg-3">
                            <img src={LogoALLC} alt='' width='80%' height='auto'/>                                         
                        </div>                                                          
                    </div>
                </div>
            </React.Fragment>);
        }
        return (
            <React.Fragment>   
                <div className='control-section card-control-section basic_card_layout'>                    
                    <div className="e-card-resize-container">
                        <div className="card-layout" >
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                <div className="e-card" id="basic_card" >                                        
                                    <div className='row'>                                                                                               
                                        <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                            <img src={LogoALLC} alt='' width='350' height='auto'/>    
                                        </div>
                                        <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8 text-center" style={{ paddingTop: '20px'}}>   
                                            <span style={{ fontSize: '3.4em', fontWeight: 'bolder', color:'#133B95'}}>Rosibot</span>    
                                            &nbsp;&nbsp;&nbsp;                     
                                            <span style={{ fontSize: '0.8em', fontWeight: 'bold' }}>Rel. 1.2.7</span>   
                                            {/* <img src={ImgTestEnv} alt='' width='15%' height='auto'/>                                         */}
                                        </div>  
                                        <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 text-center">
                                            <span style={{ fontSize: '0.9em' }}>Powered by&nbsp;&nbsp;</span>
                                            <img src={LogoDP} alt='' width='110' height='auto'/>  
                                            <br/>
                                        </div>                     
                                    </div>   
                                    <div className='row'>
                                        <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                                            <ButtonComponent id='BtnCaricaPagina' alt='Ricarica pagina Rosibot' style={{ fontWeight: '600' }} cssClass='e-normal'>Ricarica pagina</ButtonComponent>  
                                            <PaginaRosibot></PaginaRosibot>
                                        </div>    
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>   
            </React.Fragment>                                        
        );
    }      
    componentDidMount() {   
        if (bloccoApplicazione) {
            toast.warn("ATTENZIONE!! NON SEI ABILITATO/A A VISUALIZZARE IL ROSIBOT DI ALL CONSULTING!! L'APPLICAZIONE E' VISIBILE SOLO DALL'INTERNO DEL SISTEMA SIPA...", {
                position: "top-center",
                autoClose: false,                
                hideProgressBar: true,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: false,
                rtl: false,
                pauseOnFocusLoss: true
            });
        }  
        else
            document.getElementById('BtnCaricaPagina').addEventListener('click',(e) => {                       
                window.location.reload();
            });         
    }
}
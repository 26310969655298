/* eslint-disable default-case */
import * as React from 'react';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import { TabComponent, TabItemDirective, TabItemsDirective, TabAnimationSettings } from '@syncfusion/ej2-react-navigations';
import { AccordionComponent, AccordionItemDirective, AccordionItemsDirective } from '@syncfusion/ej2-react-navigations';
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, CommandColumn, ExcelExport, PdfExport, Page, Filter, Inject, Sort } from '@syncfusion/ej2-react-grids';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { RichTextEditorComponent, HtmlEditor, Toolbar as ToolbarRTE, Table, Image, Link, QuickToolbar, ToolbarType } from '@syncfusion/ej2-react-richtexteditor';
import { Query, DataManager } from '@syncfusion/ej2-data';
import { CheckBoxComponent, RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import { ProgressButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { SampleBase } from './sample-base';
import * as CustomJS from '../Custom';
import LogoRB from '../assets/logo.gif';
import { random, isUndefined, filter } from 'underscore';
import _ from 'underscore/underscore-min';
import $ from 'jquery';
import BlockUi from '@availity/block-ui';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Moment from 'react-moment';
import moment from 'moment';
import SigleTipiSinistro from '../assets/SigleTipiSinistro.json';

const APIRosibotURL = CustomJS.APIRosibotURLProd;
let dataCorr = new Date();
let dataInizioAnno = '1/1/' + dataCorr.getFullYear().toString();
let dataCorrente = new Date().toDateString();
let waitingTime = 300000000;
const messaggioNoBackend = 'Attenzione!! Nessuna comunicazione attiva con il backend. Per utilizzare le funzionalità di Rosibot, escluso "Visualizza Dati", è necessario attivare API Caller.'
const messaggioAPIBackendNonTrovata = 'Attenzione!! Comunicazione attiva con il backend ma API non trovata!!';
let doc = document.documentElement;
let top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
let left = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
let modelloHeaderEmail = 'Ciao {0},<br><br>ti invio un reminder sulle tue pratiche più urgenti:<br><br>';
let modelloHeaderEmailSurveyor = 'Ciao {0},<br><br>ti invio un reminder sulle pratiche più urgenti per le quali è necessario ricevere le foto da parte dei Surveyor:<br><br>';
let modelloHeaderEmailAllianzCRR50 = '******** ALLIANZ RAMI 50/CREDIT RAS ********<br><br><br>Ciao {0},<br><br>ti invio un reminder sulle tue pratiche più urgenti.<br><br>Ricordo che la prima scadenza, la cui chiusura è inderogabile per cui sono a disposizione per individuare soluzioni ad eventuali<br>questioni ostative alla definizione, è a <b>30 giorni</b> dalla data di incarico allo studio.<br><br>La seconda scadenza è a <b>45 giorni</b> dalla data di incarico (anche questa scadenza è parte degli obiettivi monitorati dalla Compagnia).<br><br><br>';
let modelloHeaderEmailAllianzALTRO = 'Ciao {0},<br><br>queste pratiche risultano aperte da oltre <b>60 giorni</b>.<br>Ti prego di mandarmi un aggiornamento sulla situazione e sui previsti tempi di chiusura.<br><br><br>';
let modelloHeaderEmailInterloc = 'Ciao {0},<br><br>ti invio un reminder sulle tue pratiche più urgenti, per quanto riguarda le interlocutorie:<br><br>';
let modelloFooterEmail = '<br><br>Nel caso ci fossero difficoltà a rispettare la scadenza è necessario contattare immediatamente il referente.<br><br>Saluti<br><br>&nbsp;&nbsp;&nbsp;{0}';
let modelloFooterEmailSurveyor = '<br><br>Saluti<br><br>&nbsp;&nbsp;&nbsp;{0}';
let modelloFooterEmailAllianzALTRO = '<br><br>Saluti<br><br>&nbsp;&nbsp;&nbsp;{0}';
let modelloFooterEmailInterloc = '<br><br>Nel caso ci fossero difficoltà a rispettare la scadenza è necessario contattare immediatamente il referente.<br><br>Saluti<br><br>&nbsp;&nbsp;&nbsp;{0}';
let modelloEmailInScadenza = '<b>{0} ({1}) {2}</b> da chiudere entro <b>{3} {4}</b>';
let modelloEmailPrimaScadenza = '<b>{0} ({1}) {2}</b> la prima scadenza è già passata, quindi ti prego di fare il possibile per chiuderla entro <b>{3} {4}</b>';
let modelloEmailSecondaScadenza = '<b>{0} ({1}) {2}</b> scaduto <b>{3} {4}</b> <-- DA CHIUDERE AL PIU\' PRESTO!!';
let modelloEmailPreavvScadUltimaInterloc = '<b>{0} {1}</b> LA DATA DELL\'ULTIMA INTERLOCUTORIA SI STA AVVICINANDO AI {2} GIORNI DI SCADENZA!!';
let modelloEmailScadUltimaInterloc = '<b>{0} {1}</b> LA DATA DELL\'ULTIMA INTERLOCUTORIA HA SUPERATO I {2} GIORNI DI SCADENZA!!';
let modelloEmailScadenzaSurveyor = '<b>{0} {1}</b> è stato assegnato <b>{2}</b> giorni fa al Surveyor <b>{3}</b>, quindi ti prego di fare il possibile affinché le foto vengano inviate al più presto!!';
let testoEmailPerito = '';
let indirizzoEmailPerito = '';
let soggettoEmailPerito = '{0} - avviso scadenze sinistri';
let soggettoEmailSurveyor = '{0} - avviso scadenze sinistri per foto Surveyor';
let soggettoEmailPeritoAllianz = '{0} - Avviso scadenze sinistri - Rami 50 e Credit RAS';
let modelloEmailPrimaScadSenzaInterloc = '<b>{0} {1}</b> NON C\'È LA PRIMA INTERLOCUTORIA OBBLIGATORIA A {2} GIORNI!!';
let modelloEmailSecondaScadSenzaInterloc = '<b>{0} {1}</b> NON C\'È LA PRIMA INTERLOCUTORIA, OBBLIGATORIA A {2} GIORNI E L\'INCARICO SUPERA I {3} GIORNI!!';
let soggettoEmailPeritoInterloc = '{0} - Avviso scadenze interlocutorie sinistri', testoCronologia = '';
let titoloCronologia = 'Cronologia risposte del perito {0} per il sinistro {1}';
let titoloEmailAvviso = 'Invio Email Avviso a Perito {0}'
let indScorrEmail = 0;
let soloPrimaVolta = true;

// eslint-disable-next-line no-extend-native
String.prototype.format2 = function() {
    let a = this;
    for (let k in arguments) {
      a = a.replace("{" + k + "}", arguments[k])
    }
    return a
}

export default class PaginaRosibot extends SampleBase {
    constructor() {
        super(...arguments);         
        this.accordHeader = ['Mostra/Nascondi Parametri Invio Email'];  
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.loaderType = ['line-scale-pulse-out-rapid', 'line-scale', 'line-scale-pulse-out', 'ball-pulse-sync', 'ball-pulse', 'ball-beat', 
                           'line-scale-party']; //loadTypes[random(loadTypes.length-1)].type;  
        this.loaderType2 = ['Ball-Triangle', 'Bars', 'Circles', 'Grid', 'Puff', 'Rings', 'TailSpin', 'ThreeDots', 'Oval', 'MutatingDots', 'RevolvingDot'];
        this.toasts = [{ title: 'Attenzione!', content: 'Nessun dato estratto con i parametri selezionati!!', cssClass: 'e-toast-warning', timeOut: 4000, icon: 'e-warning toast-icons' },
                       { title: 'Success!', content: '', cssClass: 'e-toast-success', icon: 'e-success toast-icons', timeOut: 4000 },
                       { title: 'Error!', content: '', cssClass: 'e-toast-danger', icon: 'e-error toast-icons', timeOut: 4000 },
                       { title: 'Informazione!', content: 'Nessun dato estratto con i parametri selezionati!!', cssClass: 'e-toast-info', icon: 'e-info toast-icons', timeOut: 4000 }];
        this.position = { X: 'Center', Y: 'Top' };
        this.fields = { text: 'Text', value: 'Value' }; 
        this.ddlRamoAllianz = [{ Value: 'CREDRASRAMO50', Text: 'Credit Ras/Ramo 50'}, { Value: 'ALTRO', Text: 'Altro'}]
        this.toolbarOptions = ['ExcelExport', 'PdfExport', 'CsvExport', 'Search', 
                                { text: 'Invio Email di avviso al perito selezionato', prefixIcon: "fas fa-paper-plane", id: "invioavvisoperito", disabled: true },
                                { template: this.ddlPeritiToolbar.bind(this) }, { text: 'Visualizza info sul perito selezionato', prefixIcon: "fa fa-eye", id: "vediinfoperito", disabled: true }]; 
        this.toolbarOptions2 = ['ExcelExport', 'PdfExport', 'CsvExport', 'Search'];
        this.filterSettings = { type: 'CheckBox' };
        this.commands = [{ type: 'Cronologia risposte', buttonOption: { iconCss: 'fas fa-history', cssClass: 'e-flat' } }];
        this.itemsRTE = ['Bold', 'Italic', 'Underline', 'StrikeThrough',
            'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
            'LowerCase', 'UpperCase', 'SuperScript', 'SubScript', '|',
            'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
            'Outdent', 'Indent', '|',
            'CreateTable', 'CreateLink', 'Image', '|', 'ClearFormat', 'Print',
            'SourceCode', '|', 'Undo', 'Redo'
        ];
        this.toolbarSettingsRTE = {
            type: ToolbarType.MultiRow,            
            enableFloating: false,
            items: this.itemsRTE
        };
        this.buttons = [{
            click: () => {
                this.invioEmailAvvisoPerito();
                if (this.chkboxInstance2.checked === false) 
                    this.dialogInstance.hide();
                //---- Patch per nascondere questi elementi creati dall'oggetto RTE ---------
                //---- Trovare soluzione definitiva
                setTimeout(() => { 
                    document.getElementById('btnRicParamEmail').ej2_instances[0].click();
                    $('[id=cronologiaRTE_toolbar_BackgroundColor_Target]').hide();
                    $('[id=cronologiaRTE_toolbar_FontColor_Target]').hide();
                    $('[id=emailRTE_toolbar_FontColor_Target]').hide();
                    $('[id=emailRTE_toolbar_BackgroundColor_Target]').hide(); 
                    this.RTEInstance2.value = testoCronologia.replace(/&amp;lt;/g, '<').replace(/&amp;gt;/g, '>').replace(/&quot;/g, '"');
                }, 1800);
                //---------------------------------------------------------------------------
            },
            buttonModel: {
                content: 'INVIA EMAIL',
                cssClass: 'e-primary',
                isPrimary: true
            }
        },
        {
            click: () => {
                this.dialogInstance.hide();
            },
            buttonModel: {
                content: 'ANNULLA',
            }
        }];
        this.buttons2 = [{
            click: () => {
                this.salvaCronologiaPerito();
                this.caricaCronologie(true);                
            },
            buttonModel: {
                content: 'SALVA CRONOLOGIA',
                cssClass: 'e-success',
                isPrimary: false
            }
        },
        {
            click: () => {
                testoEmailPerito = '';    
                this.dialogInstance.header = titoloEmailAvviso.format2(this.salvaPeritoCronologia);
                this.dialogInstance.buttons = this.buttons;          
                this.dialogInstance.show();
            },
            buttonModel: {
                content: 'SCRIVI EMAIL',
                cssClass: 'e-primary',
                isPrimary: true
            }
        },
        {
            click: () => {
                this.dialogInstance2.hide();
            },
            buttonModel: {
                content: 'ANNULLA',
            }
        }];
        this.buttons3 = [{
            click: () => {
                this.dialogInstance3.hide();
            },
            buttonModel: {
                content: 'CHIUDI',
                cssClass: 'e-primary',
                isPrimary: true
            }
        }];
        this.buttons4 = [{
            click: () => {
                this.dialogInstance4.hide();
            },
            buttonModel: {
                content: 'CHIUDI',
                cssClass: 'e-primary',
                isPrimary: true
            }
        }];
        this.buttons5 = [{
            click: () => {
                this.creaEmailTuttiPeriti(true); 
                this.dialogInstance5.hide();            
            },
            buttonModel: {
                content: 'INVIA A TUTTI',
                cssClass: 'e-control e-btn e-info',
                isPrimary: false
            }
        },
        {
            click: () => {  
                this.toggleBlocking();
                this.creaEmailTuttiPeriti(false); 
                setTimeout(() => {
                    this.dialogInstance5.hide();
                    indScorrEmail = 0;
                    if (_.isObject(this.elencoEmailPeriti[indScorrEmail])) {
                        testoEmailPerito = this.elencoEmailPeriti[indScorrEmail].TestoEmailPerito;
                        indirizzoEmailPerito = this.elencoEmailPeriti[indScorrEmail].IndirizzoEmailPerito;
                        this.dialogInstance.header = titoloEmailAvviso.format2(this.elencoEmailPeriti[indScorrEmail].NominativoPerito);
                        this.dialogInstance.buttons = this.buttons6; 
                        this.dialogInstance.show();                       
                        //this.setState({ showDialog: true });
                    }  
                    this.toggleBlocking();  
                    //---- Patch per nascondere questi elementi creati dall'oggetto RTE ---------
                    //---- Trovare soluzione definitiva
                    document.getElementById('btnRicParamEmail').ej2_instances[0].click();
                    $('[id=cronologiaRTE_toolbar_BackgroundColor_Target]').hide();
                    $('[id=cronologiaRTE_toolbar_FontColor_Target]').hide();
                    $('[id=emailRTE_toolbar_FontColor_Target]').hide();
                    $('[id=emailRTE_toolbar_BackgroundColor_Target]').hide();
                    //---------------------------------------------------------------------------
                }, 3500);
                setTimeout(() => {
                    this.dialogInstance.btnObj[1].disabled = true;
                }, 3800);
            },
            buttonModel: {
                content: 'SCEGLI PER OGNUNO',
                cssClass: 'e-control e-btn e-warning',
                isPrimary: true
            }
        },
        {
            click: () => {
                this.dialogInstance5.hide();
            },
            buttonModel: {
                content: 'ANNULLA',
                isPrimary: true
            }
        }];
        this.buttons6 = [{
            click: () => {
                this.invioEmailAvvisoPerito();
                if (this.chkboxInstance2.checked === false) {
                    indScorrEmail++;
                    if (_.isObject(this.elencoEmailPeriti[indScorrEmail])) {
                        testoEmailPerito = this.elencoEmailPeriti[indScorrEmail].TestoEmailPerito;
                        indirizzoEmailPerito = this.elencoEmailPeriti[indScorrEmail].IndirizzoEmailPerito;
                        this.dialogInstance.header = titoloEmailAvviso.format2(this.elencoEmailPeriti[indScorrEmail].NominativoPerito);
                        this.dialogOpen();
                    }     
                }
                //---- Patch per nascondere questi elementi creati dall'oggetto RTE ---------
                //---- Trovare soluzione definitiva
                setTimeout(() => {                    
                    $('[id=cronologiaRTE_toolbar_BackgroundColor_Target]').hide();
                    $('[id=cronologiaRTE_toolbar_FontColor_Target]').hide();
                    $('[id=emailRTE_toolbar_FontColor_Target]').hide();
                    $('[id=emailRTE_toolbar_BackgroundColor_Target]').hide();
                    if (_.isObject(this.elencoEmailPeriti[indScorrEmail]))
                        this.RTEInstance.value = this.elencoEmailPeriti[indScorrEmail]?.TestoEmailPerito; //testoEmailPerito.replace(/&amp;lt;/g, '<').replace(/&amp;gt;/g, '>').replace(/&quot;/g, '"');
                    else
                        this.RTEInstance.value = this.elencoEmailPeriti[indScorrEmail-1]?.TestoEmailPerito;
                    //document.getElementById('emailRTE').ej2_instances[0].value = testoEmailPerito.replace(/&amp;lt;/g, '<').replace(/&amp;gt;/g, '>').replace(/&quot;/g, '"');
                    document.getElementById('btnRicParamEmail').ej2_instances[0].click();
                }, 1800);
                //---------------------------------------------------------------------------
            },
            buttonModel: {
                content: 'INVIA EMAIL',
                cssClass: 'e-primary',
                isPrimary: true
            }
        },
        {
            click: () => {
                if (indScorrEmail > 0) {
                    indScorrEmail--;
                    if (_.isObject(this.elencoEmailPeriti[indScorrEmail])) {
                        testoEmailPerito = this.elencoEmailPeriti[indScorrEmail].TestoEmailPerito;
                        indirizzoEmailPerito = this.elencoEmailPeriti[indScorrEmail].IndirizzoEmailPerito;
                        this.dialogInstance.header = titoloEmailAvviso.format2(this.elencoEmailPeriti[indScorrEmail].NominativoPerito);
                        if (indScorrEmail === 0)
                            this.dialogInstance.btnObj[1].disabled = true;
                        else
                            this.dialogInstance.btnObj[1].disabled = false;
                        this.dialogInstance.btnObj[2].disabled = false;
                        this.dialogOpen();
                    }     
                }                                   
            },
            buttonModel: {
                content: 'PRECEDENTE',
                cssClass: 'e-info',
            }
        },
        {
            click: () => {
                indScorrEmail++;
                if (_.isObject(this.elencoEmailPeriti[indScorrEmail])) {
                    testoEmailPerito = this.elencoEmailPeriti[indScorrEmail].TestoEmailPerito;
                    indirizzoEmailPerito = this.elencoEmailPeriti[indScorrEmail].IndirizzoEmailPerito;
                    this.dialogInstance.header = titoloEmailAvviso.format2(this.elencoEmailPeriti[indScorrEmail].NominativoPerito);
                    this.dialogInstance.btnObj[1].disabled = false;
                    if (indScorrEmail === this.elencoEmailPeriti.length - 1)
                        this.dialogInstance.btnObj[2].disabled = true;
                    else
                        this.dialogInstance.btnObj[2].disabled = false;
                    this.dialogOpen();
                }                    
            },
            buttonModel: {
                content: 'SUCCESSIVO',
                cssClass: 'e-info',
            }
        },
        {
            click: () => {
                this.dialogInstance.hide();
            },
            buttonModel: {
                content: 'ANNULLA',
            }
        }];
        this.animationSettings = { effect: 'FadeZoom' };
        this.sinistroCronologia = '';
        this.dsInfoTuttiPeriti = [];
        this.elencoEmailPeriti = [];
        this.salvaPeritoCronologia = '';
        this.ricaricaGriglia = true;
    }  
    Filter = { type: 'CheckBox' }
    state = {
        blocking: false,
        showDialog: false,
        showDialog2: false,
        showDialog3: false,
        showDialog4: false,
        showDialog5: false
    } 
    toggleBlocking() {        
        this.setState({blocking: !this.state.blocking});
    }   
    ddlPeritiToolbar() {
        let IdComp = JSON.parse(localStorage.getItem("CompagnieSelezionate"))[document.getElementById('TabRosibot').ej2_instances[0].selectedItem].IdCompagnia;
        let IdDDL = `ddlPeriti${IdComp}`;
        return (
            <DropDownListComponent id={IdDDL} fields={this.fields} index='0' change={this.onChangePeriti.bind(this)} allowFiltering={true} filterType='Contains'
                   style={{width: '12vw'}} placeholder="Selezionare un perito da filtrare" floatLabelType='always' popupHeight="450px" cssClass='e-outline'/>                
        );        
    }
    onChangePeriti(args) {
        let IdComp = JSON.parse(localStorage.getItem("CompagnieSelezionate"))[document.getElementById('TabRosibot').ej2_instances[0].selectedItem].IdCompagnia;
        let tipoOper = localStorage.getItem('tipoOperativita');
        document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].clearFiltering();
        if (CustomJS.isNullEmpty(args.value)) {            
            document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].search();
            document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].toolbarModule.enableItems(['invioavvisoperito'], false);
            if (tipoOper === 'S')
                document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].toolbarModule.enableItems(['vediinfoperito'], false);
            document.getElementById(`grigliaSinistri${IdComp}_searchbar`).disabled = false;
        }
        else {
            document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].search(args.itemData.Text);  
            document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].toolbarModule.enableItems(['invioavvisoperito'], true);
            if (tipoOper === 'S' && !document.getElementById(`chkGestioneRamo${IdComp}`).ej2_instances[0].checked)
                document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].toolbarModule.enableItems(['vediinfoperito'], true);
            else
                document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].toolbarModule.enableItems(['vediinfoperito'], false);
            document.getElementById(`grigliaSinistri${IdComp}_searchbar`).disabled = true;
        }  
        setTimeout(() => { this.scrollAuto(1000); }, 1000);
    } 
    onChangeRamo(args) {
        let IdComp = JSON.parse(localStorage.getItem("CompagnieSelezionate"))[document.getElementById('TabRosibot').ej2_instances[0].selectedItem].IdCompagnia;                                
        document.getElementById(`ddlPeriti${IdComp}`).ej2_instances[0].value = null;
        document.getElementById(`ddlPeriti${IdComp}`).ej2_instances[0].text = null;
        document.getElementById(`btnVisualDati${IdComp}`).ej2_instances[0].click();
    }
    onChangeChkRamo(args) {
        let IdComp = JSON.parse(localStorage.getItem("CompagnieSelezionate"))[document.getElementById('TabRosibot').ej2_instances[0].selectedItem].IdCompagnia;
        if (args.checked) {
            document.getElementById(`divDdlGestioneRamo${IdComp}`).style.removeProperty('display'); 
            document.getElementById(`divPrimaScadCRR50${IdComp}`).style.removeProperty('display');
            document.getElementById(`divSecondaScadCRR50${IdComp}`).style.removeProperty('display');
            document.getElementById(`divPreavvisoScadCRR50${IdComp}`).style.removeProperty('display');
            document.getElementById(`divScadAltro${IdComp}`).style.removeProperty('display');
            document.getElementById(`scad1TipoS${IdComp}`).style.visibility = 'hidden';
            document.getElementById(`scad2TipoS${IdComp}`).style.visibility = 'hidden';
            document.getElementById(`scad3TipoS${IdComp}`).style.visibility = 'hidden';            
            document.getElementById(`btnSalvaScadTipoS${IdComp}`).style.visibility = 'hidden';
        }
        else {
            document.getElementById(`divDdlGestioneRamo${IdComp}`).style.display = 'none';  
            document.getElementById(`divPrimaScadCRR50${IdComp}`).style.display = 'none';
            document.getElementById(`divSecondaScadCRR50${IdComp}`).style.display = 'none';
            document.getElementById(`divPreavvisoScadCRR50${IdComp}`).style.display = 'none';
            document.getElementById(`divScadAltro${IdComp}`).style.display = 'none';
            document.getElementById(`scad1TipoS${IdComp}`).style.removeProperty('visibility');
            document.getElementById(`scad2TipoS${IdComp}`).style.removeProperty('visibility');
            document.getElementById(`scad3TipoS${IdComp}`).style.removeProperty('visibility');
            document.getElementById(`btnSalvaScadTipoS${IdComp}`).style.removeProperty('visibility');
        }  
        setTimeout(() => { 
            document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].dataSource = []; 
            document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].refresh();
            document.getElementById(`ddlPeriti${IdComp}`).ej2_instances[0].dataSource = [];                        
            document.getElementById(`ddlPeriti${IdComp}`).ej2_instances[0].value = null;
            document.getElementById(`ddlPeriti${IdComp}`).ej2_instances[0].text = null;
            document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].toolbarModule.enableItems(['invioavvisoperito'], false);
        }, 100);  
    }
    onChangeChkOrdGravInterloc(args) {
        let IdComp = JSON.parse(localStorage.getItem("CompagnieSelezionate"))[document.getElementById('TabRosibot').ej2_instances[0].selectedItem].IdCompagnia;
        document.getElementById(`btnVisualDati${IdComp}`).ej2_instances[0].click();
    }
    tabSelected(args) {    
        for (let ind=0;ind<=waitingTime;ind++); 
        let IdComp = JSON.parse(localStorage.getItem("CompagnieSelezionate"))[document.getElementById('TabRosibot').ej2_instances[0].selectedItem]?.IdCompagnia;
        let tipoOper = localStorage.getItem('tipoOperativita');    
        CustomJS.callAPI(`${APIRosibotURL}/api/Rosibot/leggiParametriScadComp/${IdComp}/${tipoOper}`, '', 'GET',
            '', retData => {    
                if (retData.length > 0) {  
                    setTimeout(() => {    
                        if (tipoOper === 'S') {
                            document.getElementById(`rigaScadenzeTipoS${IdComp}`).style.removeProperty('display');
                            document.getElementById(`rigaScadenzeTipoI${IdComp}`).style.display = 'none'; 
                            document.getElementById(`rigaScadenzeTipoSurv${IdComp}`).style.display = 'none';  
                            document.getElementById(`divBtnSituazPeriti${IdComp}`).style.removeProperty('display');
                            document.getElementById(`divOrdGravInterloc${IdComp}`).style.display = 'none';                      
                            document.getElementById(`primaScadenza${IdComp}`).ej2_instances[0].value = retData[0].prima_scadenza; 
                            document.getElementById(`secondaScadenza${IdComp}`).ej2_instances[0].value = retData[0].seconda_scadenza; 
                            document.getElementById(`giorniPreavviso${IdComp}`).ej2_instances[0].value = retData[0].giorni_preavviso;
                        }
                        else if (tipoOper === 'I') {
                            document.getElementById(`rigaScadenzeTipoS${IdComp}`).style.display = 'none';
                            document.getElementById(`rigaScadenzeTipoI${IdComp}`).style.removeProperty('display');
                            document.getElementById(`rigaScadenzeTipoSurv${IdComp}`).style.display = 'none';
                            document.getElementById(`divBtnSituazPeriti${IdComp}`).style.display = 'none';
                            document.getElementById(`divOrdGravInterloc${IdComp}`).style.removeProperty('display');  
                            document.getElementById(`scadenzaUltimaInterloc${IdComp}`).ej2_instances[0].value = retData[0].scadenza_ultima_interloc;
                            document.getElementById(`primaScadSenzaInterloc${IdComp}`).ej2_instances[0].value = retData[0].prima_scadenza; 
                            document.getElementById(`secondaScadSenzaInterloc${IdComp}`).ej2_instances[0].value = retData[0].seconda_scadenza; 
                            document.getElementById(`preavvisoScadUltimaInterloc${IdComp}`).ej2_instances[0].value = retData[0].giorni_preavviso;
                        }
                        else if (tipoOper === 'SURV') {
                            document.getElementById(`rigaScadenzeTipoS${IdComp}`).style.display = 'none';
                            document.getElementById(`rigaScadenzeTipoI${IdComp}`).style.display = 'none';
                            document.getElementById(`rigaScadenzeTipoSurv${IdComp}`).style.removeProperty('display');
                            document.getElementById(`divBtnSituazPeriti${IdComp}`).style.display = 'none';
                            document.getElementById(`divOrdGravInterloc${IdComp}`).style.display = 'none';  
                            document.getElementById(`primaScadenzaSurv${IdComp}`).ej2_instances[0].value = retData[0].prima_scadenza;
                            document.getElementById(`secondaScadenzaSurv${IdComp}`).ej2_instances[0].value = retData[0].seconda_scadenza;
                        }
                    }, 200);
                }   
                else {
                    setTimeout(() => {    
                        if (tipoOper === 'S') {
                            document.getElementById(`rigaScadenzeTipoS${IdComp}`).style.removeProperty('display');
                            document.getElementById(`rigaScadenzeTipoI${IdComp}`).style.display = 'none';  
                            document.getElementById(`rigaScadenzeTipoSurv${IdComp}`).style.display = 'none'; 
                            document.getElementById(`divBtnSituazPeriti${IdComp}`).style.removeProperty('display');
                            document.getElementById(`divOrdGravInterloc${IdComp}`).style.display = 'none';
                        }
                        else if (tipoOper === 'I') {
                            document.getElementById(`rigaScadenzeTipoS${IdComp}`).style.display = 'none';
                            document.getElementById(`rigaScadenzeTipoI${IdComp}`).style.removeProperty('display');
                            document.getElementById(`rigaScadenzeTipoSurv${IdComp}`).style.display = 'none'; 
                            document.getElementById(`divBtnSituazPeriti${IdComp}`).style.display = 'none';
                            document.getElementById(`divOrdGravInterloc${IdComp}`).style.removeProperty('display');
                        }
                        else if (tipoOper === 'SURV') {
                            document.getElementById(`rigaScadenzeTipoS${IdComp}`).style.display = 'none';
                            document.getElementById(`rigaScadenzeTipoI${IdComp}`).style.display = 'none';
                            document.getElementById(`rigaScadenzeTipoSurv${IdComp}`).style.removeProperty('display'); 
                            document.getElementById(`divBtnSituazPeriti${IdComp}`).style.display = 'none';
                            document.getElementById(`divOrdGravInterloc${IdComp}`).style.display = 'none';
                        }
                    }, 200);
                }  
                if (tipoOper !== document.getElementById(`tipoOperCaricGriglia${IdComp}`).value) {
                    setTimeout(() => { 
                        document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].dataSource = []; 
                        document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].refresh();
                        document.getElementById(`ddlPeriti${IdComp}`).ej2_instances[0].dataSource = [];                        
                        document.getElementById(`ddlPeriti${IdComp}`).ej2_instances[0].value = null;
                        document.getElementById(`ddlPeriti${IdComp}`).ej2_instances[0].text = null;
                        document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].toolbarModule.enableItems(['invioavvisoperito'], false);
                    }, 100);
                }                    
                setTimeout(() => { this.scrollAuto(1000); }, 800);      
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? messaggioAPIBackendNonTrovata : messaggioNoBackend;
                toast.warn(messaggio, {
                    onClose: () => toast.dismiss(),                    
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,            
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true})
        }, true, false);
        setTimeout(() => {
            if (!CustomJS.isNullEmpty(_.find(CustomJS.compagnieGestioneRamo, elm => { return elm === IdComp.toString()}))
                && document.getElementById('tipoOper1').checked) {       
                document.getElementById(`divChkGestioneRamo${IdComp}`).style.removeProperty('display');
                if (document.getElementById(`chkGestioneRamo${IdComp}`).ej2_instances[0].checked) {
                    document.getElementById(`divDdlGestioneRamo${IdComp}`).style.removeProperty('display');
                    document.getElementById(`divPrimaScadCRR50${IdComp}`).style.removeProperty('display');
                    document.getElementById(`divSecondaScadCRR50${IdComp}`).style.removeProperty('display');
                    document.getElementById(`divPreavvisoScadCRR50${IdComp}`).style.removeProperty('display');
                    document.getElementById(`divScadAltro${IdComp}`).style.removeProperty('display');
                }
            }
            else {
                document.getElementById(`chkGestioneRamo${IdComp}`).ej2_instances[0].checked = false;
                document.getElementById(`divChkGestioneRamo${IdComp}`).style.display = 'none';           
                document.getElementById(`divDdlGestioneRamo${IdComp}`).style.display = 'none'; 
                document.getElementById(`divPrimaScadCRR50${IdComp}`).style.display = 'none';
                document.getElementById(`divSecondaScadCRR50${IdComp}`).style.display = 'none';
                document.getElementById(`divPreavvisoScadCRR50${IdComp}`).style.display = 'none';
                document.getElementById(`divScadAltro${IdComp}`).style.display = 'none';
            }
        }, 300);
    }
    tabCreated() {
        let itemsData = [];
        JSON.parse(localStorage.getItem("CompagnieSelezionate")).forEach((elm,idx) => {
            let idComp = elm.IdCompagnia;
            let funcName = `tabContent${idComp}`;
            let numBoxID1 = `primaScadenza${idComp}`;
            let numBoxID2 = `secondaScadenza${idComp}`;
            let numBoxID3 = `giorniPreavviso${idComp}`;
            let numBoxID4 = `scadenzaUltimaInterloc${idComp}`;
            let numBoxID5 = `preavvisoScadUltimaInterloc${idComp}`;
            let numBoxID6 = `primaScadSenzaInterloc${idComp}`;
            let numBoxID7 = `secondaScadSenzaInterloc${idComp}`;
            let numBoxID8 = `primaScadenzaSurv${idComp}`;
            let numBoxID9 = `secondaScadenzaSurv${idComp}`;
            let numBoxID10 = `primaScadCRR50${idComp}`;
            let numBoxID11 = `secondaScadCRR50${idComp}`;
            let numBoxID12 = `scadAltro${idComp}`;
            let numBoxID13 = `preavvisoScadCRR50${idComp}`;
            let btnSalvaScad = `btnSalvaScad${idComp}`;
            let btnSalvaScad2 = `btnSalvaScad2${idComp}`;
            let btnSalvaScad3 = `btnSalvaScad3${idComp}`;
            let btnVisualDati = `btnVisualDati${idComp}`;
            let btnSituazPeriti = `btnSituazPeriti${idComp}`;
            let divBtnSituazPeriti = `divBtnSituazPeriti${idComp}`
            let chkOrdGravInterloc = `chkOrdGravInterloc${idComp}`;            
            let divOrdGravInterloc = `divOrdGravInterloc${idComp}`;
            let chkGestioneRamo = `chkGestioneRamo${idComp}`;
            let divPrimaScadCRR50 = `divPrimaScadCRR50${idComp}`;
            let divSecondaScadCRR50 = `divSecondaScadCRR50${idComp}`;
            let divScadAltro = `divScadAltro${idComp}`;
            let divPreavvisoScadCRR50 = `divPreavvisoScadCRR50${idComp}`;
            let divChkGestioneRamo = `divChkGestioneRamo${idComp}`;
            let ddlGestioneRamo = `ddlGestioneRamo${idComp}`;
            let divDdlGestioneRamo = `divDdlGestioneRamo${idComp}`;
            let btnEmailTuttiPeriti = `btnEmailTuttiPeriti${idComp}`;
            let grigliaSinistri = `grigliaSinistri${idComp}`;
            let rigaScadenzeTipoS = `rigaScadenzeTipoS${idComp}`;
            let scad1TipoS = `scad1TipoS${idComp}`; 
            let scad2TipoS = `scad2TipoS${idComp}`; 
            let scad3TipoS = `scad3TipoS${idComp}`; 
            let btnSalvaScadTipoS = `btnSalvaScadTipoS${idComp}`; 
            let rigaScadenzeTipoI = `rigaScadenzeTipoI${idComp}`;
            let rigaScadenzeTipoSurv = `rigaScadenzeTipoSurv${idComp}`;
            let tipoOperCaricGriglia = `tipoOperCaricGriglia${idComp}`;
            this[funcName] = () => {
                  return (<React.Fragment>                        
                        <div id={rigaScadenzeTipoS} className='row' style={{ paddingTop: '10px' }}>
                            <div id={scad1TipoS} className="col-xs-4 col-sm-4 col-md-2 col-lg-1">
                                <NumericTextBoxComponent id={numBoxID1} placeholder="Prima Scadenza" format='N0' style={{width: '7vw'}} cssClass="e-outline" floatLabelType="Auto" showSpinButton={false}></NumericTextBoxComponent>
                            </div>
                            <div id={scad2TipoS} className="col-xs-3 col-sm-4 col-md-1 col-lg-1">
                                <NumericTextBoxComponent id={numBoxID2} placeholder="Seconda Scadenza" format='N0' style={{width: '7vw'}} cssClass="e-outline" floatLabelType="Auto" showSpinButton={false}></NumericTextBoxComponent>
                            </div>
                            <div id={scad3TipoS} className="col-xs-4 col-sm-4 col-md-2 col-lg-1">
                                <NumericTextBoxComponent id={numBoxID3} placeholder="Giorni Preavviso" format='N0' style={{width: '6.9vw'}} cssClass="e-outline" floatLabelType="Auto" showSpinButton={false}></NumericTextBoxComponent>
                            </div>   
                            <div id={btnSalvaScadTipoS} className="col-xs-4 col-sm-4 col-lg-1 col-md-1 mt-2" style={{ paddingTop: '3px' }}>   
                                <ProgressButtonComponent id={btnSalvaScad} content="Salva" duration='1000' enableProgress spinSettings={{ position: 'Center' }} 
                                        animationSettings={{effect: 'SlideLeft'}} created={this.ProgrButtCreated4} cssClass="e-success"></ProgressButtonComponent> 
                            </div>  
                            <div id={divPrimaScadCRR50} className="col-xs-4 col-sm-4 col-md-2 col-lg-2 offset-lg-1" style={{ display: 'none'}}>
                                <NumericTextBoxComponent id={numBoxID10} placeholder="Prima Scadenza Credit Ras/Ramo 50" format='N0' style={{width: '11.3vw'}} value='30' cssClass="e-outline" floatLabelType="Auto" showSpinButton={false}></NumericTextBoxComponent>
                            </div>
                            <div id={divSecondaScadCRR50} className="col-xs-3 col-sm-4 col-md-2 col-lg-2" style={{ display: 'none'}}>
                                <NumericTextBoxComponent id={numBoxID11} placeholder="Seconda Scadenza Credit Ras/Ramo 50" format='N0' style={{width: '12.1vw'}} value='45' cssClass="e-outline" floatLabelType="Auto" showSpinButton={false}></NumericTextBoxComponent>
                            </div>
                            <div id={divPreavvisoScadCRR50} className="col-xs-3 col-sm-4 col-md-2 col-lg-2" style={{ display: 'none'}}>
                                <NumericTextBoxComponent id={numBoxID13} placeholder="Preavviso Scadenza Credit Ras/Ramo 50" format='N0' style={{width: '12.4vw'}} value='10' cssClass="e-outline" floatLabelType="Auto" showSpinButton={false}></NumericTextBoxComponent>
                            </div>
                            <div id={divScadAltro} className="col-xs-4 col-sm-4 col-md-1 col-lg-1" style={{ display: 'none'}}>
                                <NumericTextBoxComponent id={numBoxID12} placeholder="Scadenza Ramo Altro" format='N0' style={{width: '7.5vw'}} value='60' cssClass="e-outline" floatLabelType="Auto" showSpinButton={false}></NumericTextBoxComponent>
                            </div>                                                    
                        </div>   
                        <div id={rigaScadenzeTipoI} className='row' style={{ paddingTop: '10px' }}>
                            <div className="col-xs-4 col-sm-4 col-md-2 col-lg-2">
                                <NumericTextBoxComponent id={numBoxID4} placeholder="Scadenza Ultima Interlocutoria" format='N0' style={{width: '14vw'}} cssClass="e-outline" floatLabelType="Auto" showSpinButton={false}></NumericTextBoxComponent>
                            </div>
                            <div className="col-xs-3 col-sm-4 col-md-2 col-lg-2">
                                <NumericTextBoxComponent id={numBoxID5} placeholder="Preavv. Scadenza Ultima Interlocutoria" format='N0' style={{width: '14vw'}} cssClass="e-outline" floatLabelType="Auto" showSpinButton={false}></NumericTextBoxComponent>
                            </div>
                            <div className="col-xs-4 col-sm-4 col-md-2 col-lg-2">
                                <NumericTextBoxComponent id={numBoxID6} placeholder="Prima Scadenza Senza Interlocutorie" format='N0' style={{width: '14vw'}} cssClass="e-outline" floatLabelType="Auto" showSpinButton={false}></NumericTextBoxComponent>
                            </div>  
                            <div className="col-xs-4 col-sm-4 col-md-2 col-lg-2">
                                <NumericTextBoxComponent id={numBoxID7} placeholder="Seconda Scadenza Senza Interlocutorie" format='N0' style={{width: '14vw'}} cssClass="e-outline" floatLabelType="Auto" showSpinButton={false}></NumericTextBoxComponent>
                            </div>  
                            <div className="col-xs-4 col-sm-4 col-lg-1 col-md-1 mt-2 text-center" style={{ paddingTop: '3px' }}>   
                                <ProgressButtonComponent id={btnSalvaScad2} content="Salva" duration='1000' enableProgress spinSettings={{ position: 'Center' }} 
                                        animationSettings={{effect: 'SlideLeft'}} created={this.ProgrButtCreated4} cssClass="e-success"></ProgressButtonComponent> 
                            </div>                                                    
                        </div>   
                        <div id={rigaScadenzeTipoSurv} className='row' style={{ paddingTop: '10px' }}>
                            <div className="col-xs-4 col-sm-4 col-md-2 col-lg-1">
                                <NumericTextBoxComponent id={numBoxID8} placeholder="Prima Scadenza" format='N0' style={{width: '7vw'}} cssClass="e-outline" floatLabelType="Auto" showSpinButton={false}></NumericTextBoxComponent>
                            </div>
                            <div className="col-xs-3 col-sm-4 col-md-1 col-lg-1">
                                <NumericTextBoxComponent id={numBoxID9} placeholder="Seconda Scadenza" format='N0' style={{width: '7vw'}} cssClass="e-outline" floatLabelType="Auto" showSpinButton={false}></NumericTextBoxComponent>
                            </div>
                            <div className="col-xs-4 col-sm-4 col-lg-1 col-md-1 mt-2" style={{ paddingTop: '3px' }}>   
                                <ProgressButtonComponent id={btnSalvaScad3} content="Salva" duration='1000' enableProgress spinSettings={{ position: 'Center' }} 
                                        animationSettings={{effect: 'SlideLeft'}} created={this.ProgrButtCreated4} cssClass="e-success"></ProgressButtonComponent> 
                            </div>                                                     
                        </div>   
                        <div className='row' style={{ paddingTop: '10px' }}>
                            <div className="col-xs-4 col-sm-4 col-lg-1 col-md-1 text-center">
                                <ProgressButtonComponent id={btnVisualDati} content="Visualizza Dati" duration='2000' enableProgress spinSettings={{ position: 'Center' }} 
                                       animationSettings={{effect: 'SlideRight'}} created={this.ProgrButtCreated5.bind(this)} cssClass="e-success"></ProgressButtonComponent>                                   
                            </div>    
                            <div id={divBtnSituazPeriti} className="col-xs-4 col-sm-4 col-lg-2 col-md-2 text-center">   
                                <ProgressButtonComponent id={btnSituazPeriti} content="Situazione Scadenze Periti" duration='1000' disabled={true} enableProgress spinSettings={{ position: 'Center' }} 
                                        animationSettings={{effect: 'SlideLeft'}} created={this.ProgrButtCreated6.bind(this)} cssClass="e-warning"></ProgressButtonComponent> 
                            </div>   
                            <div id={divOrdGravInterloc} className="col-xs-4 col-sm-4 col-lg-2 col-md-2"> 
                                 &nbsp; &nbsp; &nbsp;  
                                 <CheckBoxComponent id={chkOrdGravInterloc} checked={false} change={this.onChangeChkOrdGravInterloc} label='Ordina per gravità interlocutorie'></CheckBoxComponent>
                            </div>    
                            <div className="col-xs-4 col-sm-4 col-lg-2 col-md-2">   
                                <ProgressButtonComponent id={btnEmailTuttiPeriti} content="Invio Email avviso a tutti i periti" duration='1000' disabled={true} enableProgress spinSettings={{ position: 'Center' }} 
                                        animationSettings={{effect: 'SlideLeft'}} created={this.ProgrButtCreated7.bind(this)} cssClass="e-warning"></ProgressButtonComponent> 
                            </div>  
                            <div id={divChkGestioneRamo} className="col-xs-4 col-sm-4 col-lg-2 col-md-2 text-right" style={{ display: 'none'}}> 
                                 <CheckBoxComponent id={chkGestioneRamo} checked={false} change={this.onChangeChkRamo} label='Gestione per ramo'></CheckBoxComponent>
                            </div> 
                            <div id={divDdlGestioneRamo} className="col-xs-4 col-sm-4 col-lg-2 col-md-2" style={{ display: 'none' }}>  
                                <DropDownListComponent id={ddlGestioneRamo} fields={this.fields} index='0' change={this.onChangeRamo.bind(this)} dataSource={this.ddlRamoAllianz} 
                                    placeholder="Selezionare un ramo da visualizzare" floatLabelType='always' popupHeight="200px" cssClass='e-outline'/>       
                             </div>
                        </div>                          
                        <div className='row'>
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 text-center">  
                                <input id={tipoOperCaricGriglia} type='hidden' value=''></input>                              
                                <GridComponent id={grigliaSinistri} toolbar={this.toolbarOptions} allowSelection={false} enableHover={false} allowTextWrap={true} filterSettings={this.filterSettings} enableVirtualization={false} commandClick={this.onCommandClick.bind(this)}
                                    allowPaging={true} allowSorting={true} allowFiltering={true} allowExcelExport={true} allowPdfExport={true} pageSettings={{ pageCount: 40, pageSize: 20, pageSizes: ['20', '100', '200', '500'] }} actionComplete={this.onActionComplete.bind(this)}
                                    enableAltRow={false} gridLines='Both' dataBound={this.onDatabound.bind(this)} rowDataBound={this.onRowDatabound.bind(this)} toolbarClick={this.toolbarClick.bind(this)} enablePersistence={false} 
                                    queryCellInfo={this.onQueryCellInfo.bind(this)}>
                                    <ColumnsDirective>                            
                                        <ColumnDirective field='IdSinistro' headerText='Id Sinistro' width='7%' headerTemplate={this.headerTemplate} allowFiltering={false} template={this.fieldTemplate} textAlign='Center' filter={this.Filter} clipMode="EllipsisWithTooltip"></ColumnDirective>
                                        <ColumnDirective field='DescrCompagnia' headerText="Compagnia" width='10%' headerTemplate={this.headerTemplate} allowFiltering={false} template={this.fieldTemplate} textAlign='Center' clipMode="Clip"></ColumnDirective>
                                        <ColumnDirective field='SedePerito' headerText="Sede Perito" width='9%' headerTemplate={this.headerTemplate} allowFiltering={false} template={this.fieldTemplate} textAlign='Center'></ColumnDirective>
                                        <ColumnDirective field='NumSinistroCompagnia' headerText="Numero Sinistro Compagnia" width='15%' headerTemplate={this.headerTemplate} allowFiltering={false} template={this.fieldTemplate} textAlign='Center'></ColumnDirective>
                                        <ColumnDirective field='NominativoPerito' headerText="Perito" width='14%' headerTemplate={this.headerTemplate} allowFiltering={false} template={this.fieldTemplate} textAlign='Center'></ColumnDirective>
                                        <ColumnDirective field='NominativoSurveyor' headerText="Surveyor" width='14%' headerTemplate={this.headerTemplate} allowFiltering={true} template={this.fieldTemplate} textAlign='Center'></ColumnDirective>
                                        <ColumnDirective field='DescrTipoSinistro' headerText="Tipo Sinistro" width='20%' headerTemplate={this.headerTemplate} allowFiltering={false} template={this.fieldTemplate} textAlign='Center'></ColumnDirective>
                                        <ColumnDirective field='DescrRamo' headerText="Ramo" width='6%' headerTemplate={this.headerTemplate} template={this.fieldTemplate} allowFiltering={false} textAlign='Center'></ColumnDirective>
                                        <ColumnDirective field='NominativoContraenteAssicurato' width='17%' headerText="Contraente/Assicurato" headerTemplate={this.headerTemplate} allowFiltering={false} template={this.fieldTemplate} textAlign='Center'></ColumnDirective>
                                        <ColumnDirective field='GradoSinistro' headerText="Grado Sinistro" width='10%' headerTemplate={this.headerTemplate} template={this.fieldTemplate} allowFiltering={true} textAlign='Center'></ColumnDirective>
                                        <ColumnDirective field='DataPervenimento' headerText="Data Pervenim." format='dd/MM/yyyy' width='10%' allowFiltering={false} headerTemplate={this.headerTemplate} template={this.fieldTemplate} textAlign='Center'></ColumnDirective>
                                        <ColumnDirective field='DataAssegnazione' headerText="Data Assegn. Perito" format='dd/MM/yyyy' width='11%' allowFiltering={false} headerTemplate={this.headerTemplate} template={this.fieldTemplate} textAlign='Center'></ColumnDirective>
                                        <ColumnDirective field='DataAssegnazioneSurveyor' headerText="Data Assegn. Surveyor" format='dd/MM/yyyy' width='11%' allowFiltering={false} headerTemplate={this.headerTemplate} template={this.fieldTemplate} textAlign='Center'></ColumnDirective>
                                        <ColumnDirective field='DataUltimaInterlocutoria' headerText="Data Ultima Interloc." width='11%' format='dd/MM/yyyy' headerTemplate={this.headerTemplate} template={this.fieldTemplate} textAlign='Center'></ColumnDirective>
                                        <ColumnDirective columns={[{ field: 'GiorniApertura', headerText: 'Apertura', textAlign: 'Center', allowFiltering: false, template: this.fieldTemplate, headerTemplate: this.headerTemplate, headerTextAlign: 'Center', allowSorting: true }, 
                                                                   { field: 'GiorniAssegnazione', headerText: 'Assegnaz.', template: this.fieldTemplate, allowFiltering: false, headerTemplate: this.headerTemplate, headerTextAlign: 'Center' },
                                                                   { field: 'GiorniTotale', headerText: 'Totale', template: this.fieldTemplate, allowFiltering: false, headerTemplate: this.headerTemplate, headerTextAlign: 'Center' }]} headerText='Giorni' headerTemplate={this.headerTemplate} template={this.fieldTemplate} textAlign='Center'></ColumnDirective>
                                        <ColumnDirective field='Comandi' headerText="" textAlign='Left' width='4.3%' allowFiltering={false} commands={this.commands}></ColumnDirective>
                                    </ColumnsDirective>  
                                    <Inject services={[Toolbar, CommandColumn, ExcelExport, PdfExport, Filter, Sort, Page]}/>                    
                                </GridComponent>
                            </div>
                        </div> 
                  </React.Fragment>);
            };
            itemsData.push({ header: { text: elm.DescrizioneCompagnia }, content: this[funcName] });             
        });      
        document.getElementById('TabRosibot').ej2_instances[0].items = itemsData;
        document.getElementById('TabRosibot').ej2_instances[0].refresh();        
    }
    accordContent1() {
        return (<React.Fragment>
            <div className='row' style={{ paddingTop: '15px' }}>
                <div className="col-xs-3 col-sm-3 col-md-2 col-lg-2">
                    <TextBoxComponent id='smtp_server' ref={txtbox => { this.txtbox1Instance = txtbox }} htmlAttributes={{ maxlength:"30" }} placeholder="SMTP" 
                            cssClass="e-outline" floatLabelType="Auto" showClearButton={true}/>
                </div>
                <div className="col-xs-3 col-sm-3 col-md-1 col-lg-1">
                    <TextBoxComponent id='smtp_porta' ref={txtbox => { this.txtbox2Instance = txtbox }} htmlAttributes={{ maxlength:"5" }} placeholder="Porta"
                            cssClass="e-outline" floatLabelType="Auto" showClearButton={true}/>
                </div>
                <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                    <TextBoxComponent id='smtp_utente' ref={txtbox => { this.txtbox3Instance = txtbox }} htmlAttributes={{ maxlength:"50" }} placeholder="Utente"
                            cssClass="e-outline" floatLabelType="Auto" showClearButton={true}/>
                </div>
                <div className="col-xs-3 col-sm-3 col-md-2 col-lg-2">
                    <TextBoxComponent id='smtp_password' ref={txtbox => { this.txtbox4Instance = txtbox }} htmlAttributes={{ maxlength:"30" }} placeholder="Password"
                            type='Password' cssClass="e-outline" floatLabelType="Auto" showClearButton={true}/>
                </div>
                <div className="col-xs-1 col-sm-1col-md-1 col-lg-1">
                    <CheckBoxComponent id='smtp_SSL' ref={chkbox => { this.chkboxInstance = chkbox }} checked={false} label='SSL'></CheckBoxComponent>
                </div>
            </div>
            <div className='row' style={{ paddingTop: '15px' }}>
                <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                    <TextBoxComponent id='nome_mittente' ref={txtbox => { this.txtbox5Instance = txtbox }} htmlAttributes={{ maxlength:"30" }} placeholder="Nome mittente"
                            cssClass="e-outline" floatLabelType="Auto" showClearButton={true}/>
                </div>
                <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                    <TextBoxComponent id='email_mittente' ref={txtbox => { this.txtbox6Instance = txtbox }} htmlAttributes={{ maxlength:"50" }} placeholder="Email mittente"
                            cssClass="e-outline" floatLabelType="Auto" showClearButton={true}/>
                </div>  
                <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                    <TextBoxComponent id='email_cc' ref={txtbox => { this.txtbox7Instance = txtbox }} htmlAttributes={{ maxlength:"50" }} placeholder="CC"
                            cssClass="e-outline" floatLabelType="Auto" showClearButton={true}/>
                </div>
            </div>   
            <div className='row'>&nbsp;</div>  
            <div className='row'>
                <div className="col-xs-4 col-sm-4 col-lg-2 col-md-2 text-right">   
                    <ProgressButtonComponent id='btnRicParamEmail' content="Ricarica Parametri Email" enableProgress duration='500' spinSettings={{ position: 'Center' }} 
                            animationSettings={{effect: 'SlideRight'}} created={this.ProgrButtCreated1} cssClass="e-outline e-normal"></ProgressButtonComponent>                    
                </div>
                <div className="col-xs-4 col-sm-4 col-lg-2 offset-lg-1 col-md-2 offset-md-1 text-center">   
                    <ProgressButtonComponent id='btnVerifParamEmail' content="Verifica Parametri Email" enableProgress spinSettings={{ position: 'Center' }} 
                            animationSettings={{effect: 'SlideRight'}} created={this.ProgrButtCreated2} cssClass="e-outline e-normal"></ProgressButtonComponent>                    
                </div>
                <div className="col-xs-4 col-sm-4 col-lg-2 offset-lg-1 col-md-2 offset-md-1">   
                    <ProgressButtonComponent id='btnSalvaParamEmail' duration='1000' content="Salva Parametri Email" enableProgress spinSettings={{ position: 'Center' }} 
                            animationSettings={{effect: 'SlideLeft'}} created={this.ProgrButtCreated3} cssClass="e-success"></ProgressButtonComponent> 
                </div>
            </div>                 
        </React.Fragment>);
    }
    ProgrButtCreated1() {      
        this.element.addEventListener('click', () => {
            CustomJS.callAPI(`${APIRosibotURL}/api/Rosibot/leggiParametriEmailRef/${window.localStorage.getItem("IdReferente")}`, '', 'GET',
                '', retData => {     
                    if (retData.length > 0) {                             
                        setTimeout(() => { 
                            document.getElementById('smtp_server').ej2_instances[0].value = retData[0].smtp_server;
                            document.getElementById('smtp_porta').ej2_instances[0].value = retData[0].smtp_porta;   
                            document.getElementById('smtp_utente').ej2_instances[0].value = retData[0].smtp_utente;  
                            document.getElementById('smtp_password').ej2_instances[0].value = retData[0].smtp_password; 
                            document.getElementById('smtp_SSL').ej2_instances[0].checked = retData[0].smtp_SSL === 'S';
                            document.getElementById('nome_mittente').ej2_instances[0].value = retData[0].nome_mittente;
                            document.getElementById('email_mittente').ej2_instances[0].value = retData[0].email_mittente;
                            document.getElementById('email_cc').ej2_instances[0].value = retData[0].email_cc;
                        },50);       
                    }       
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? messaggioAPIBackendNonTrovata : messaggioNoBackend;
                toast.warn(messaggio, {
                    onClose: () => toast.dismiss(),
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,            
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true})
            }, true, false)
        });
    }
    ProgrButtCreated2() {      
        this.element.addEventListener('click', () => {
            let dataObj = {
                server: document.getElementById('smtp_server').ej2_instances[0].value,
                porta: document.getElementById('smtp_porta').ej2_instances[0].value,
                utente: document.getElementById('smtp_utente').ej2_instances[0].value,
                password: document.getElementById('smtp_password').ej2_instances[0].value,
                SSL: document.getElementById('smtp_SSL').ej2_instances[0].checked,
                mittente: document.getElementById('nome_mittente').ej2_instances[0].value,
                destinatario: document.getElementById('email_mittente').ej2_instances[0].value,
                emailMittente: document.getElementById('email_mittente').ej2_instances[0].value,
                emailCC: document.getElementById('email_cc').ej2_instances[0].value,
                soggetto: 'Rosibot - Email di prova per verifica parametri di configurazione',
                messaggioTesto: 'Messaggio di prova in formato testo...',
                messaggioHtml: '<span style="font-size:20px;font-weight: bold;">Messaggio di prova in formato HTML</span>'
            }
            CustomJS.callAPI(`${APIRosibotURL}/api/Rosibot/sendEmail`, JSON.stringify(dataObj), 'POST',
                'application/json', retData => {
                    if (retData.response === 'KO')
                        toast.error(`Attenzione!! Parametri per invio Email errati. L\' errore riscontrato è il seguente : ${JSON.stringify(retData.msg)}  `, {
                            onClose: () => toast.dismiss(),                            
                            position: "top-center",
                            autoClose: 15000,
                            hideProgressBar: false,            
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });  
                    else
                        toast.success('Parametri per invio Email validi!!', {
                            onClose: () => toast.dismiss(),                            
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,            
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });  
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? messaggioAPIBackendNonTrovata : messaggioNoBackend;
                toast.warn(messaggio, {
                    onClose: () => toast.dismiss(),
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,            
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true})
            }, true, false)
        });
    }
    ProgrButtCreated3() {      
        this.element.addEventListener('click', () => {
            let dataObj = {
                server: document.getElementById('smtp_server').ej2_instances[0].value,
                porta: document.getElementById('smtp_porta').ej2_instances[0].value,
                utente: document.getElementById('smtp_utente').ej2_instances[0].value,
                password: document.getElementById('smtp_password').ej2_instances[0].value,
                SSL: document.getElementById('smtp_SSL').ej2_instances[0].checked === true ? 'S' : '',
                mittente: document.getElementById('nome_mittente').ej2_instances[0].value,
                emailMittente: document.getElementById('email_mittente').ej2_instances[0].value,
                emailCC: document.getElementById('email_cc').ej2_instances[0].value                
            }
            CustomJS.callAPI(`${APIRosibotURL}/api/Rosibot/salvaParametriEmailRef/${window.localStorage.getItem("IdReferente")}`, JSON.stringify(dataObj), 'POST',
                'application/json', retData => {
                    if (retData.response === 'KO')
                        toast.error(`Attenzione!! Errore durante il salvataggio dei parametri Email. L\' errore riscontrato è il seguente : ${JSON.stringify(retData.msg)}  `, {
                            onClose: () => toast.dismiss(),
                            position: "top-center",
                            autoClose: 15000,
                            hideProgressBar: false,            
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });  
                    else
                        toast.success('Salvataggio parametri Email effettuato con successo!!', {
                            onClose: () => toast.dismiss(),
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,            
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });  
                    setTimeout(() => { document.getElementById('accordionParamEmail').ej2_instances[0].expandItem(false, 0) }, 250);
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? messaggioAPIBackendNonTrovata : messaggioNoBackend;
                toast.warn(messaggio, {
                    onClose: () => toast.dismiss(),
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,            
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                })
            }, true, false)
        });
    }
    ProgrButtCreated4(args) {              
        this.element.addEventListener('click', (e) => {            
            let IdComp = JSON.parse(localStorage.getItem("CompagnieSelezionate"))[document.getElementById('TabRosibot').ej2_instances[0].selectedItem].IdCompagnia;                        
            let tipoOper = localStorage.getItem('tipoOperativita');
            let dataObj = {
                tipoOper: tipoOper,
                primaScadenza: tipoOper === 'S' ? document.getElementById(`primaScadenza${IdComp}`).ej2_instances[0].value : tipoOper === 'I' ? document.getElementById(`primaScadSenzaInterloc${IdComp}`).ej2_instances[0].value : tipoOper === 'SURV' ? document.getElementById(`primaScadenzaSurv${IdComp}`).ej2_instances[0].value : null,
                secondaScadenza: tipoOper === 'S' ? document.getElementById(`secondaScadenza${IdComp}`).ej2_instances[0].value : tipoOper === 'I' ? document.getElementById(`secondaScadSenzaInterloc${IdComp}`).ej2_instances[0].value : tipoOper === 'SURV' ? document.getElementById(`secondaScadenzaSurv${IdComp}`).ej2_instances[0].value : null,
                giorniPreavviso: tipoOper === 'S' ? document.getElementById(`giorniPreavviso${IdComp}`).ej2_instances[0].value : tipoOper === 'I' ? document.getElementById(`preavvisoScadUltimaInterloc${IdComp}`).ej2_instances[0].value : null,
                scadUltimaInterloc: tipoOper === 'S' || tipoOper === 'SURV' ? null : document.getElementById(`scadenzaUltimaInterloc${IdComp}`).ej2_instances[0].value
            }
            CustomJS.callAPI(`${APIRosibotURL}/api/Rosibot/salvaParametriScadComp/${IdComp}`, JSON.stringify(dataObj), 'POST',
                'application/json', retData => {
                    if (retData.response === 'KO')
                        toast.error(`Attenzione!! Errore durante il salvataggio dei parametri di Compagnia. L\' errore riscontrato è il seguente : ${JSON.stringify(retData.msg)}  `, {
                            onClose: () => toast.dismiss(),
                            position: "top-center",
                            autoClose: 15000,
                            hideProgressBar: false,            
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });  
                    else
                        toast.success('Salvataggio parametri di Compagnia effettuato con successo!!', {
                            onClose: () => toast.dismiss(),
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,            
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });                      
                }, error => { let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? messaggioAPIBackendNonTrovata : messaggioNoBackend;
                              toast.warn(messaggio, {
                                onClose: () => toast.dismiss(),
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,            
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            }); 
            }, true, false)
        });
    }    
    ProgrButtCreated5(args) {
        let IdComp = JSON.parse(localStorage.getItem("CompagnieSelezionate"))[document.getElementById('TabRosibot').ej2_instances[0].selectedItem].IdCompagnia;
        document.getElementById('btnVisualDati' + IdComp).ej2_instances[0].element.addEventListener('click', (e) => {                   
            this.toggleBlocking();    
            this.caricaCronologie(false); 
            this.ricaricaGriglia = true;  
            let dataDa = moment(this.dateRangeInstance2.startDate).format('YYYYMMDD');
            let dataA = moment(this.dateRangeInstance2.endDate).format('YYYYMMDD');       
            CustomJS.getRemoteData2(CustomJS.APIUrlProd,
                `token=${CustomJS.tokenProd}&azione=rosibot&dataDa=${dataDa}&dataA=${dataA}`, 
                'POST', "application/x-www-form-urlencoded", (retData) => {   
                    debugger;  
                    if (document.getElementById(`secondaScadenza${IdComp}`).ej2_instances[0].value < document.getElementById(`primaScadenza${IdComp}`).ej2_instances[0].value)
                        document.getElementById(`secondaScadenza${IdComp}`).ej2_instances[0].value = document.getElementById(`primaScadenza${IdComp}`).ej2_instances[0].value; 
                    let dsGriglia = IdComp === 999 ? retData : retData.filter(elm => { return elm.IdCompagnia === parseInt(IdComp) });  
                    if (localStorage.getItem('tipoOperativita') === 'SURV')
                        dsGriglia = dsGriglia.filter(elm => { return !CustomJS.isNullEmpty(elm.DataAssegnazioneSurveyor) && CustomJS.isNullEmpty(elm.DataInvioFotoSurveyor)});
                    dsGriglia.forEach(elm => { 
                        elm.DescrTipoSinistro = localStorage.getItem('tipoOperativita') === 'S' ? _.find(SigleTipiSinistro.TipoSinistro, elm2 => { return elm2.TipoSinistro === elm.DescrTipoSinistro; })?.Sigla : elm.DescrTipoSinistro;  
                        elm.DescrRamo = elm.Ramo;
                        elm.Ramo = elm.Ramo === 'Credit Ras' ? 'CREDRASRAMO50' : elm.Ramo === 'Ramo 50' ? 'CREDRASRAMO50' : 'ALTRO';
                    }); 
                    if (document.getElementById(`chkGestioneRamo${IdComp}`).ej2_instances[0].checked) {
                        dsGriglia = dsGriglia.filter(elm => { return elm.Ramo === document.getElementById(`ddlGestioneRamo${IdComp}`).ej2_instances[0].value });
                    }                
                    let ddlPeriti = [{ Value: '', Text: '' }];
                    dsGriglia.forEach(elm => {
                        ddlPeriti.push({ Value: elm.IdPerito.toString(), Text: elm.NominativoPerito });
                    });                     
                    ddlPeriti = _.uniq(ddlPeriti, elm => { return elm.Value });
                    if (localStorage.getItem('tipoOperativita') === 'SURV')
                        document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].sortSettings = { columns: [{field: 'DataAssegnazioneSurveyor', direction: 'Ascending'}]};
                    else
                        document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].sortSettings = document.getElementById(`chkOrdGravInterloc${IdComp}`).ej2_instances[0].checked ? { columns: [] } : { columns: [{field: 'DataPervenimento', direction: 'Ascending'}]};
                    document.getElementById(`ddlPeriti${IdComp}`).ej2_instances[0].dataSource = ddlPeriti;
                    //document.getElementById(`ddlPeriti${IdComp}`).ej2_instances[0].refresh();
                    document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].dataSource = dsGriglia;
                    //document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].refresh();     
                    document.getElementById(`tipoOperCaricGriglia${IdComp}`).value = localStorage.getItem('tipoOperativita');              
                    setTimeout(() => { document.getElementById('accordionParamEmail').ej2_instances[0].expandItem(false, 0); }, 300);    
                    setTimeout(() => { 
                        this.toggleBlocking();                      
                        //---- Patch per nascondere questi elementi creati dall'oggetto RTE ---------
                        //---- Trovare soluzione definitiva
                        document.getElementById('btnRicParamEmail').ej2_instances[0].click();
                        $('[id=cronologiaRTE_toolbar_BackgroundColor_Target]').hide();
                        $('[id=cronologiaRTE_toolbar_FontColor_Target]').hide();
                        $('[id=emailRTE_toolbar_FontColor_Target]').hide();
                        $('[id=emailRTE_toolbar_BackgroundColor_Target]').hide();
                        //---------------------------------------------------------------------------
                    }, 1000);
                    setTimeout(() => { this.scrollAuto(1000); }, 1000);
            }, true, false);
        });
    }
    ProgrButtCreated6(args) {
        let IdComp = JSON.parse(localStorage.getItem("CompagnieSelezionate"))[document.getElementById('TabRosibot').ej2_instances[0].selectedItem].IdCompagnia;
        document.getElementById(`btnSituazPeriti${IdComp}`).ej2_instances[0].element.addEventListener('click', (e) => {
            this.toggleBlocking();
            document.getElementById('grigliaSituazionePeriti').ej2_instances[0].dataSource = null;
            let descrComp = `Situazione Scadenze Periti - Compagnia: ${JSON.parse(localStorage.getItem("CompagnieSelezionate"))[document.getElementById('TabRosibot').ej2_instances[0].selectedItem].DescrizioneCompagnia}`;
            this.dialogInstance4.show();
            //this.setState({ showDialog4: true });
            setTimeout(() => {
                this.caricaInfoTuttiPeriti();               
            }, 300);
            setTimeout(() => { 
                document.getElementById('compagniaSituazPeriti').innerHTML = descrComp;
                //---- Patch per nascondere questi elementi creati dall'oggetto RTE ---------
                //---- Trovare soluzione definitiva
                document.getElementById('btnRicParamEmail').ej2_instances[0].click();
                $('[id=cronologiaRTE_toolbar_BackgroundColor_Target]').hide();
                $('[id=cronologiaRTE_toolbar_FontColor_Target]').hide();
                $('[id=emailRTE_toolbar_FontColor_Target]').hide();
                $('[id=emailRTE_toolbar_BackgroundColor_Target]').hide();
                //---------------------------------------------------------------------------
            }, 2500);
        });
    }
    ProgrButtCreated7(args) {
        let IdComp = JSON.parse(localStorage.getItem("CompagnieSelezionate"))[document.getElementById('TabRosibot').ej2_instances[0].selectedItem].IdCompagnia;
        document.getElementById(`btnEmailTuttiPeriti${IdComp}`).ej2_instances[0].element.addEventListener('click', (e) => {
            this.dialogInstance5.show();
            //this.setState({ showDialog5: true });
        });
    }
    ProgrButtCreated8(args) {
        this.element.addEventListener('click', () => {
            let dataObj = {
                tipo_oper_default: localStorage.getItem('tipoOperativita'),
                data_da_default: document.getElementById('RangeDateEstrazione2').ej2_instances[0].startDate,
                data_a_default: document.getElementById('RangeDateEstrazione2').ej2_instances[0].endDate
            }
            CustomJS.callAPI(`${APIRosibotURL}/api/Rosibot/salvaTipoOperativitaRef/${window.localStorage.getItem("IdReferente")}`, JSON.stringify(dataObj), 'POST',
                'application/json', retData => {
                    if (retData.response === 'KO')
                        toast.error(`Attenzione!! Errore durante il salvataggio dell\'operatività default. L\' errore riscontrato è il seguente : ${JSON.stringify(retData.msg)}  `, {
                            onClose: () => toast.dismiss(),
                            position: "top-center",
                            autoClose: 15000,
                            hideProgressBar: false,            
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });  
                    else
                        toast.success('Salvataggio operatività default effettuato con successo!!', {
                            onClose: () => toast.dismiss(),
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,            
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });                      
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? messaggioAPIBackendNonTrovata : messaggioNoBackend;
                toast.warn(messaggio, {
                    onClose: () => toast.dismiss(),
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,            
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                })
            }, true, false)
        });
    }
    headerTemplate(props) {    
        return (<div>
             <span style={{ fontSize: '1.4em', fontWeight: 'bolder' }}>{props.headerText}</span>
        </div>);
    }
    headerTemplate2(props) {    
        return (<div>
             <span style={{ fontSize: '1.4em', fontWeight: 'bolder' }}>{props.headerText}</span>
        </div>);
    }
    fieldTemplate(props) { 
        let testoCampo;        
        if (props.column.field === 'GradoSinistro') 
            testoCampo = props[props.column.field] === 'S' ? 'Semplice' : props[props.column.field] === 'I' ? 'Intermedio' : 'Complesso';       
        else if (props.column.field === 'SedePerito')  
            testoCampo = props[props.column.field].substring(0, props[props.column.field].indexOf(','));  
        else if (props.column.field === 'DataPervenimento' || props.column.field === 'DataUltimaInterlocutoria' || props.column.field === 'DataAssegnazione' || props.column.field === 'DataAssegnazioneSurveyor')
            testoCampo = CustomJS.isNullEmpty(props[props.column.field]) ? 'N.D.' : <Moment format='DD/MM/YYYY'>{props[props.column.field]}</Moment>;
        else if (props.column.field === 'GiorniApertura') {              
            const date1 = new Date((<Moment format='YYYY/MM/DD'>{props.DataPervenimento}</Moment>).props.children);
            const date2 = new Date((<Moment format='YYYY/MM/DD'>{props.DataApertura}</Moment>).props.children);
            const diffTime = Math.abs(date2 - date1);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));    
            testoCampo = diffDays.toString();  
        }
        else if (props.column.field === 'GiorniAssegnazione') {   
            let tipoOper = localStorage.getItem('tipoOperativita');
            if (tipoOper === 'SURV') {
                const date1 = new Date((<Moment format='YYYY/MM/DD'>{props.DataAssegnazioneSurveyor}</Moment>).props.children);
                const date2 = new Date();
                const diffTime = Math.abs(date2 - date1);
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));    
                testoCampo = diffDays.toString();  
            }
            else {           
                const date1 = new Date((<Moment format='YYYY/MM/DD'>{props.DataApertura}</Moment>).props.children);
                const date2 = new Date((<Moment format='YYYY/MM/DD'>{props.DataAssegnazione}</Moment>).props.children);
                const diffTime = Math.abs(date2 - date1);
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));    
                testoCampo = diffDays.toString();  
            }
        }
        else if (props.column.field === 'GiorniTotale') { 
            const date1 = new Date((<Moment format='YYYY/MM/DD'>{props.DataPervenimento}</Moment>).props.children);
            const date2 = new Date();
            const diffTime = Math.abs(date2 - date1);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));    
            testoCampo = diffDays.toString();  
        }
        else
            testoCampo = props[props.column.field];
        return (        
            <div><span style={{ fontSize: '1.3em', color: 'purple', fontWeight: 'bold'}}>{testoCampo}</span></div>
        );
    }
    onDatabound(args) {              
        let IdComp = JSON.parse(localStorage.getItem("CompagnieSelezionate"))[document.getElementById('TabRosibot').ej2_instances[0].selectedItem].IdCompagnia;
        let tipoOper = localStorage.getItem('tipoOperativita'); 
        if (tipoOper === 'S') {
            if (!CustomJS.isNullEmpty(_.find(CustomJS.compagnieGestioneRamo, elm => { return elm === IdComp.toString()}))
                && document.getElementById(`chkGestioneRamo${IdComp}`).ej2_instances[0].checked) {
                if (IdComp === 999) {
                    document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].showColumns(['Contraente/Assicurato', 'Compagnia', 'Ramo', 'Tipo Sinistro', 'Grado Sinistro', 'Data Pervenim.', 'Giorni', 'Apertura', 'Assegnaz.', 'Totale']);
                    document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].hideColumns(['Sede Perito', 'Numero Sinistro Compagnia', 'Data Ultima Interloc.', 'Surveyor', 'Data Assegn. Surveyor', 'Data Assegn. Perito']);
                    document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].columns[8].width = '14%';
                    if (soloPrimaVolta)
                        document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].filterSettings = { type: 'CheckBox', columns: [ { field: 'GradoSinistro', matchCase: false,
                                                                                                                                               operator: 'equal', predicate: 'and', value: 'C'
                                                                                                                                             } ]};
                }
                else {
                    document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].showColumns(['Contraente/Assicurato', 'Ramo', 'Tipo Sinistro', 'Grado Sinistro', 'Data Pervenim.', 'Giorni', 'Apertura', 'Assegnaz.', 'Totale']);
                    document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].hideColumns(['Sede Perito', 'Compagnia', 'Numero Sinistro Compagnia', 'Data Ultima Interloc.', 'Surveyor', 'Data Assegn. Surveyor', 'Data Assegn. Perito']);
                    document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].columns[8].width = '16%';
                    if (soloPrimaVolta)
                        document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].filterSettings = { type: 'CheckBox' }
                }
            }
            else {
                if (IdComp === 999) {
                    document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].showColumns(['Contraente/Assicurato', 'Compagnia', 'Grado Sinistro', 'Tipo Sinistro', 'Data Pervenim.', 'Giorni', 'Apertura', 'Assegnaz.', 'Totale']);
                    document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].hideColumns(['Sede Perito', 'Ramo', 'Numero Sinistro Compagnia', 'Data Ultima Interloc.', 'Surveyor', 'Data Assegn. Surveyor', 'Data Assegn. Perito']);
                    document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].columns[8].width = '19%';
                    if (soloPrimaVolta)
                        document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].filterSettings = { type: 'CheckBox', columns: [ { field: 'GradoSinistro', matchCase: false,
                                                                                                                                           operator: 'equal', predicate: 'and', value: 'C'
                                                                                                                                         } ]};
                }
                else {
                    document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].showColumns(['Contraente/Assicurato', 'Grado Sinistro', 'Tipo Sinistro', 'Data Pervenim.', 'Giorni', 'Apertura', 'Assegnaz.', 'Totale']);
                    document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].hideColumns(['Sede Perito', 'Compagnia', 'Ramo', 'Numero Sinistro Compagnia', 'Data Ultima Interloc.', 'Surveyor', 'Data Assegn. Surveyor', 'Data Assegn. Perito']);
                    document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].columns[8].width = '19%';
                    if (soloPrimaVolta)
                        document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].filterSettings = { type: 'CheckBox' }
                }
            }
            document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].columns[6].width = '10%';
            for (let ind=0;ind<=15;ind++) {
                document.getElementById(`grigliaSinistri${IdComp}`).querySelectorAll('.e-columnheader')[0].children[ind].style.backgroundColor = 'Linen';            
            }  
            for (let ind=0;ind<=2;ind++) {            
                document.getElementById(`grigliaSinistri${IdComp}`).querySelectorAll('.e-columnheader')[1].children[ind].style.backgroundColor = 'Linen';
            }  
            let almenoUnaScad = false;
            let dsGriglia = document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].dataSource;        
            dsGriglia.forEach(elm2 => {
                const date1 = new Date((<Moment format='YYYY/MM/DD'>{elm2.DataPervenimento}</Moment>).props.children);
                const date2 = new Date();
                const diffTime = Math.abs(date2 - date1);
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));  
                if (document.getElementById(`chkGestioneRamo${IdComp}`).ej2_instances[0].checked 
                    && document.getElementById(`ddlGestioneRamo${IdComp}`).ej2_instances[0].value === 'CREDRASRAMO50') {
                        if (diffDays > document.getElementById(`primaScadCRR50${IdComp}`).ej2_instances[0].value 
                            && diffDays <= document.getElementById(`secondaScadCRR50${IdComp}`).ej2_instances[0].value)                                 
                            almenoUnaScad = true;                            
                        else if (diffDays > document.getElementById(`secondaScadCRR50${IdComp}`).ej2_instances[0].value)                                 
                            almenoUnaScad = true;
                        else if ((diffDays + document.getElementById(`preavvisoScadCRR50${IdComp}`).ej2_instances[0].value) > document.getElementById(`primaScadCRR50${IdComp}`).ej2_instances[0].value) 
                            almenoUnaScad = true;
                }
                else if (document.getElementById(`chkGestioneRamo${IdComp}`).ej2_instances[0].checked 
                         && document.getElementById(`ddlGestioneRamo${IdComp}`).ej2_instances[0].value === 'ALTRO') {
                        if (diffDays > document.getElementById(`scadAltro${IdComp}`).ej2_instances[0].value)                                 
                            almenoUnaScad = true;
                }
                else if (document.getElementById(`primaScadenza${IdComp}`).ej2_instances[0].value > 0 ||
                         document.getElementById(`secondaScadenza${IdComp}`).ej2_instances[0].value > 0) {
                    if (diffDays > document.getElementById(`primaScadenza${IdComp}`).ej2_instances[0].value 
                        && diffDays <= document.getElementById(`secondaScadenza${IdComp}`).ej2_instances[0].value)                                 
                        almenoUnaScad = true;                            
                    else if (diffDays > document.getElementById(`secondaScadenza${IdComp}`).ej2_instances[0].value)                                 
                        almenoUnaScad = true;                            
                    else if ((diffDays + document.getElementById(`giorniPreavviso${IdComp}`).ej2_instances[0].value) > document.getElementById(`primaScadenza${IdComp}`).ej2_instances[0].value) 
                        almenoUnaScad = true;                            
                }
            }); 
            if (almenoUnaScad) {
                if (!document.getElementById(`chkGestioneRamo${IdComp}`).ej2_instances[0].checked)
                    document.getElementById(`btnSituazPeriti${IdComp}`).ej2_instances[0].disabled = false;
                document.getElementById(`btnEmailTuttiPeriti${IdComp}`).ej2_instances[0].disabled = false;
            } 
            else {
                document.getElementById(`btnSituazPeriti${IdComp}`).ej2_instances[0].disabled = true;
                document.getElementById(`btnEmailTuttiPeriti${IdComp}`).ej2_instances[0].disabled = true;
            }
        }
        else if (tipoOper === 'I') {
            if (IdComp === 999) {
                document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].hideColumns(['Sede Perito', 'Surveyor', 'Ramo', 'Giorni', 'Apertura', 'Assegnaz.', 'Totale', 'Data Assegn. Surveyor']);
                document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].showColumns(['Compagnia', 'Numero Sinistro Compagnia', 'Tipo Sinistro', 'Contraente/Assicurato', 'Grado Sinistro', 'Data Pervenim.', 'Data Assegn. Perito', 'Data Ultima Interloc.']);
                document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].columns[6].width = '15%';
                if (soloPrimaVolta)
                    document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].filterSettings = { type: 'CheckBox', columns: [ { field: 'GradoSinistro', matchCase: false,
                                                                                                                                           operator: 'equal', predicate: 'and', value: 'C'
                                                                                                                                         } ]};
            }
            else {
                document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].hideColumns(['Sede Perito', 'Compagnia', 'Surveyor', 'Ramo', 'Giorni', 'Apertura', 'Assegnaz.', 'Totale', 'Data Assegn. Surveyor']);
                document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].showColumns(['Numero Sinistro Compagnia', 'Tipo Sinistro', 'Contraente/Assicurato', 'Grado Sinistro', 'Data Pervenim.', 'Data Assegn. Perito', 'Data Ultima Interloc.']);
                document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].columns[6].width = '15%';
                if (soloPrimaVolta)
                    document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].filterSettings = { type: 'CheckBox' }
            }
            for (let ind=0;ind<=14;ind++) {
                document.getElementById(`grigliaSinistri${IdComp}`).querySelectorAll('.e-columnheader')[0].children[ind].style.backgroundColor = 'Linen';            
            }  
            for (let ind=0;ind<=2;ind++) {            
                document.getElementById(`grigliaSinistri${IdComp}`).querySelectorAll('.e-columnheader')[1].children[ind].style.backgroundColor = 'Linen';
            }  
            let almenoUnaScad = false;
            let dsGriglia = document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].dataSource;        
            dsGriglia.forEach(elm2 => {
                const date1 = new Date((<Moment format='YYYY/MM/DD'>{elm2.DataPervenimento}</Moment>).props.children);
                const date2 = new Date();
                const date3 = CustomJS.isNullEmpty(elm2.DataUltimaInterlocutoria) ? 'ND' : new Date((<Moment format='YYYY/MM/DD'>{elm2.DataUltimaInterlocutoria}</Moment>).props.children); 
                const diffTime = Math.abs(date2 - date1);
                const diffTime2 = date3 === 'ND' ? 'ND' : Math.abs(date2 - date3);
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));  
                const diffDays2 = diffTime2 === 'ND' ? 'ND' : Math.ceil(diffTime2 / (1000 * 60 * 60 * 24));  
                if (diffDays2 === 'ND' && diffDays > document.getElementById(`secondaScadSenzaInterloc${IdComp}`).ej2_instances[0].value) {
                    almenoUnaScad = true;
                    elm2.SecondaScadSenzaInterloc = true;
                }
                else if (diffDays2 === 'ND' && diffDays > document.getElementById(`primaScadSenzaInterloc${IdComp}`).ej2_instances[0].value 
                        && diffDays <= document.getElementById(`secondaScadSenzaInterloc${IdComp}`).ej2_instances[0].value) {
                    almenoUnaScad = true;
                    elm2.PrimaScadSenzaInterloc = true;
                }
                else if (diffDays2 > document.getElementById(`scadenzaUltimaInterloc${IdComp}`).ej2_instances[0].value) {
                    almenoUnaScad = true;
                    elm2.ScadenzaUltimaInterloc = true;
                }
                else if ((diffDays2 + document.getElementById(`preavvisoScadUltimaInterloc${IdComp}`).ej2_instances[0].value) > document.getElementById(`scadenzaUltimaInterloc${IdComp}`).ej2_instances[0].value) {
                    almenoUnaScad = true;
                    elm2.PreavvisoScadUltimaInterloc = true;
                }
            }); 
            if (almenoUnaScad)                 
                document.getElementById(`btnEmailTuttiPeriti${IdComp}`).ej2_instances[0].disabled = false;
            else 
                document.getElementById(`btnEmailTuttiPeriti${IdComp}`).ej2_instances[0].disabled = true;            
            if (document.getElementById(`chkOrdGravInterloc${IdComp}`).ej2_instances[0].checked && this.ricaricaGriglia) {
                this.ricaricaGriglia = false;
                let dsTemp1 = _.filter(dsGriglia, elm => { return elm.SecondaScadSenzaInterloc === true });
                let dsTemp2 = _.filter(dsGriglia, elm => { return elm.PrimaScadSenzaInterloc === true });
                let dsTemp3 = _.filter(dsGriglia, elm => { return elm.ScadenzaUltimaInterloc === true });
                let dsTemp4 = _.filter(dsGriglia, elm => { return elm.PreavvisoScadUltimaInterloc === true });
                let dsTemp5 = _.filter(dsGriglia, elm => { return isUndefined(elm.SecondaScadSenzaInterloc) && isUndefined(elm.PrimaScadSenzaInterloc)
                                       && isUndefined(elm.ScadenzaUltimaInterloc) && isUndefined(elm.PreavvisoScadUltimaInterloc) });
                let dsGrigliaSort = _.union(dsTemp1, dsTemp2, dsTemp3, dsTemp4, dsTemp5);    
                document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].dataSource = dsGrigliaSort;
                document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].refresh();                
            }
        }     
        else if (tipoOper === 'SURV') {
            if (IdComp === 999) {
                document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].showColumns(['Contraente/Assicurato', 'Compagnia', 'Surveyor', 'Data Assegn. Surveyor', 'Grado Sinistro', 'Giorni', 'Assegnaz.']);
                document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].hideColumns(['Sede Perito', 'Numero Sinistro Compagnia', 'Ramo', 'Tipo Sinistro', 'Data Ultima Interloc.', 'Apertura', 'Totale', 'Data Pervenim.', 'Data Assegn. Perito']);
                document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].columns[8].width = '20%';
                document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].columns[9].width = '10%';
                if (soloPrimaVolta)
                    document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].filterSettings = { type: 'CheckBox', columns: [ { field: 'GradoSinistro', matchCase: false,
                                                                                                                                           operator: 'equal', predicate: 'and', value: 'C'
                                                                                                                                         } ]};
            }
            else {
                document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].showColumns(['Contraente/Assicurato', 'Surveyor', 'Data Assegn. Surveyor', 'Grado Sinistro', 'Giorni', 'Assegnaz.']);
                document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].hideColumns(['Sede Perito', 'Compagnia', 'Numero Sinistro Compagnia', 'Ramo', 'Tipo Sinistro', 'Data Ultima Interloc.', 'Apertura', 'Totale', 'Data Pervenim.', 'Data Assegn. Perito']);
                document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].columns[8].width = '30%';
                document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].columns[9].width = '10%';
                if (soloPrimaVolta)
                    document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].filterSettings = { type: 'CheckBox' }
            }
            for (let ind=0;ind<=15;ind++) {
                document.getElementById(`grigliaSinistri${IdComp}`).querySelectorAll('.e-columnheader')[0].children[ind].style.backgroundColor = 'Linen';            
            }  
            for (let ind=0;ind<=2;ind++) {            
                document.getElementById(`grigliaSinistri${IdComp}`).querySelectorAll('.e-columnheader')[1].children[ind].style.backgroundColor = 'Linen';
            }  
            let almenoUnaScad = false;
            let dsGriglia = document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].dataSource;        
            dsGriglia.forEach(elm2 => {
                const date1 = new Date((<Moment format='YYYY/MM/DD'>{elm2.DataAssegnazioneSurveyor}</Moment>).props.children);
                const date2 = new Date();
                const diffTime = Math.abs(date2 - date1);
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));    
                if (document.getElementById(`primaScadenzaSurv${IdComp}`).ej2_instances[0].value > 0 ||
                    document.getElementById(`secondaScadenzaSurv${IdComp}`).ej2_instances[0].value > 0) {
                    if (diffDays > document.getElementById(`primaScadenzaSurv${IdComp}`).ej2_instances[0].value 
                        && diffDays <= document.getElementById(`secondaScadenzaSurv${IdComp}`).ej2_instances[0].value)                                 
                        almenoUnaScad = true;                            
                    else if (diffDays > document.getElementById(`secondaScadenzaSurv${IdComp}`).ej2_instances[0].value)                                 
                        almenoUnaScad = true;                            
                }
            }); 
            if (almenoUnaScad) {
                document.getElementById(`btnEmailTuttiPeriti${IdComp}`).ej2_instances[0].disabled = false;
            } 
            else {
                document.getElementById(`btnEmailTuttiPeriti${IdComp}`).ej2_instances[0].disabled = true;
            }
        } 
        soloPrimaVolta = false;        
    }
    onRowDatabound(args) {     
        let IdComp = JSON.parse(localStorage.getItem("CompagnieSelezionate"))[document.getElementById('TabRosibot').ej2_instances[0].selectedItem].IdCompagnia;
        let tipoOper = localStorage.getItem('tipoOperativita');
        const date1 = tipoOper === 'SURV' ? new Date((<Moment format='YYYY/MM/DD'>{args.data.DataAssegnazioneSurveyor}</Moment>).props.children) : new Date((<Moment format='YYYY/MM/DD'>{args.data.DataPervenimento}</Moment>).props.children);
        const date2 = new Date();
        const date3 = CustomJS.isNullEmpty(args.data.DataUltimaInterlocutoria) ? 'ND' : new Date((<Moment format='YYYY/MM/DD'>{args.data.DataUltimaInterlocutoria}</Moment>).props.children); 
        const diffTime = Math.abs(date2 - date1);
        const diffTime2 = date3 === 'ND' ? 'ND' : Math.abs(date2 - date3);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
        const diffDays2 = diffTime2 === 'ND' ? 'ND' : Math.ceil(diffTime2 / (1000 * 60 * 60 * 24));        
        if (tipoOper === 'S') {
            if (document.getElementById(`chkGestioneRamo${IdComp}`).ej2_instances[0].checked 
                && document.getElementById(`ddlGestioneRamo${IdComp}`).ej2_instances[0].value === 'CREDRASRAMO50') {
                    if (diffDays > document.getElementById(`primaScadCRR50${IdComp}`).ej2_instances[0].value 
                        && diffDays <= document.getElementById(`secondaScadCRR50${IdComp}`).ej2_instances[0].value)
                        args.row.bgColor = 'orange';      
                    else if (diffDays > document.getElementById(`secondaScadCRR50${IdComp}`).ej2_instances[0].value)                
                        args.row.bgColor = 'lightcoral';
                    else if ((diffDays + document.getElementById(`preavvisoScadCRR50${IdComp}`).ej2_instances[0].value) > document.getElementById(`primaScadCRR50${IdComp}`).ej2_instances[0].value) 
                        args.row.bgColor = 'yellow';
            }
            else if (document.getElementById(`chkGestioneRamo${IdComp}`).ej2_instances[0].checked 
                     && document.getElementById(`ddlGestioneRamo${IdComp}`).ej2_instances[0].value === 'ALTRO') {
                     if (diffDays > document.getElementById(`scadAltro${IdComp}`).ej2_instances[0].value)                
                         args.row.bgColor = 'lightcoral';
            }
            else if (document.getElementById(`primaScadenza${IdComp}`).ej2_instances[0].value > 0 ||
                document.getElementById(`secondaScadenza${IdComp}`).ej2_instances[0].value > 0) {
                if (diffDays > document.getElementById(`primaScadenza${IdComp}`).ej2_instances[0].value 
                    && diffDays <= document.getElementById(`secondaScadenza${IdComp}`).ej2_instances[0].value)
                    args.row.bgColor = 'orange';      
                else if (diffDays > document.getElementById(`secondaScadenza${IdComp}`).ej2_instances[0].value)                
                    args.row.bgColor = 'lightcoral';           
                else if ((diffDays + document.getElementById(`giorniPreavviso${IdComp}`).ej2_instances[0].value) > document.getElementById(`primaScadenza${IdComp}`).ej2_instances[0].value) 
                    args.row.bgColor = 'yellow';             
            }
        }  
        else if (tipoOper === 'I') {
            if (diffDays2 === 'ND' && diffDays > document.getElementById(`secondaScadSenzaInterloc${IdComp}`).ej2_instances[0].value) {
                args.row.bgColor = 'red';
            }
            else if (diffDays2 === 'ND' && diffDays > document.getElementById(`primaScadSenzaInterloc${IdComp}`).ej2_instances[0].value 
                     && diffDays <= document.getElementById(`secondaScadSenzaInterloc${IdComp}`).ej2_instances[0].value)
                args.row.bgColor = 'lightcoral';
            else if (diffDays2 > document.getElementById(`scadenzaUltimaInterloc${IdComp}`).ej2_instances[0].value)
                args.row.bgColor = 'orange';
            else if ((diffDays2 + document.getElementById(`preavvisoScadUltimaInterloc${IdComp}`).ej2_instances[0].value) > document.getElementById(`scadenzaUltimaInterloc${IdComp}`).ej2_instances[0].value) 
                args.row.bgColor = 'yellow';
        }
        else if (tipoOper === 'SURV') {
            if (document.getElementById(`primaScadenzaSurv${IdComp}`).ej2_instances[0].value > 0 ||
                document.getElementById(`secondaScadenzaSurv${IdComp}`).ej2_instances[0].value > 0) {
                if (diffDays > document.getElementById(`primaScadenzaSurv${IdComp}`).ej2_instances[0].value 
                    && diffDays <= document.getElementById(`secondaScadenzaSurv${IdComp}`).ej2_instances[0].value)
                    args.row.bgColor = 'orange';      
                else if (diffDays > document.getElementById(`secondaScadenzaSurv${IdComp}`).ej2_instances[0].value)                
                    args.row.bgColor = 'lightcoral';           
            }
        }  
    }
    onQueryCellInfo(args) { 
        if (args.column.field === 'Comandi') { 
            let tipoOper = localStorage.getItem('tipoOperativita');    
            if (!CustomJS.isNullEmpty(_.find(this.tutteCronologie, elm => { return parseInt(elm.id_sinistro) === args.data.IdSinistro && elm.tipo_operativita === tipoOper; }))) 
                args.cell.bgColor = 'lightgreen';
        }
    }
    toolbarClick(args) {
        let IdComp = JSON.parse(localStorage.getItem("CompagnieSelezionate"))[document.getElementById('TabRosibot').ej2_instances[0].selectedItem].IdCompagnia;
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].pdfExport();
                break;
            case 'e-excelexport':
                document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].excelExport();
                break;
            case 'e-csvexport':
                document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].csvExport();
                break;
        }
        if (args.item.id === 'invioavvisoperito') {
            this.toggleBlocking();
            let ddlPeriti = document.getElementById(`ddlPeriti${IdComp}`).ej2_instances[0];
            document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].search(ddlPeriti.text); 
            setTimeout(() => {     
                this.creaEmailPerito(); 
            }, 1500);
            setTimeout(() => {
                this.toggleBlocking(); 
                this.dialogInstance.header = titoloEmailAvviso.format2(document.getElementById(`ddlPeriti${IdComp}`).ej2_instances[0].text);     
                this.dialogInstance.buttons = this.buttons; 
                this.dialogInstance.show();        
                //this.setState({ showDialog: true });
            }, 2500)
            setTimeout(() => {                
                //---- Patch per nascondere questi elementi creati dall'oggetto RTE ---------
                //---- Trovare soluzione definitiva
                document.getElementById('btnRicParamEmail').ej2_instances[0].click();
                $('[id=cronologiaRTE_toolbar_BackgroundColor_Target]').hide();
                $('[id=cronologiaRTE_toolbar_FontColor_Target]').hide();
                $('[id=emailRTE_toolbar_FontColor_Target]').hide();
                $('[id=emailRTE_toolbar_BackgroundColor_Target]').hide();
                //---------------------------------------------------------------------------
            }, 3500);
        }
        if (args.item.id === 'vediinfoperito') {
            this.toggleBlocking();
            let ddlPeriti = document.getElementById(`ddlPeriti${IdComp}`).ej2_instances[0];
            document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].search(ddlPeriti.text);   
            setTimeout(() => {               
                this.dialogInstance3.show();      
                //this.setState({ showDialog3: true });
                this.toggleBlocking();
                this.caricaInfoPerito();
            }, 1500);            
            setTimeout(() => { 
                //---- Patch per nascondere questi elementi creati dall'oggetto RTE ---------
                //---- Trovare soluzione definitiva
                document.getElementById('btnRicParamEmail').ej2_instances[0].click();
                $('[id=cronologiaRTE_toolbar_BackgroundColor_Target]').hide();
                $('[id=cronologiaRTE_toolbar_FontColor_Target]').hide();
                $('[id=emailRTE_toolbar_FontColor_Target]').hide();
                $('[id=emailRTE_toolbar_BackgroundColor_Target]').hide();
                //---------------------------------------------------------------------------
            }, 3000);
        }
    }
    onActionComplete(args) {                 
        let IdComp = JSON.parse(localStorage.getItem("CompagnieSelezionate"))[document.getElementById('TabRosibot').ej2_instances[0].selectedItem].IdCompagnia;
        let tipoOper = localStorage.getItem('tipoOperativita');
        let dsGriglia = document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].dataSource;
        if (args.type === 'actionComplete' && args.requestType === 'searching') {           
            new DataManager(dsGriglia) 
                .executeQuery(new Query().search(args.searchString, ['NominativoPerito'])) 
                .then((e) => { 
                    let rows = e.result; 
                    rows.forEach(elm => {
                        const date1 = tipoOper === 'SURV' ? new Date((<Moment format='YYYY/MM/DD'>{elm.DataAssegnazioneSurveyor}</Moment>).props.children) : new Date((<Moment format='YYYY/MM/DD'>{elm.DataPervenimento}</Moment>).props.children);
                        const date2 = new Date();
                        const date3 = CustomJS.isNullEmpty(elm.DataUltimaInterlocutoria) ? 'ND' : new Date((<Moment format='YYYY/MM/DD'>{elm.DataUltimaInterlocutoria}</Moment>).props.children); 
                        const diffTime = Math.abs(date2 - date1);
                        const diffTime2 = date3 === 'ND' ? 'ND' : Math.abs(date2 - date3);    
                        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                        const diffDays2 = diffTime2 === 'ND' ? 'ND' : Math.ceil(diffTime2 / (1000 * 60 * 60 * 24));
                        if (tipoOper === 'S') {   
                            if (document.getElementById(`chkGestioneRamo${IdComp}`).ej2_instances[0].checked 
                                && document.getElementById(`ddlGestioneRamo${IdComp}`).ej2_instances[0].value === 'CREDRASRAMO50') {
                                if (diffDays > document.getElementById(`primaScadCRR50${IdComp}`).ej2_instances[0].value 
                                    && diffDays <= document.getElementById(`secondaScadCRR50${IdComp}`).ej2_instances[0].value)                                 
                                    elm.PrimaScadenza = true;                            
                                else if (diffDays > document.getElementById(`secondaScadCRR50${IdComp}`).ej2_instances[0].value)                                 
                                    elm.SecondaScadenza = true; 
                                else if ((diffDays + document.getElementById(`preavvisoScadCRR50${IdComp}`).ej2_instances[0].value) > document.getElementById(`primaScadCRR50${IdComp}`).ej2_instances[0].value) 
                                    elm.InScadenza = true; 
                            }                         
                            else if (document.getElementById(`chkGestioneRamo${IdComp}`).ej2_instances[0].checked 
                                     && document.getElementById(`ddlGestioneRamo${IdComp}`).ej2_instances[0].value === 'ALTRO') {
                                     if (diffDays > document.getElementById(`scadAltro${IdComp}`).ej2_instances[0].value)                                 
                                         elm.SecondaScadenza = true;
                            }
                            else if (document.getElementById(`primaScadenza${IdComp}`).ej2_instances[0].value > 0 ||
                                     document.getElementById(`secondaScadenza${IdComp}`).ej2_instances[0].value > 0) {
                                if (diffDays > document.getElementById(`primaScadenza${IdComp}`).ej2_instances[0].value 
                                    && diffDays <= document.getElementById(`secondaScadenza${IdComp}`).ej2_instances[0].value)                                 
                                    elm.PrimaScadenza = true;                            
                                else if (diffDays > document.getElementById(`secondaScadenza${IdComp}`).ej2_instances[0].value)                                 
                                    elm.SecondaScadenza = true;                            
                                else if ((diffDays + document.getElementById(`giorniPreavviso${IdComp}`).ej2_instances[0].value) > document.getElementById(`primaScadenza${IdComp}`).ej2_instances[0].value) 
                                    elm.InScadenza = true;                            
                            }
                        }
                        else if (tipoOper === 'I') {
                            if (diffDays2 === 'ND' && diffDays > document.getElementById(`secondaScadSenzaInterloc${IdComp}`).ej2_instances[0].value)
                                elm.SecondaScadSenzaInterloc = true;
                            else if (diffDays2 === 'ND' && diffDays > document.getElementById(`primaScadSenzaInterloc${IdComp}`).ej2_instances[0].value 
                                     && diffDays <= document.getElementById(`secondaScadSenzaInterloc${IdComp}`).ej2_instances[0].value)
                                elm.PrimaScadSenzaInterloc = true;
                            else if (diffDays2 > document.getElementById(`scadenzaUltimaInterloc${IdComp}`).ej2_instances[0].value)
                                elm.ScadenzaUltimaInterloc = true;
                            else if ((diffDays2 + document.getElementById(`preavvisoScadUltimaInterloc${IdComp}`).ej2_instances[0].value) > document.getElementById(`scadenzaUltimaInterloc${IdComp}`).ej2_instances[0].value) 
                                elm.PreavvisoScadUltimaInterloc = true;                             
                        }
                        else if (tipoOper === 'SURV') {                                
                            if (document.getElementById(`primaScadenzaSurv${IdComp}`).ej2_instances[0].value > 0 ||
                                document.getElementById(`secondaScadenzaSurv${IdComp}`).ej2_instances[0].value > 0) {
                                if (diffDays > document.getElementById(`primaScadenzaSurv${IdComp}`).ej2_instances[0].value 
                                    && diffDays <= document.getElementById(`secondaScadenzaSurv${IdComp}`).ej2_instances[0].value)                                 
                                    elm.PrimaScadenza = true;                            
                                else if (diffDays > document.getElementById(`secondaScadenzaSurv${IdComp}`).ej2_instances[0].value)                                 
                                    elm.SecondaScadenza = true;                            
                            }
                        }
                    });                    
                    localStorage.setItem(`datiRicercaGriglia${IdComp}`, JSON.stringify(rows)); 
                }); 
        }      
    }
    onCommandClick(args) {        
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-history') > -1) {
            this.sinistroCronologia = args.rowData.IdSinistro.toString();
            indirizzoEmailPerito = args.rowData.IndirizzoEmailPerito;
            let tipoOper = localStorage.getItem('tipoOperativita');
            CustomJS.callAPI(`${APIRosibotURL}/api/Rosibot/leggiCronologiaSinistro/${args.rowData.IdSinistro}/${tipoOper}`, '', 'GET',
                '', retData => {     
                    if (retData.length > 0)                             
                        testoCronologia = retData[0].testo_cronologia;                          
                    else
                        testoCronologia = '';
                    this.dialogInstance2.header = titoloCronologia.format2(args.rowData.NominativoPerito, args.rowData.IdSinistro); 
                    this.salvaPeritoCronologia = args.rowData.NominativoPerito; 
                    this.dialogInstance2.show();
                    //this.setState({ showDialog2: true });       
            }, error => {                
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? messaggioAPIBackendNonTrovata : messaggioNoBackend; 
                toast.warn(messaggio, {
                    onClose: () => toast.dismiss(),
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,            
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true})
            }, true, false)
        }
    }
    onDatabound2(args) {              
        for (let ind=0;ind<=10;ind++) {
            document.getElementById('grigliaSituazionePeriti').querySelectorAll('.e-columnheader')[0].children[ind].style.backgroundColor = 'Linen';            
        }  
    }
    toolbarClick2(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                document.getElementById('grigliaSituazionePeriti').ej2_instances[0].pdfExport();
                break;
            case 'e-excelexport':
                document.getElementById('grigliaSituazionePeriti').ej2_instances[0].excelExport();
                break;
            case 'e-csvexport':
                document.getElementById('grigliaSituazionePeriti').ej2_instances[0].csvExport();
                break;
        }
    }
    dialogClose() {
        testoEmailPerito = '';
        this.dialogInstance.hide();
        //this.setState({ showDialog: false });
    }
    dialogOpen() {   
        this.chkboxInstance2.checked = false;     
        document.getElementById('emailDialog').ej2_instances[0].position = {X: 'center', Y: 'center'};               
        document.getElementById('emailRTE').ej2_instances[0].value = testoEmailPerito.replace(/&amp;lt;/g, '<').replace(/&amp;gt;/g, '>').replace(/&quot;/g, '"');
        setTimeout(() => {     //PATCH - TODO : Appena possibile trovare soluzione corretta
            document.getElementById('emailRTE_toolbar_SourceCode').click();
            document.getElementById('emailRTE_toolbar_Preview').click();            
        }, 300);        
    }
    dialogClose2() {
        testoCronologia = '';
        this.dialogInstance2.hide();
        //this.setState({ showDialog2: false });
    }
    dialogOpen2() {        
        document.getElementById('cronologiaDialog').ej2_instances[0].position = {X: 'center', Y: 'center'};   
        testoCronologia = `${moment().format('DD/MM/YYYY HH:mm')}<br/><br/>------------------------<br/><br/>${testoCronologia}`;           
        document.getElementById('cronologiaRTE').ej2_instances[0].value = testoCronologia.replace(/&amp;lt;/g, '<').replace(/&amp;gt;/g, '>').replace(/&quot;/g, '"');
        setTimeout(() => {     //PATCH - TODO : Appena possibile trovare soluzione corretta
            document.getElementById('cronologiaRTE_toolbar_SourceCode').click();
            document.getElementById('cronologiaRTE_toolbar_Preview').click();                        
        }, 300);           
    }
    dialogClose3() {
        this.dialogInstance3.hide();
        //this.setState({ showDialog3: false });
    }
    dialogOpen3() {        
        document.getElementById('infoPeritoDialog').ej2_instances[0].position = {X: 'center', Y: 'center'};   
    }
    dialogClose4() {
        this.dialogInstance4.hide();
        //this.setState({ showDialog4: false });
    }
    dialogOpen4() {        
        document.getElementById('situazionePeritiDialog').ej2_instances[0].position = {X: 'center', Y: 'center'};   
    }
    dialogClose5() {
        this.dialogInstance5.hide();
        //this.setState({ showDialog5: false });
    }
    dialogOpen5() {  
        document.getElementById('sceltaEmailPeritiDialog').ej2_instances[0].content = '<span style="font-size: 0.8vw;">Scegli il tipo di operazione desiderata :<br/><br/>- <b>INVIA A TUTTI</b> --> manda in modo automatico una email di avviso a tutti i periti con situazioni scadute/in scadenza, senza ulteriori conferme. Porre attenzione al fatto che in questo caso il numero di email inviate automaticamente potrebbe essere molto alto.<br/><br/>- <b>SCEGLI PER OGNUNO</b> --> permette di vedere tutte le email, in sequenza, per i periti con situazioni scadute/in scadenza, permettendo di scegliere a quale perito inviare la email o meno.</span>';   
        document.getElementById('sceltaEmailPeritiDialog').ej2_instances[0].position = {X: 'center', Y: 'center'};   
    }   
    invioEmailAvvisoPerito() {
        let IdComp = JSON.parse(localStorage.getItem("CompagnieSelezionate"))[document.getElementById('TabRosibot').ej2_instances[0].selectedItem].IdCompagnia;
        let descrComp = JSON.parse(localStorage.getItem("CompagnieSelezionate"))[document.getElementById('TabRosibot').ej2_instances[0].selectedItem].DescrizioneCompagnia;
        let tipoOper = localStorage.getItem('tipoOperativita');
        let testoMessaggio = this.RTEInstance.value; 
        let soggettoEmailPeritoTemp = (document.getElementById(`chkGestioneRamo${IdComp}`).ej2_instances[0].checked && document.getElementById(`ddlGestioneRamo${IdComp}`).ej2_instances[0].value === 'CREDRASRAMO50') ? soggettoEmailPeritoAllianz.format2(descrComp) : soggettoEmailPerito.format2(descrComp);   
        let dataObj = {
            server: document.getElementById('smtp_server').ej2_instances[0].value,
            porta: document.getElementById('smtp_porta').ej2_instances[0].value,
            utente: document.getElementById('smtp_utente').ej2_instances[0].value,
            password: document.getElementById('smtp_password').ej2_instances[0].value,
            SSL: document.getElementById('smtp_SSL').ej2_instances[0].checked,
            mittente: document.getElementById('nome_mittente').ej2_instances[0].value,
            destinatario: this.chkboxInstance2.checked ? document.getElementById('email_mittente').ej2_instances[0].value : indirizzoEmailPerito, // document.getElementById('email_mittente').ej2_instances[0].value, //in prod per else mettere indirizzoEmailPerito,
            emailMittente: document.getElementById('email_mittente').ej2_instances[0].value,
            emailCC: document.getElementById('email_cc').ej2_instances[0].value,
            soggetto: tipoOper === 'SURV' ? soggettoEmailSurveyor.format2(descrComp) : tipoOper === 'S' ? soggettoEmailPeritoTemp : soggettoEmailPeritoInterloc.format2(descrComp),
            //messaggioTesto: 'Messaggio di prova in formato testo...',
            messaggioHtml: testoMessaggio
        }    
        this.toggleBlocking();
        CustomJS.callAPI(`${APIRosibotURL}/api/Rosibot/sendEmail`, JSON.stringify(dataObj), 'POST',
            'application/json', retData => {
                if (retData.response === 'KO')
                    toast.error(`Attenzione!! Parametri per invio Email errati. L\' errore riscontrato è il seguente : ${JSON.stringify(retData.msg)}  `, {
                        onClose: () => toast.dismiss(),
                        position: "top-center",
                        autoClose: 15000,
                        hideProgressBar: false,            
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });  
                else
                    toast.success('Email di avviso inviata con successo!!', {
                        onClose: () => toast.dismiss(),
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,            
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });                 
                this.toggleBlocking();
        }, error => {
            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? messaggioAPIBackendNonTrovata : messaggioNoBackend;
            toast.warn(messaggio, {
                onClose: () => toast.dismiss(),
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true});            
            this.toggleBlocking();
        }, true, false)
    }
    invioEmailAvvisoPerito2() {
        let IdComp = JSON.parse(localStorage.getItem("CompagnieSelezionate"))[document.getElementById('TabRosibot').ej2_instances[0].selectedItem].IdCompagnia;
        let descrComp = JSON.parse(localStorage.getItem("CompagnieSelezionate"))[document.getElementById('TabRosibot').ej2_instances[0].selectedItem].DescrizioneCompagnia;
        let tipoOper = localStorage.getItem('tipoOperativita');
        let testoMessaggio = testoEmailPerito; 
        let soggettoEmailPeritoTemp = (document.getElementById(`chkGestioneRamo${IdComp}`).ej2_instances[0].checked && document.getElementById(`ddlGestioneRamo${IdComp}`).ej2_instances[0].value === 'CREDRASRAMO50') ? soggettoEmailPeritoAllianz.format2(descrComp) : soggettoEmailPerito.format2(descrComp);   
        let dataObj = {
            server: document.getElementById('smtp_server').ej2_instances[0].value,
            porta: document.getElementById('smtp_porta').ej2_instances[0].value,
            utente: document.getElementById('smtp_utente').ej2_instances[0].value,
            password: document.getElementById('smtp_password').ej2_instances[0].value,
            SSL: document.getElementById('smtp_SSL').ej2_instances[0].checked,
            mittente: document.getElementById('nome_mittente').ej2_instances[0].value,
            destinatario: indirizzoEmailPerito, // document.getElementById('email_mittente').ej2_instances[0].value, //in prod mettere indirizzoEmailPerito,
            emailMittente: document.getElementById('email_mittente').ej2_instances[0].value,
            emailCC: document.getElementById('email_cc').ej2_instances[0].value,
            soggetto: tipoOper === 'SURV' ? soggettoEmailSurveyor.format2(descrComp) : tipoOper === 'S' ? soggettoEmailPeritoTemp : soggettoEmailPeritoInterloc.format2(descrComp),
            //messaggioTesto: 'Messaggio di prova in formato testo...',
            messaggioHtml: testoMessaggio
        }     
        CustomJS.callAPI(`${APIRosibotURL}/api/Rosibot/sendEmail`, JSON.stringify(dataObj), 'POST',
            'application/json', retData => {
                if (retData.response === 'KO')
                    toast.error(`Attenzione!! Parametri per invio Email errati. L\' errore riscontrato è il seguente : ${JSON.stringify(retData.msg)}  `, {
                        onClose: () => toast.dismiss(),
                        position: "top-right",
                        autoClose: 15000,
                        hideProgressBar: false,            
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });  
                else
                    toast.success('Email di avviso inviata con successo!!', {
                        onClose: () => toast.dismiss(),
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,            
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });                 
        }, error => {
            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? messaggioAPIBackendNonTrovata : messaggioNoBackend;
            toast.warn(messaggio, {
                onClose: () => toast.dismiss(),
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true});            
        }, true, false)
    }
    creaEmailPerito() {
        let IdComp = JSON.parse(localStorage.getItem("CompagnieSelezionate"))[document.getElementById('TabRosibot').ej2_instances[0].selectedItem].IdCompagnia;
        let tipoOper = localStorage.getItem('tipoOperativita');
        let nomePerito = '';    
        let modelloHeaderEmailTemp = (document.getElementById(`chkGestioneRamo${IdComp}`).ej2_instances[0].checked && document.getElementById(`ddlGestioneRamo${IdComp}`).ej2_instances[0].value === 'CREDRASRAMO50') ? modelloHeaderEmailAllianzCRR50 : (document.getElementById(`chkGestioneRamo${IdComp}`).ej2_instances[0].checked && document.getElementById(`ddlGestioneRamo${IdComp}`).ej2_instances[0].value === 'ALTRO') ? modelloHeaderEmailAllianzALTRO : modelloHeaderEmail;   
        testoEmailPerito = tipoOper === 'S' ? modelloHeaderEmailTemp : tipoOper === 'I' ? modelloHeaderEmailInterloc : modelloHeaderEmailSurveyor  + '<ul>';        
        JSON.parse(localStorage.getItem(`datiRicercaGriglia${IdComp}`)).forEach(elm => {
            nomePerito = elm.NominativoPerito.split(' ')[1];
            indirizzoEmailPerito = elm.IndirizzoEmailPerito;
            if (elm.SecondaScadenza === true) {    
                let dataSecondaScad = tipoOper === 'SURV' ? new Date((<Moment format='YYYY/MM/DD'>{elm.DataAssegnazioneSurveyor}</Moment>).props.children) : new Date((<Moment format='YYYY/MM/DD'>{elm.DataPervenimento}</Moment>).props.children);
                if (tipoOper === 'SURV') {
                    const date1 = new Date((<Moment format='YYYY/MM/DD'>{elm.DataAssegnazioneSurveyor}</Moment>).props.children);
                    const date2 = new Date();
                    const diffTime = Math.abs(date2 - date1);
                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
                    testoEmailPerito += '<li>' + modelloEmailScadenzaSurveyor.format2(elm.IdSinistro, elm.NominativoContraenteAssicurato,
                                        diffDays.toString(), elm.NominativoSurveyor.toUpperCase()) + '</li><br>';
                }
                else {
                    if (document.getElementById(`chkGestioneRamo${IdComp}`).ej2_instances[0].checked 
                        && document.getElementById(`ddlGestioneRamo${IdComp}`).ej2_instances[0].value === 'CREDRASRAMO50') {
                        dataSecondaScad.setDate(dataSecondaScad.getDate() + document.getElementById(`secondaScadCRR50${IdComp}`).ej2_instances[0].value);
                    }
                    else if (document.getElementById(`chkGestioneRamo${IdComp}`).ej2_instances[0].checked 
                            && document.getElementById(`ddlGestioneRamo${IdComp}`).ej2_instances[0].value === 'ALTRO') {
                            dataSecondaScad.setDate(dataSecondaScad.getDate() + document.getElementById(`scadAltro${IdComp}`).ej2_instances[0].value);
                    }
                    else 
                        dataSecondaScad.setDate(dataSecondaScad.getDate() + document.getElementById(`secondaScadenza${IdComp}`).ej2_instances[0].value);                    
                    testoEmailPerito += '<li>' + modelloEmailSecondaScadenza.format2(elm.IdSinistro, elm.DescrTipoSinistro, elm.NominativoContraenteAssicurato,
                                        CustomJS.getDayName(dataSecondaScad, "it-IT"), dataSecondaScad.toLocaleDateString()) + '</li><br>';
                }            
            }
            if (elm.PrimaScadenza === true) {
                let dataSecondaScad = tipoOper === 'SURV' ? new Date((<Moment format='YYYY/MM/DD'>{elm.DataAssegnazioneSurveyor}</Moment>).props.children) : new Date((<Moment format='YYYY/MM/DD'>{elm.DataPervenimento}</Moment>).props.children);
                if (tipoOper === 'SURV') {
                    const date1 = new Date((<Moment format='YYYY/MM/DD'>{elm.DataAssegnazioneSurveyor}</Moment>).props.children);
                    const date2 = new Date();
                    const diffTime = Math.abs(date2 - date1);
                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
                    testoEmailPerito += '<li>' + modelloEmailScadenzaSurveyor.format2(elm.IdSinistro, elm.NominativoContraenteAssicurato,
                                        diffDays.toString(), elm.NominativoSurveyor.toUpperCase()) + '</li><br>';
                    }
                else {
                    if (document.getElementById(`chkGestioneRamo${IdComp}`).ej2_instances[0].checked 
                        && document.getElementById(`ddlGestioneRamo${IdComp}`).ej2_instances[0].value === 'CREDRASRAMO50') {
                        dataSecondaScad.setDate(dataSecondaScad.getDate() + document.getElementById(`secondaScadCRR50${IdComp}`).ej2_instances[0].value);
                    }
                    else if (document.getElementById(`chkGestioneRamo${IdComp}`).ej2_instances[0].checked 
                             && document.getElementById(`ddlGestioneRamo${IdComp}`).ej2_instances[0].value === 'ALTRO') {
                             dataSecondaScad.setDate(dataSecondaScad.getDate() + document.getElementById(`scadAltro${IdComp}`).ej2_instances[0].value);
                    }
                    else 
                       dataSecondaScad.setDate(dataSecondaScad.getDate() + document.getElementById(`secondaScadenza${IdComp}`).ej2_instances[0].value); 
                    testoEmailPerito += '<li>' + modelloEmailPrimaScadenza.format2(elm.IdSinistro, elm.DescrTipoSinistro, elm.NominativoContraenteAssicurato,
                                        CustomJS.getDayName(dataSecondaScad, "it-IT"), dataSecondaScad.toLocaleDateString()) + '</li><br>';
                }
            }
            if (elm.InScadenza === true) {
                let dataPrimaScad = new Date((<Moment format='YYYY/MM/DD'>{elm.DataPervenimento}</Moment>).props.children);
                if (document.getElementById(`chkGestioneRamo${IdComp}`).ej2_instances[0].checked 
                    && document.getElementById(`ddlGestioneRamo${IdComp}`).ej2_instances[0].value === 'CREDRASRAMO50') {
                        dataPrimaScad.setDate(dataPrimaScad.getDate() + document.getElementById(`primaScadCRR50${IdComp}`).ej2_instances[0].value);
                }                
                else 
                    dataPrimaScad.setDate(dataPrimaScad.getDate() + document.getElementById(`primaScadenza${IdComp}`).ej2_instances[0].value);
                testoEmailPerito += '<li>' + modelloEmailInScadenza.format2(elm.IdSinistro, elm.DescrTipoSinistro, elm.NominativoContraenteAssicurato,
                                    CustomJS.getDayName(dataPrimaScad, "it-IT"), dataPrimaScad.toLocaleDateString()) + '</li><br>';
            }   
            if (elm.PreavvisoScadUltimaInterloc === true) {
                testoEmailPerito += '<li>' + modelloEmailPreavvScadUltimaInterloc.format2(elm.IdSinistro, elm.NominativoContraenteAssicurato,
                                    document.getElementById(`scadenzaUltimaInterloc${IdComp}`).value.toString()) + '</li><br>';
            }   
            if (elm.ScadenzaUltimaInterloc === true) {
                testoEmailPerito += '<li>' + modelloEmailScadUltimaInterloc.format2(elm.IdSinistro, elm.NominativoContraenteAssicurato,
                                    document.getElementById(`scadenzaUltimaInterloc${IdComp}`).value.toString()) + '</li><br>';
            } 
            if (elm.PrimaScadSenzaInterloc === true) {
                testoEmailPerito += '<li>' + modelloEmailPrimaScadSenzaInterloc.format2(elm.IdSinistro, elm.NominativoContraenteAssicurato,
                                    document.getElementById(`primaScadSenzaInterloc${IdComp}`).value.toString()) + '</li><br>';
            }   
            if (elm.SecondaScadSenzaInterloc === true) {
                testoEmailPerito += '<li>' + modelloEmailSecondaScadSenzaInterloc.format2(elm.IdSinistro, elm.NominativoContraenteAssicurato,
                                    document.getElementById(`primaScadSenzaInterloc${IdComp}`).value.toString(), document.getElementById(`secondaScadSenzaInterloc${IdComp}`).value.toString()) + '</li><br>';
            }           
        });    
        testoEmailPerito += '</ul>' + ((tipoOper === 'S' && !document.getElementById(`chkGestioneRamo${IdComp}`).ej2_instances[0].checked) || (tipoOper === 'S' && document.getElementById(`chkGestioneRamo${IdComp}`).ej2_instances[0].checked && document.getElementById(`ddlGestioneRamo${IdComp}`).ej2_instances[0].value === 'CREDRASRAMO50') ? modelloFooterEmail.format2(window.localStorage.getItem("NominativoReferente")) : (tipoOper === 'S' && document.getElementById(`chkGestioneRamo${IdComp}`).ej2_instances[0].checked && document.getElementById(`ddlGestioneRamo${IdComp}`).ej2_instances[0].value === 'ALTRO') ? modelloFooterEmailAllianzALTRO.format2(window.localStorage.getItem("NominativoReferente")) : (tipoOper === 'I') ? modelloFooterEmailInterloc.format2(window.localStorage.getItem("NominativoReferente")) : modelloFooterEmailSurveyor.format2(window.localStorage.getItem("NominativoReferente")));   
        testoEmailPerito = testoEmailPerito.format2(nomePerito);  
    }
    creaEmailTuttiPeriti(invioCiclico) {
        this.toggleBlocking();
        this.elencoEmailPeriti = [];
        let IdComp = JSON.parse(localStorage.getItem("CompagnieSelezionate"))[document.getElementById('TabRosibot').ej2_instances[0].selectedItem].IdCompagnia;
        let tipoOper = localStorage.getItem('tipoOperativita');
        document.getElementById(`ddlPeriti${IdComp}`).ej2_instances[0].dataSource.forEach(elm => {
            if (CustomJS.isNullEmpty(elm.Value)) return;            
            let dsGriglia = document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].dataSource;
            new DataManager(dsGriglia) 
                .executeQuery(new Query().search(elm.Text, ['NominativoPerito'])) 
                .then((e) => { 
                    let rows = e.result; 
                    rows.forEach(elm2 => {
                        const date1 = tipoOper === 'SURV' ? new Date((<Moment format='YYYY/MM/DD'>{elm2.DataAssegnazioneSurveyor}</Moment>).props.children) : new Date((<Moment format='YYYY/MM/DD'>{elm2.DataPervenimento}</Moment>).props.children);
                        const date2 = new Date();
                        const date3 = CustomJS.isNullEmpty(elm2.DataUltimaInterlocutoria) ? 'ND' : new Date((<Moment format='YYYY/MM/DD'>{elm2.DataUltimaInterlocutoria}</Moment>).props.children); 
                        const diffTime = Math.abs(date2 - date1);
                        const diffTime2 = date3 === 'ND' ? 'ND' : Math.abs(date2 - date3);
                        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));  
                        const diffDays2 = diffTime2 === 'ND' ? 'ND' : Math.ceil(diffTime2 / (1000 * 60 * 60 * 24));   
                        if (tipoOper === 'S') {
                            if (document.getElementById(`chkGestioneRamo${IdComp}`).ej2_instances[0].checked 
                                && document.getElementById(`ddlGestioneRamo${IdComp}`).ej2_instances[0].value === 'CREDRASRAMO50') {
                                if (diffDays > document.getElementById(`primaScadCRR50${IdComp}`).ej2_instances[0].value 
                                    && diffDays <= document.getElementById(`secondaScadCRR50${IdComp}`).ej2_instances[0].value)                                 
                                    elm2.PrimaScadenza = true;                            
                                else if (diffDays > document.getElementById(`secondaScadCRR50${IdComp}`).ej2_instances[0].value)                                 
                                    elm2.SecondaScadenza = true; 
                                else if ((diffDays + document.getElementById(`preavvisoScadCRR50${IdComp}`).ej2_instances[0].value) > document.getElementById(`primaScadCRR50${IdComp}`).ej2_instances[0].value) 
                                    elm2.InScadenza = true;  
                            }
                            else if (document.getElementById(`chkGestioneRamo${IdComp}`).ej2_instances[0].checked 
                                     && document.getElementById(`ddlGestioneRamo${IdComp}`).ej2_instances[0].value === 'ALTRO') {
                                    if (diffDays > document.getElementById(`scadAltro${IdComp}`).ej2_instances[0].value)                                 
                                        elm2.SecondaScadenza = true;
                            }
                            else if (document.getElementById(`primaScadenza${IdComp}`).ej2_instances[0].value > 0 ||
                                document.getElementById(`secondaScadenza${IdComp}`).ej2_instances[0].value > 0) {
                                if (diffDays > document.getElementById(`primaScadenza${IdComp}`).ej2_instances[0].value 
                                    && diffDays <= document.getElementById(`secondaScadenza${IdComp}`).ej2_instances[0].value)                                 
                                    elm2.PrimaScadenza = true;                            
                                else if (diffDays > document.getElementById(`secondaScadenza${IdComp}`).ej2_instances[0].value)                                 
                                    elm2.SecondaScadenza = true;                            
                                else if ((diffDays + document.getElementById(`giorniPreavviso${IdComp}`).ej2_instances[0].value) > document.getElementById(`primaScadenza${IdComp}`).ej2_instances[0].value) 
                                    elm2.InScadenza = true;                            
                            }
                        }
                        else if (tipoOper === 'I') {
                            if (diffDays2 === 'ND' && diffDays > document.getElementById(`secondaScadSenzaInterloc${IdComp}`).ej2_instances[0].value)
                                elm2.SecondaScadSenzaInterloc = true;
                            else if (diffDays2 === 'ND' && diffDays > document.getElementById(`primaScadSenzaInterloc${IdComp}`).ej2_instances[0].value 
                                     && diffDays <= document.getElementById(`secondaScadSenzaInterloc${IdComp}`).ej2_instances[0].value)
                                elm2.PrimaScadSenzaInterloc = true;
                            else if (diffDays2 > document.getElementById(`scadenzaUltimaInterloc${IdComp}`).ej2_instances[0].value)
                                elm2.ScadenzaUltimaInterloc = true;
                            else if ((diffDays2 + document.getElementById(`preavvisoScadUltimaInterloc${IdComp}`).ej2_instances[0].value) > document.getElementById(`scadenzaUltimaInterloc${IdComp}`).ej2_instances[0].value) 
                                elm2.PreavvisoScadUltimaInterloc = true;   
                        }
                        else if (tipoOper === 'SURV') {
                            if (document.getElementById(`primaScadenzaSurv${IdComp}`).ej2_instances[0].value > 0 ||
                                document.getElementById(`secondaScadenzaSurv${IdComp}`).ej2_instances[0].value > 0) {
                                if (diffDays > document.getElementById(`primaScadenzaSurv${IdComp}`).ej2_instances[0].value 
                                    && diffDays <= document.getElementById(`secondaScadenzaSurv${IdComp}`).ej2_instances[0].value)                                 
                                    elm2.PrimaScadenza = true;                            
                                else if (diffDays > document.getElementById(`secondaScadenzaSurv${IdComp}`).ej2_instances[0].value)                                 
                                    elm2.SecondaScadenza = true;                            
                             }
                        }
                    });   
                    let inviaEmail = false;
                    let modelloHeaderEmailTemp = (document.getElementById(`chkGestioneRamo${IdComp}`).ej2_instances[0].checked && document.getElementById(`ddlGestioneRamo${IdComp}`).ej2_instances[0].value === 'CREDRASRAMO50') ? modelloHeaderEmailAllianzCRR50 : (document.getElementById(`chkGestioneRamo${IdComp}`).ej2_instances[0].checked && document.getElementById(`ddlGestioneRamo${IdComp}`).ej2_instances[0].value === 'ALTRO') ? modelloHeaderEmailAllianzALTRO : modelloHeaderEmail;   
                    testoEmailPerito = tipoOper === 'S' ? modelloHeaderEmailTemp : tipoOper === 'I' ? modelloHeaderEmailInterloc : modelloHeaderEmailSurveyor  + '<ul>';  
                    let nomePerito = '';       
                    rows.forEach(elm3 => {
                        nomePerito = elm3.NominativoPerito.split(' ')[1];
                        indirizzoEmailPerito = elm3.IndirizzoEmailPerito;
                        if (elm3.SecondaScadenza === true) {    
                            inviaEmail = true;            
                            let dataSecondaScad = tipoOper === 'SURV' ? new Date((<Moment format='YYYY/MM/DD'>{elm3.DataAssegnazioneSurveyor}</Moment>).props.children) : new Date((<Moment format='YYYY/MM/DD'>{elm3.DataPervenimento}</Moment>).props.children);
                            if (tipoOper === 'SURV') {
                                const date1 = new Date((<Moment format='YYYY/MM/DD'>{elm3.DataAssegnazioneSurveyor}</Moment>).props.children);
                                const date2 = new Date();
                                const diffTime = Math.abs(date2 - date1);
                                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));                     
                                testoEmailPerito += '<li>' + modelloEmailScadenzaSurveyor.format2(elm3.IdSinistro, elm3.NominativoContraenteAssicurato,
                                                    diffDays.toString(), elm3.NominativoSurveyor.toUpperCase()) + '</li><br>';
                            }
                            else {
                                if (document.getElementById(`chkGestioneRamo${IdComp}`).ej2_instances[0].checked 
                                    && document.getElementById(`ddlGestioneRamo${IdComp}`).ej2_instances[0].value === 'CREDRASRAMO50') {
                                        dataSecondaScad.setDate(dataSecondaScad.getDate() + document.getElementById(`secondaScadCRR50${IdComp}`).ej2_instances[0].value);
                                    }
                                    else if (document.getElementById(`chkGestioneRamo${IdComp}`).ej2_instances[0].checked 
                                            && document.getElementById(`ddlGestioneRamo${IdComp}`).ej2_instances[0].value === 'ALTRO') {
                                            dataSecondaScad.setDate(dataSecondaScad.getDate() + document.getElementById(`scadAltro${IdComp}`).ej2_instances[0].value);
                                    }
                                else 
                                    dataSecondaScad.setDate(dataSecondaScad.getDate() + document.getElementById(`secondaScadenza${IdComp}`).ej2_instances[0].value);                    
                                testoEmailPerito += '<li>' + modelloEmailSecondaScadenza.format2(elm3.IdSinistro, elm3.DescrTipoSinistro, elm3.NominativoContraenteAssicurato,
                                                    CustomJS.getDayName(dataSecondaScad, "it-IT"), dataSecondaScad.toLocaleDateString()) + '</li><br>';
                            }
                        }
                        if (elm3.PrimaScadenza === true) {
                            inviaEmail = true; 
                            let dataSecondaScad = tipoOper === 'SURV' ? new Date((<Moment format='YYYY/MM/DD'>{elm3.DataAssegnazioneSurveyor}</Moment>).props.children) : new Date((<Moment format='YYYY/MM/DD'>{elm3.DataPervenimento}</Moment>).props.children);
                            if (tipoOper === 'SURV') {
                                const date1 = new Date((<Moment format='YYYY/MM/DD'>{elm3.DataAssegnazioneSurveyor}</Moment>).props.children);
                                const date2 = new Date();
                                const diffTime = Math.abs(date2 - date1);
                                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                                testoEmailPerito += '<li>' + modelloEmailScadenzaSurveyor.format2(elm3.IdSinistro, elm3.NominativoContraenteAssicurato,
                                                    diffDays.toString(), elm3.NominativoSurveyor.toUpperCase(), dataSecondaScad.toLocaleDateString()) + '</li><br>';
                             }
                            else {
                                if (document.getElementById(`chkGestioneRamo${IdComp}`).ej2_instances[0].checked 
                                    && document.getElementById(`ddlGestioneRamo${IdComp}`).ej2_instances[0].value === 'CREDRASRAMO50') {
                                    dataSecondaScad.setDate(dataSecondaScad.getDate() + document.getElementById(`secondaScadCRR50${IdComp}`).ej2_instances[0].value);
                                }
                                else if (document.getElementById(`chkGestioneRamo${IdComp}`).ej2_instances[0].checked 
                                        && document.getElementById(`ddlGestioneRamo${IdComp}`).ej2_instances[0].value === 'ALTRO') {
                                        dataSecondaScad.setDate(dataSecondaScad.getDate() + document.getElementById(`scadAltro${IdComp}`).ej2_instances[0].value);
                                }
                                else 
                                    dataSecondaScad.setDate(dataSecondaScad.getDate() + document.getElementById(`secondaScadenza${IdComp}`).ej2_instances[0].value); 
                                testoEmailPerito += '<li>' + modelloEmailPrimaScadenza.format2(elm3.IdSinistro, elm3.DescrTipoSinistro, elm3.NominativoContraenteAssicurato,
                                                    CustomJS.getDayName(dataSecondaScad, "it-IT"), dataSecondaScad.toLocaleDateString()) + '</li><br>';
                            }
                        }
                        if (elm3.InScadenza === true) {
                            inviaEmail = true; 
                            let dataPrimaScad = new Date((<Moment format='YYYY/MM/DD'>{elm3.DataPervenimento}</Moment>).props.children);
                            if (document.getElementById(`chkGestioneRamo${IdComp}`).ej2_instances[0].checked 
                                && document.getElementById(`ddlGestioneRamo${IdComp}`).ej2_instances[0].value === 'CREDRASRAMO50') {
                                dataPrimaScad.setDate(dataPrimaScad.getDate() + document.getElementById(`primaScadCRR50${IdComp}`).ej2_instances[0].value);
                            }                            
                            else 
                                dataPrimaScad.setDate(dataPrimaScad.getDate() + document.getElementById(`primaScadenza${IdComp}`).ej2_instances[0].value);
                            testoEmailPerito += '<li>' + modelloEmailInScadenza.format2(elm3.IdSinistro, elm3.DescrTipoSinistro, elm3.NominativoContraenteAssicurato,
                                                CustomJS.getDayName(dataPrimaScad, "it-IT"), dataPrimaScad.toLocaleDateString()) + '</li><br>';
                        }  
                        if (elm3.PreavvisoScadUltimaInterloc === true) {
                            inviaEmail = true;
                            testoEmailPerito += '<li>' + modelloEmailPreavvScadUltimaInterloc.format2(elm3.IdSinistro, elm3.NominativoContraenteAssicurato,
                                                document.getElementById(`scadenzaUltimaInterloc${IdComp}`).value.toString()) + '</li><br>';
                        }   
                        if (elm3.ScadenzaUltimaInterloc === true) {
                            inviaEmail = true;
                            testoEmailPerito += '<li>' + modelloEmailScadUltimaInterloc.format2(elm3.IdSinistro, elm3.NominativoContraenteAssicurato,
                                                document.getElementById(`scadenzaUltimaInterloc${IdComp}`).value.toString()) + '</li><br>';
                        } 
                        if (elm3.PrimaScadSenzaInterloc === true) {
                            inviaEmail = true;
                            testoEmailPerito += '<li>' + modelloEmailPrimaScadSenzaInterloc.format2(elm3.IdSinistro, elm3.NominativoContraenteAssicurato,
                                                document.getElementById(`primaScadSenzaInterloc${IdComp}`).value.toString()) + '</li><br>';
                        }   
                        if (elm3.SecondaScadSenzaInterloc === true) {
                            inviaEmail = true;
                            testoEmailPerito += '<li>' + modelloEmailSecondaScadSenzaInterloc.format2(elm3.IdSinistro, elm3.NominativoContraenteAssicurato,
                                                document.getElementById(`primaScadSenzaInterloc${IdComp}`).value.toString(), document.getElementById(`secondaScadSenzaInterloc${IdComp}`).value.toString()) + '</li><br>';
                        }                       
                    });   
                    testoEmailPerito += '</ul>' + ((tipoOper === 'S' && !document.getElementById(`chkGestioneRamo${IdComp}`).ej2_instances[0].checked) || (tipoOper === 'S' && document.getElementById(`chkGestioneRamo${IdComp}`).ej2_instances[0].checked && document.getElementById(`ddlGestioneRamo${IdComp}`).ej2_instances[0].value === 'CREDRASRAMO50') ? modelloFooterEmail.format2(window.localStorage.getItem("NominativoReferente")) : (tipoOper === 'S' && document.getElementById(`chkGestioneRamo${IdComp}`).ej2_instances[0].checked && document.getElementById(`ddlGestioneRamo${IdComp}`).ej2_instances[0].value === 'ALTRO') ? modelloFooterEmailAllianzALTRO.format2(window.localStorage.getItem("NominativoReferente")) : (tipoOper === 'I') ? modelloFooterEmailInterloc.format2(window.localStorage.getItem("NominativoReferente")) : modelloFooterEmailSurveyor.format2(window.localStorage.getItem("NominativoReferente")));   
                    testoEmailPerito = testoEmailPerito.format2(nomePerito);
                    if (invioCiclico && inviaEmail)        
                        this.invioEmailAvvisoPerito2();                    
                    else if (!invioCiclico && inviaEmail)
                        this.elencoEmailPeriti.push({ NominativoPerito: elm.Text, TestoEmailPerito: testoEmailPerito, IndirizzoEmailPerito: indirizzoEmailPerito });                    
                }); 
        });
        this.toggleBlocking();
    }
    salvaCronologiaPerito() {
        let IdComp = JSON.parse(localStorage.getItem("CompagnieSelezionate"))[document.getElementById('TabRosibot').ej2_instances[0].selectedItem].IdCompagnia;
        let tipoOper = localStorage.getItem('tipoOperativita');
        let dataObj = {
            id_compagnia: IdComp,
            tipo_oper: tipoOper,
            testo_cronologia: this.RTEInstance2.value,              
        }
        CustomJS.callAPI(`${APIRosibotURL}/api/Rosibot/salvaCronologiaRispostePerito/${this.sinistroCronologia}`, JSON.stringify(dataObj), 'POST',
            'application/json', retData => {
                if (retData.response === 'KO')
                    toast.error(`Attenzione!! Errore durante il salvataggio della cronologia delle risposte del perito. L\' errore riscontrato è il seguente : ${JSON.stringify(retData.msg)}  `, {
                        onClose: () => toast.dismiss(),
                        position: "top-center",
                        autoClose: 15000,
                        hideProgressBar: false,            
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });  
                else {
                    toast.success('Salvataggio cronologia risposte del perito effettuato con successo!!', {
                        onClose: () => toast.dismiss(),
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,            
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });  
                    this.RTEInstance2.value = '';
                    this.dialogInstance2.hide();
                }
                    
        }, error => {
            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? messaggioAPIBackendNonTrovata : messaggioNoBackend;
            toast.warn(messaggio, {
                onClose: () => toast.dismiss(),
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true})
        }, true, false)
    }
    caricaInfoPerito() {
        let IdComp = JSON.parse(localStorage.getItem("CompagnieSelezionate"))[document.getElementById('TabRosibot').ej2_instances[0].selectedItem].IdCompagnia;
        let descrComp =`Compagnia: ${JSON.parse(localStorage.getItem("CompagnieSelezionate"))[document.getElementById('TabRosibot').ej2_instances[0].selectedItem].DescrizioneCompagnia}`;
        document.getElementById('nomePeritoInfo').innerHTML = document.getElementById(`ddlPeriti${IdComp}`).ej2_instances[0].text;
        document.getElementById('compagniaInfo').innerHTML = descrComp;
        let contaInScad = 0, contaPrimaScad = 0, contaSecondaScad = 0;
        JSON.parse(window.localStorage.getItem(`datiRicercaGriglia${IdComp}`)).forEach(elm => {
            if (elm.InScadenza === true)
                contaInScad++;
            if (elm.PrimaScadenza === true)
                contaPrimaScad++;
            if (elm.SecondaScadenza === true)
                contaSecondaScad++;
        });
        document.getElementById('sinInScadInfo').innerHTML = contaInScad.toLocaleString('it-IT');
        document.getElementById('sinPrimaScadInfo').innerHTML = contaPrimaScad.toLocaleString('it-IT');
        document.getElementById('sinSecondaScadInfo').innerHTML = contaSecondaScad.toLocaleString('it-IT');
        CustomJS.getRemoteData2(CustomJS.APIUrlProd,
            `token=${CustomJS.tokenProd}&azione=scaricoCompagnie&raggruppamento=rerc`, 
            'POST', "application/x-www-form-urlencoded", (retData) => { 
                let globalChartData = [];              
                retData.forEach((elm, idx) => {
                    let peritoSel = _.find(globalChartData, (obj) => { return obj.IdPerito === elm.IdPerito.toString(); });
                    if (CustomJS.isNullEmpty(peritoSel)) {
                        if (IdComp.toString() === elm.IdCompagnia && document.getElementById(`ddlPeriti${IdComp}`).ej2_instances[0].value === elm.IdPerito.toString()) { 
                            let objPerito = {};                            
                            let percChiusi = ((elm.NumSinistriChiusi / elm.NumSinistriEntrati) * 100) < 100 && ((elm.NumSinistriChiusi / elm.NumSinistriEntrati) * 100) > 0 
                                               && (((elm.NumSinistriChiusi / elm.NumSinistriEntrati) * 100) - Math.floor(((elm.NumSinistriChiusi / elm.NumSinistriEntrati) * 100))) !== 0
                                              ? ((elm.NumSinistriChiusi / elm.NumSinistriEntrati) * 100).toFixed(2)
                                              : ((elm.NumSinistriChiusi / elm.NumSinistriEntrati) * 100).toFixed(0);
                            objPerito[elm.IdCompagnia] = `${parseFloat(percChiusi).toLocaleString()}% (${elm.NumSinistriChiusi.toString()}/${elm.NumSinistriEntrati.toString()})`;
                            objPerito.TotSinistriChiusi = elm.NumSinistriChiusi;
                            objPerito.TotSinistriEntrati = elm.NumSinistriEntrati;
                            if (elm.Raggruppamento === 'RE') {
                                objPerito.TotSinistriChiusiRE = elm.NumSinistriChiusi;
                                objPerito.TotSinistriEntratiRE = elm.NumSinistriEntrati;
                                objPerito.TotSinistriChiusiRC = 0;
                                objPerito.TotSinistriEntratiRC = 0;
                                objPerito.GiorniMediRientroRE = elm.GiorniMediRientro;
                                objPerito.PagatoMedioRE = elm.PagatoMedio;
                                objPerito.GiorniMediRientroRC = 0;
                                objPerito.PagatoMedioRC = 0;
                            }
                            else if (elm.Raggruppamento === 'RC') {
                                objPerito.TotSinistriChiusiRC = elm.NumSinistriChiusi;
                                objPerito.TotSinistriEntratiRC = elm.NumSinistriEntrati;
                                objPerito.TotSinistriChiusiRE = 0;
                                objPerito.TotSinistriEntratiRE = 0;
                                objPerito.GiorniMediRientroRC = elm.GiorniMediRientro;
                                objPerito.PagatoMedioRC = elm.PagatoMedio;
                                objPerito.GiorniMediRientroRE = 0;
                                objPerito.PagatoMedioRE = 0;
                            }             
                            objPerito.Raggruppamento = elm.Raggruppamento;
                            objPerito.GiorniMediRientro = elm.GiorniMediRientro;
                            objPerito.PagatoMedio = elm.PagatoMedio;
                            globalChartData.push(objPerito);
                        }
                        else {
                            if (IdComp.toString() === elm.IdCompagnia && document.getElementById(`ddlPeriti${IdComp}`).ej2_instances[0].value === elm.IdPerito.toString()) {   
                                let percChiusi = ((elm.NumSinistriChiusi / elm.NumSinistriEntrati) * 100) < 100 && ((elm.NumSinistriChiusi / elm.NumSinistriEntrati) * 100) > 0 
                                                   && (((elm.NumSinistriChiusi / elm.NumSinistriEntrati) * 100) - Math.floor(((elm.NumSinistriChiusi / elm.NumSinistriEntrati) * 100))) !== 0
                                                  ? ((elm.NumSinistriChiusi / elm.NumSinistriEntrati) * 100).toFixed(2)
                                                  : ((elm.NumSinistriChiusi / elm.NumSinistriEntrati) * 100).toFixed(0);                                                      
                                peritoSel[elm.IdCompagnia] = `${parseFloat(percChiusi).toLocaleString()}% (${elm.NumSinistriChiusi.toString()}/${elm.NumSinistriEntrati.toString()})`;
                                peritoSel.GiorniMediRientro = (peritoSel.GiorniMediRientro * peritoSel.TotSinistriChiusi) + (elm.GiorniMediRientro * elm.NumSinistriChiusi) / (peritoSel.TotSinistriChiusi + elm.NumSinistriChiusi);
                                peritoSel.PagatoMedio = (peritoSel.PagatoMedio * peritoSel.TotSinistriChiusi) + (elm.PagatoMedio * elm.NumSinistriChiusi) / (peritoSel.TotSinistriChiusi + elm.NumSinistriChiusi);
                                peritoSel.TotSinistriChiusi += elm.NumSinistriChiusi;
                                peritoSel.TotSinistriEntrati += elm.NumSinistriEntrati;
                                if (elm.Raggruppamento === 'RE') {
                                    if (elm.NumSinistriChiusi > 0) {
                                        peritoSel.GiorniMediRientroRE = (peritoSel.GiorniMediRientroRE * peritoSel.TotSinistriChiusiRE) + (elm.GiorniMediRientro * elm.NumSinistriChiusi) / (peritoSel.TotSinistriChiusiRE + elm.NumSinistriChiusi);
                                        peritoSel.PagatoMedioRE = (peritoSel.PagatoMedioRE * peritoSel.TotSinistriChiusiRE) + (elm.PagatoMedio * elm.NumSinistriChiusi) / (peritoSel.TotSinistriChiusiRE + elm.NumSinistriChiusi);
                                    }
                                    peritoSel.TotSinistriChiusiRE += elm.NumSinistriChiusi;
                                    peritoSel.TotSinistriEntratiRE += elm.NumSinistriEntrati;
                                }
                                else if (elm.Raggruppamento === 'RC') {
                                    if (elm.NumSinistriChiusi > 0) {
                                        peritoSel.GiorniMediRientroRC = (peritoSel.GiorniMediRientroRC * peritoSel.TotSinistriChiusiRC) + (elm.GiorniMediRientro * elm.NumSinistriChiusi) / (peritoSel.TotSinistriChiusiRC + elm.NumSinistriChiusi);
                                        peritoSel.PagatoMedioRC = (peritoSel.PagatoMedioRC * peritoSel.TotSinistriChiusiRC) + (elm.PagatoMedio * elm.NumSinistriChiusi) / (peritoSel.TotSinistriChiusiRC + elm.NumSinistriChiusi);
                                    }
                                    peritoSel.TotSinistriChiusiRC += elm.NumSinistriChiusi;
                                    peritoSel.TotSinistriEntratiRC += elm.NumSinistriEntrati;
                                }   
                            }
                        }
                    }
                });
                document.getElementById('chiusuraSinREInfo').innerHTML = _.isObject(globalChartData[0]) ? globalChartData[0][IdComp].toLocaleString('it-IT') : '0% (0/0)';
                document.getElementById('giorniMediRientroSinREInfo').innerHTML = _.isObject(globalChartData[0]) ? globalChartData[0].GiorniMediRientroRE.toLocaleString('it-IT') : '0';
                document.getElementById('pagatoMedioSinREInfo').innerHTML = _.isObject(globalChartData[0]) ? globalChartData[0].PagatoMedioRE.toLocaleString('it-IT') : '0';
                document.getElementById('chiusuraSinRCInfo').innerHTML = _.isObject(globalChartData[1]) ? globalChartData[1][IdComp].toLocaleString('it-IT') : '0% (0/0)';
                document.getElementById('giorniMediRientroSinRCInfo').innerHTML = _.isObject(globalChartData[1]) ? globalChartData[1].GiorniMediRientroRC.toLocaleString('it-IT') : '0';
                document.getElementById('pagatoMedioSinRCInfo').innerHTML = _.isObject(globalChartData[1]) ? globalChartData[1].PagatoMedioRC.toLocaleString('it-IT') : '0';                                
            }, true, false);
    }
    caricaInfoTuttiPeriti() {
        this.dsInfoTuttiPeriti = [];       
        let IdComp = JSON.parse(localStorage.getItem("CompagnieSelezionate"))[document.getElementById('TabRosibot').ej2_instances[0].selectedItem].IdCompagnia;
        document.getElementById(`ddlPeriti${IdComp}`).ej2_instances[0].dataSource.forEach(elm => {
            if (CustomJS.isNullEmpty(elm.Value)) return;
            let objPerito = {};
            objPerito.IdPerito = elm.Value;
            objPerito.NominativoPerito = elm.Text;
            let dsGriglia = document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].dataSource;
            new DataManager(dsGriglia) 
                .executeQuery(new Query().search(elm.Text, ['NominativoPerito'])) 
                .then((e) => { 
                    let rows = e.result; 
                    rows.forEach(elm2 => {
                        const date1 = new Date((<Moment format='YYYY/MM/DD'>{elm2.DataPervenimento}</Moment>).props.children);
                        const date2 = new Date();
                        const diffTime = Math.abs(date2 - date1);
                        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));    
                        if (document.getElementById(`primaScadenza${IdComp}`).ej2_instances[0].value > 0 ||
                            document.getElementById(`secondaScadenza${IdComp}`).ej2_instances[0].value > 0) {
                            if (diffDays > document.getElementById(`primaScadenza${IdComp}`).ej2_instances[0].value 
                                && diffDays <= document.getElementById(`secondaScadenza${IdComp}`).ej2_instances[0].value)                                 
                                elm2.PrimaScadenza = true;                            
                            else if (diffDays > document.getElementById(`secondaScadenza${IdComp}`).ej2_instances[0].value)                                 
                                elm2.SecondaScadenza = true;                            
                            else if ((diffDays + document.getElementById(`giorniPreavviso${IdComp}`).ej2_instances[0].value) > document.getElementById(`primaScadenza${IdComp}`).ej2_instances[0].value) 
                                elm2.InScadenza = true;                            
                        }
                    });   
                    let contaInScad = 0, contaPrimaScad = 0, contaSecondaScad = 0;
                    rows.forEach(elm3 => {
                        if (elm3.InScadenza === true)
                            contaInScad++;
                        if (elm3.PrimaScadenza === true)
                            contaPrimaScad++;
                        if (elm3.SecondaScadenza === true)
                            contaSecondaScad++;
                    });   
                    objPerito.SinistriSecondaScad = contaSecondaScad;
                    objPerito.SinistriPrimaScad = contaPrimaScad;
                    objPerito.SinistriInScad = contaInScad; 
                    objPerito.TuttiSinistriScaduti = contaPrimaScad + contaSecondaScad;
                    this.dsInfoTuttiPeriti.push(objPerito);
                }); 
        });     
        CustomJS.getRemoteData2(CustomJS.APIUrlProd,
            `token=${CustomJS.tokenProd}&azione=scaricoCompagnie&raggruppamento=rerc`, 
            'POST', "application/x-www-form-urlencoded", (retData) => { 
                this.dsInfoTuttiPeriti = this.dsInfoTuttiPeriti.filter(elm3 => { return (elm3.TuttiSinistriScaduti + elm3.SinistriInScad) > 0 });                    
                document.getElementById(`ddlPeriti${IdComp}`).ej2_instances[0].dataSource.forEach(elm2 => { 
                    if (CustomJS.isNullEmpty(elm2.Value)) return;
                    let globalChartData = [];         
                    retData.forEach((elm, idx) => {                 
                        let peritoSel = _.find(globalChartData, (obj) => { return obj.IdPerito === elm2.Value; });
                        if (CustomJS.isNullEmpty(peritoSel)) {                            
                            if (IdComp.toString() === elm.IdCompagnia && elm2.Value === elm.IdPerito.toString() ) { 
                                let objPerito = {};                            
                                let percChiusi = ((elm.NumSinistriChiusi / elm.NumSinistriEntrati) * 100) < 100 && ((elm.NumSinistriChiusi / elm.NumSinistriEntrati) * 100) > 0 
                                                && (((elm.NumSinistriChiusi / elm.NumSinistriEntrati) * 100) - Math.floor(((elm.NumSinistriChiusi / elm.NumSinistriEntrati) * 100))) !== 0
                                                ? ((elm.NumSinistriChiusi / elm.NumSinistriEntrati) * 100).toFixed(2)
                                                : ((elm.NumSinistriChiusi / elm.NumSinistriEntrati) * 100).toFixed(0);
                                objPerito[elm.IdCompagnia] = `${parseFloat(percChiusi).toLocaleString()}% (${elm.NumSinistriChiusi.toString()}/${elm.NumSinistriEntrati.toString()})`;
                                objPerito.TotSinistriChiusi = elm.NumSinistriChiusi;
                                objPerito.TotSinistriEntrati = elm.NumSinistriEntrati;
                                if (elm.Raggruppamento === 'RE') {
                                    objPerito.TotSinistriChiusiRE = elm.NumSinistriChiusi;
                                    objPerito.TotSinistriEntratiRE = elm.NumSinistriEntrati;
                                    objPerito.TotSinistriChiusiRC = 0;
                                    objPerito.TotSinistriEntratiRC = 0;
                                    objPerito.GiorniMediRientroRE = elm.GiorniMediRientro;
                                    objPerito.PagatoMedioRE = elm.PagatoMedio;
                                    objPerito.GiorniMediRientroRC = 0;
                                    objPerito.PagatoMedioRC = 0;
                                }
                                else if (elm.Raggruppamento === 'RC') {
                                    objPerito.TotSinistriChiusiRC = elm.NumSinistriChiusi;
                                    objPerito.TotSinistriEntratiRC = elm.NumSinistriEntrati;
                                    objPerito.TotSinistriChiusiRE = 0;
                                    objPerito.TotSinistriEntratiRE = 0;
                                    objPerito.GiorniMediRientroRC = elm.GiorniMediRientro;
                                    objPerito.PagatoMedioRC = elm.PagatoMedio;
                                    objPerito.GiorniMediRientroRE = 0;
                                    objPerito.PagatoMedioRE = 0;
                                }             
                                objPerito.Raggruppamento = elm.Raggruppamento;
                                objPerito.GiorniMediRientro = elm.GiorniMediRientro;
                                objPerito.PagatoMedio = elm.PagatoMedio;
                                globalChartData.push(objPerito);
                            }
                            else {
                                if (IdComp.toString() === elm.IdCompagnia && elm2.Value === elm.IdPerito.toString() ) {   
                                    let percChiusi = ((elm.NumSinistriChiusi / elm.NumSinistriEntrati) * 100) < 100 && ((elm.NumSinistriChiusi / elm.NumSinistriEntrati) * 100) > 0 
                                                    && (((elm.NumSinistriChiusi / elm.NumSinistriEntrati) * 100) - Math.floor(((elm.NumSinistriChiusi / elm.NumSinistriEntrati) * 100))) !== 0
                                                    ? ((elm.NumSinistriChiusi / elm.NumSinistriEntrati) * 100).toFixed(2)
                                                    : ((elm.NumSinistriChiusi / elm.NumSinistriEntrati) * 100).toFixed(0);                                                      
                                    peritoSel[elm.IdCompagnia] = `${parseFloat(percChiusi).toLocaleString()}% (${elm.NumSinistriChiusi.toString()}/${elm.NumSinistriEntrati.toString()})`;
                                    peritoSel.GiorniMediRientro = (peritoSel.GiorniMediRientro * peritoSel.TotSinistriChiusi) + (elm.GiorniMediRientro * elm.NumSinistriChiusi) / (peritoSel.TotSinistriChiusi + elm.NumSinistriChiusi);
                                    peritoSel.PagatoMedio = (peritoSel.PagatoMedio * peritoSel.TotSinistriChiusi) + (elm.PagatoMedio * elm.NumSinistriChiusi) / (peritoSel.TotSinistriChiusi + elm.NumSinistriChiusi);
                                    peritoSel.TotSinistriChiusi += elm.NumSinistriChiusi;
                                    peritoSel.TotSinistriEntrati += elm.NumSinistriEntrati;
                                    if (elm.Raggruppamento === 'RE') {
                                        if (elm.NumSinistriChiusi > 0) {
                                            peritoSel.GiorniMediRientroRE = (peritoSel.GiorniMediRientroRE * peritoSel.TotSinistriChiusiRE) + (elm.GiorniMediRientro * elm.NumSinistriChiusi) / (peritoSel.TotSinistriChiusiRE + elm.NumSinistriChiusi);
                                            peritoSel.PagatoMedioRE = (peritoSel.PagatoMedioRE * peritoSel.TotSinistriChiusiRE) + (elm.PagatoMedio * elm.NumSinistriChiusi) / (peritoSel.TotSinistriChiusiRE + elm.NumSinistriChiusi);
                                        }
                                        peritoSel.TotSinistriChiusiRE += elm.NumSinistriChiusi;
                                        peritoSel.TotSinistriEntratiRE += elm.NumSinistriEntrati;
                                    }
                                    else if (elm.Raggruppamento === 'RC') {
                                        if (elm.NumSinistriChiusi > 0) {
                                            peritoSel.GiorniMediRientroRC = (peritoSel.GiorniMediRientroRC * peritoSel.TotSinistriChiusiRC) + (elm.GiorniMediRientro * elm.NumSinistriChiusi) / (peritoSel.TotSinistriChiusiRC + elm.NumSinistriChiusi);
                                            peritoSel.PagatoMedioRC = (peritoSel.PagatoMedioRC * peritoSel.TotSinistriChiusiRC) + (elm.PagatoMedio * elm.NumSinistriChiusi) / (peritoSel.TotSinistriChiusiRC + elm.NumSinistriChiusi);
                                        }
                                        peritoSel.TotSinistriChiusiRC += elm.NumSinistriChiusi;
                                        peritoSel.TotSinistriEntratiRC += elm.NumSinistriEntrati;
                                    }   
                                }
                            }
                        }                        
                    });                                            
                    let objPerito = _.findWhere(this.dsInfoTuttiPeriti, { IdPerito: elm2.Value });
                    if (_.isObject(objPerito)) {
                        objPerito.PercScaricoRE = _.isObject(globalChartData[0]) ? globalChartData[0][IdComp].toLocaleString('it-IT') : '0% (0/0)';
                        objPerito.GiorniMediRientroRE = _.isObject(globalChartData[0]) ? globalChartData[0].GiorniMediRientroRE.toLocaleString('it-IT') : '0';
                        objPerito.PagatoMedioRE = _.isObject(globalChartData[0]) ? globalChartData[0].PagatoMedioRE.toLocaleString('it-IT') : '0';
                        objPerito.PercScaricoRC = _.isObject(globalChartData[1]) ? globalChartData[1][IdComp].toLocaleString('it-IT') : '0% (0/0)';
                        objPerito.GiorniMediRientroRC = _.isObject(globalChartData[1]) ? globalChartData[1].GiorniMediRientroRC.toLocaleString('it-IT') : '0';
                        objPerito.PagatoMedioRC = _.isObject(globalChartData[1]) ? globalChartData[1].PagatoMedioRC.toLocaleString('it-IT') : '0';
                        let idxPos = _.findIndex(this.dsInfoTuttiPeriti, elm4 => { return elm4.IdPerito === elm2.Value; })
                        this.dsInfoTuttiPeriti[idxPos] = objPerito;
                    }
                });
                this.toggleBlocking();
                this.gridInstance.dataSource = this.dsInfoTuttiPeriti;
                //document.getElementById('grigliaSituazionePeriti').ej2_instances[0].dataSource = this.dsInfoTuttiPeriti;   
            }, true, false);
    }
    caricaCronologie(refresh) {
        let IdComp = JSON.parse(localStorage.getItem("CompagnieSelezionate"))[document.getElementById('TabRosibot').ej2_instances[0].selectedItem].IdCompagnia;
        let tipoOper = localStorage.getItem('tipoOperativita');
        if (refresh) {
            setTimeout(() => {
               document.getElementById(`btnVisualDati${IdComp}`).ej2_instances[0].click();
            }, 500);
            return;
        }       
        CustomJS.callAPI(`${APIRosibotURL}/api/Rosibot/leggiTutteCronologie/${IdComp}/${tipoOper}`,'', 'GET',
            '', retData => {   
                this.tutteCronologie = retData;                               
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? messaggioAPIBackendNonTrovata : messaggioNoBackend;
                toast.warn(messaggio, {
                    onClose: () => toast.dismiss(),
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,            
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true})
        }, true, false)
    }
    cambioOperativita(args) {
        let IdComp = JSON.parse(localStorage.getItem("CompagnieSelezionate"))[document.getElementById('TabRosibot').ej2_instances[0].selectedItem].IdCompagnia;
        localStorage.setItem('tipoOperativita', args.value === 'scadenza' ? 'S' : args.value === 'interloc' ? 'I' : args.value === 'surveyor' ? 'SURV' : ""); 
        if (args.value === 'scadenza') {
            document.getElementById(`rigaScadenzeTipoS${IdComp}`).style.removeProperty('display');
            document.getElementById(`rigaScadenzeTipoI${IdComp}`).style.display = 'none';
            document.getElementById(`rigaScadenzeTipoSurv${IdComp}`).style.display = 'none';
            document.getElementById(`divBtnSituazPeriti${IdComp}`).style.removeProperty('display');
            document.getElementById(`divOrdGravInterloc${IdComp}`).style.display = 'none';
            document.getElementById(`chkOrdGravInterloc${IdComp}`).ej2_instances[0].checked = false;
        }
        else if (args.value === 'interloc') {
            document.getElementById(`rigaScadenzeTipoS${IdComp}`).style.display = 'none';
            document.getElementById(`rigaScadenzeTipoI${IdComp}`).style.removeProperty('display');
            document.getElementById(`rigaScadenzeTipoSurv${IdComp}`).style.display = 'none';
            document.getElementById(`divBtnSituazPeriti${IdComp}`).style.display = 'none';
            document.getElementById(`divOrdGravInterloc${IdComp}`).style.removeProperty('display');
            document.getElementById(`chkOrdGravInterloc${IdComp}`).ej2_instances[0].checked = false;
        }
        else if (args.value === 'surveyor') {
            document.getElementById(`rigaScadenzeTipoS${IdComp}`).style.display = 'none';
            document.getElementById(`rigaScadenzeTipoI${IdComp}`).style.display = 'none';
            document.getElementById(`rigaScadenzeTipoSurv${IdComp}`).style.removeProperty('display');
            document.getElementById(`divBtnSituazPeriti${IdComp}`).style.display = 'none';
            document.getElementById(`divOrdGravInterloc${IdComp}`).style.display = 'none';
            document.getElementById(`chkOrdGravInterloc${IdComp}`).ej2_instances[0].checked = false;
        }
        setTimeout(() => { this.tabSelected({ selectedIndex: document.getElementById('TabRosibot').ej2_instances[0].selectedItem }) }, 50);
        setTimeout(() => { 
            document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].dataSource = []; 
            document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].refresh();
            document.getElementById(`ddlPeriti${IdComp}`).ej2_instances[0].dataSource = [];
            document.getElementById(`ddlPeriti${IdComp}`).ej2_instances[0].value = null;
            document.getElementById(`ddlPeriti${IdComp}`).ej2_instances[0].text = null;
            document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].toolbarModule.enableItems(['invioavvisoperito'], false);
            document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].clearFiltering();
            if (IdComp === 999 && document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].dataSource.length === 0)
                document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].filterSettings = { type: 'CheckBox', columns: [ { field: 'GradoSinistro', matchCase: false,
                                                                                                                                        operator: 'equal', predicate: 'and', value: 'C'
                                                                                                                                     } ]};
            else
                document.getElementById(`grigliaSinistri${IdComp}`).ej2_instances[0].filterSettings = { type: 'CheckBox' }
        }, 200);
    }
    dialogContent0() {        
        return (<div className='container-fluid'>
                <div className='row'>
                    <RichTextEditorComponent id="emailRTE" ref={RTEObj => { this.RTEInstance = RTEObj; }} height='500px' enableHtmlEncode={false} readOnly={false}
                                             toolbarSettings={this.toolbarSettingsRTE}>              
                    <Inject services={[HtmlEditor, ToolbarRTE, Image, Table, Link, QuickToolbar]}/>
                    </RichTextEditorComponent>
                </div>
                <div className='row' style={{paddingTop: '15px'}}>
                    <CheckBoxComponent label='Invia email di prova a mittente e, se presente, a persona in CC (il perito non riceverà nulla)' ref={chkbox => this.chkboxInstance2 = chkbox}></CheckBoxComponent>
                </div>            
        </div>);
    }
    dialogContent1() {        
        return (<RichTextEditorComponent id="cronologiaRTE" ref={RTEObj => { this.RTEInstance2 = RTEObj; }} height='500px' enableHtmlEncode={false} readOnly={false}
                                         toolbarSettings={this.toolbarSettingsRTE}>            
                    <Inject services={[HtmlEditor, ToolbarRTE, Image, Table, Link, QuickToolbar]}/>
            </RichTextEditorComponent>);
    }
    dialogContent2() {        
        return (<div className="control-section card-control-section basic_card_layout">
                    <div className="e-card-resize-container">
                        <div className="card-layout">
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                <div tabIndex="0" className="e-card" id="main_card">
                                    <div className="e-card-header">
                                        <div className="e-card-header-caption">
                                            <div id='nomePeritoInfo' className="e-card-header-title" style={{ fontSize: '2.3em', fontWeight: 'bold', color:'#133B95'}}></div>
                                            <div id='compagniaInfo' className="e-card-header-title" style={{ fontSize: '1.2em', fontWeight: 'bold', color:'#133B95'}}></div>
                                        </div>
                                    </div>
                                    <div className="e-card-content">
                                        <div className='row'>
                                            <div className="col-xs-4 col-sm-4 col-lg-4 col-md-5 text-center">
                                                <div className="e-card-resize-container">                                                    
                                                    <div className="e-card" id="basic_card">
                                                        <div className="e-card-header">
                                                            <div className="e-card-header-caption">
                                                                <div className="e-card-title" style={{fontSize: '1.6em', color:'#133B95'}}>Sinistri in scadenza</div>                                                                                
                                                            </div>
                                                        </div>
                                                        <div id='sinInScadInfo' className="e-card-content text-center" style={{fontSize: '1.8em', fontWeight: 'bold', color:'#133B95'}}> 
                                                        </div>                                                                        
                                                    </div>
                                                </div>
                                            </div>  
                                            <div className="col-xs-4 col-sm-3 col-lg-4 col-md-4 text-center">
                                                <div className="e-card-resize-container">                                                    
                                                    <div className="e-card" id="basic_card">
                                                        <div className="e-card-header">
                                                            <div className="e-card-header-caption">
                                                                <div className="e-card-title" style={{fontSize: '1.6em', color:'#133B95'}}>Sinistri dopo prima scadenza</div>                                                                                
                                                            </div>
                                                        </div>
                                                        <div id='sinPrimaScadInfo' className="e-card-content text-center" style={{fontSize: '1.8em', fontWeight: 'bold', color:'#133B95'}}>                                                        
                                                        </div>                                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4 text-center">
                                                <div className="e-card-resize-container">                                                    
                                                    <div className="e-card" id="basic_card">
                                                        <div className="e-card-header">
                                                            <div className="e-card-header-caption">
                                                                <div className="e-card-title" style={{fontSize: '1.6em', color:'#133B95'}}>Sinistri dopo seconda scadenza</div>                                                                                
                                                            </div>
                                                        </div>
                                                        <div id='sinSecondaScadInfo' className="e-card-content text-center" style={{fontSize: '1.8em', fontWeight: 'bold', color:'#133B95'}}>                                                       
                                                        </div>                                                                        
                                                    </div>
                                                </div>
                                            </div>                                                        
                                        </div> 
                                        <br/>
                                        <div className='row'>
                                            <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6 text-center">
                                                <div className="e-card-resize-container">                                                    
                                                    <div className="e-card" id="basic_card">
                                                        <div className="e-card-header">
                                                            <div className="e-card-header-caption">
                                                                <div className="e-card-title" style={{fontSize: '1.6em', color:'#133B95'}}>Percentuale scarico sinistri RE</div>                                                                                
                                                            </div>
                                                        </div>
                                                        <div id='chiusuraSinREInfo' className="e-card-content text-center" style={{fontSize: '1.8em', fontWeight: 'bold', color:'#133B95'}}> 
                                                        </div>                                                                        
                                                    </div>
                                                </div>
                                            </div>  
                                            <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6 text-center">
                                                <div className="e-card-resize-container">                                                    
                                                    <div className="e-card" id="basic_card">
                                                        <div className="e-card-header">
                                                            <div className="e-card-header-caption">
                                                                <div className="e-card-title" style={{fontSize: '1.6em', color:'#133B95'}}>Percentuale scarico sinistri RC</div>                                                                                
                                                            </div>
                                                        </div>
                                                        <div id='chiusuraSinRCInfo' className="e-card-content text-center" style={{fontSize: '1.8em', fontWeight: 'bold', color:'#133B95'}}>                                                        
                                                        </div>                                                                        
                                                    </div>
                                                </div>
                                            </div>                                                                                    
                                        </div> 
                                        <br/>
                                        <div className='row'>
                                            <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6 text-center">
                                                <div className="e-card-resize-container">                                                    
                                                    <div className="e-card" id="basic_card">
                                                        <div className="e-card-header">
                                                            <div className="e-card-header-caption">
                                                                <div className="e-card-title" style={{fontSize: '1.6em', color:'#133B95'}}>Giorni medi rientro sinistri RE</div>                                                                                
                                                            </div>
                                                        </div>
                                                        <div id='giorniMediRientroSinREInfo' className="e-card-content text-center" style={{fontSize: '1.8em', fontWeight: 'bold', color:'#133B95'}}> 
                                                        </div>                                                                        
                                                    </div>
                                                </div>
                                            </div>  
                                            <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6 text-center">
                                                <div className="e-card-resize-container">                                                    
                                                    <div className="e-card" id="basic_card">
                                                        <div className="e-card-header">
                                                            <div className="e-card-header-caption">
                                                                <div className="e-card-title" style={{fontSize: '1.6em', color:'#133B95'}}>Pagato medio sinistri RE</div>                                                                                
                                                            </div>
                                                        </div>
                                                        <div id='pagatoMedioSinREInfo' className="e-card-content text-center" style={{fontSize: '1.8em', fontWeight: 'bold', color:'#133B95'}}>                                                        
                                                        </div>                                                                        
                                                    </div>
                                                </div>
                                            </div>                                                                                    
                                        </div> 
                                        <br/>
                                        <div className='row'>
                                            <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6 text-center">
                                                <div className="e-card-resize-container">                                                    
                                                    <div className="e-card" id="basic_card">
                                                        <div className="e-card-header">
                                                            <div className="e-card-header-caption">
                                                                <div className="e-card-title" style={{fontSize: '1.6em', color:'#133B95'}}>Giorni medi rientro sinistri RC</div>                                                                                
                                                            </div>
                                                        </div>
                                                        <div id='giorniMediRientroSinRCInfo' className="e-card-content text-center" style={{fontSize: '1.8em', fontWeight: 'bold', color:'#133B95'}}> 
                                                        </div>                                                                        
                                                    </div>
                                                </div>
                                            </div>  
                                            <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6 text-center">
                                                <div className="e-card-resize-container">                                                    
                                                    <div className="e-card" id="basic_card">
                                                        <div className="e-card-header">
                                                            <div className="e-card-header-caption">
                                                                <div className="e-card-title" style={{fontSize: '1.6em', color:'#133B95'}}>Pagato medio sinistri RC</div>                                                                                
                                                            </div>
                                                        </div>
                                                        <div id='pagatoMedioSinRCInfo' className="e-card-content text-center" style={{fontSize: '1.8em', fontWeight: 'bold', color:'#133B95'}}>                                                        
                                                        </div>                                                                        
                                                    </div>
                                                </div>
                                            </div>                                                                                    
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>);
    }
    dialogContent3() {
       return(<div className="control-section card-control-section basic_card_layout">
                    <div className="e-card-resize-container">
                        <div className="card-layout">
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                <div tabIndex="0" className="e-card" id="main_card">
                                    <div className="e-card-header">
                                        <div className="e-card-header-caption">                                            
                                            <div id='compagniaSituazPeriti' className="e-card-header-title" style={{ fontSize: '2.3em', fontWeight: 'bold', color:'#133B95'}}></div>
                                        </div>
                                    </div>
                                    <div className="e-card-content">
                                        <GridComponent id='grigliaSituazionePeriti' toolbar={this.toolbarOptions2} allowSelection={false} enableHover={false} allowTextWrap={true} filterSettings={this.filterSettings} enableVirtualization={false} 
                                            allowPaging={true} allowSorting={true} allowFiltering={false} allowExcelExport={true} allowPdfExport={true} pageSettings={{ pageCount: 5, pageSize: 7, pageSizes: ['7', '20', '50', '100'] }} 
                                            sortSettings={{ columns: [{field: 'TuttiSinistriScaduti', direction: 'Descending'}]}} enableAltRow={false} gridLines='Both' 
                                            dataBound={this.onDatabound2.bind(this)} toolbarClick={this.toolbarClick2.bind(this)} ref={gsp => this.gridInstance = gsp}>
                                            <ColumnsDirective>                            
                                                <ColumnDirective field='NominativoPerito' headerText="Perito" width='15%' headerTemplate={this.headerTemplate2} template={this.fieldTemplate} textAlign='Center'></ColumnDirective>
                                                <ColumnDirective field='SinistriSecondaScad' headerText="Sinistri dopo seconda scadenza" format='N0' headerTemplate={this.headerTemplate2} template={this.fieldTemplate} textAlign='Center'></ColumnDirective>
                                                <ColumnDirective field='SinistriPrimaScad' headerText="Sinistri dopo prima scadenza" format='N0' headerTemplate={this.headerTemplate2} template={this.fieldTemplate} textAlign='Center'></ColumnDirective>
                                                <ColumnDirective field='SinistriInScad' headerText="Sinistri in scadenza" format='N0' headerTemplate={this.headerTemplate2} template={this.fieldTemplate} textAlign='Center'></ColumnDirective>
                                                <ColumnDirective field='TuttiSinistriScaduti' visible={false} format='N0'></ColumnDirective>
                                                <ColumnDirective field='PercScaricoRE' headerText="Percent. scarico sinistri RE" headerTemplate={this.headerTemplate2} template={this.fieldTemplate} textAlign='Center'></ColumnDirective>
                                                <ColumnDirective field='PercScaricoRC' headerText="Percent. scarico sinistri RC" headerTemplate={this.headerTemplate2} template={this.fieldTemplate} textAlign='Center'></ColumnDirective>
                                                <ColumnDirective field='GiorniMediRientroRE' headerText="Giorni medi rientro sinistri RE" format='N2' headerTemplate={this.headerTemplate2} template={this.fieldTemplate} textAlign='Center'></ColumnDirective>
                                                <ColumnDirective field='PagatoMedioRE' headerText="Pagato medio sinistri RE" format='N2' headerTemplate={this.headerTemplate2} template={this.fieldTemplate} textAlign='Center'></ColumnDirective>
                                                <ColumnDirective field='GiorniMediRientroRC' headerText="Giorni medi rientro sinistri RC" format='N2' headerTemplate={this.headerTemplate2} template={this.fieldTemplate} textAlign='Center'></ColumnDirective>
                                                <ColumnDirective field='PagatoMedioRC' headerText="Pagato medio sinistri RC" format='N2' headerTemplate={this.headerTemplate2} template={this.fieldTemplate} textAlign='Center'></ColumnDirective>
                                            </ColumnsDirective>  
                                            <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, Sort, Page]}/>                    
                                        </GridComponent>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
        </div>);
    }
    
    render() {
        return (<React.Fragment>
                    <div className='control-section card-control-section basic_card_layout'>  
                        <BlockUi id='blockUIRosibot' blocking={this.state.blocking} keepInView={true} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>
                            <div className="e-card-resize-container">  
                                <div className="card-layout" >  
                                    <DialogComponent id="sceltaEmailPeritiDialog" showCloseIcon={true} target='body' animationSettings={this.animationSettings} visible={this.state.showDialog5} width='30%'
                                            ref={dialog => this.dialogInstance5 = dialog} header='Attenzione!!' buttons={this.buttons5} height='auto' minHeight='250px' isModal={true} 
                                            open={this.dialogOpen5.bind(this)} close={this.dialogClose5.bind(this)} content=''></DialogComponent>
                                    <DialogComponent id="situazionePeritiDialog" showCloseIcon={true} target='body' animationSettings={this.animationSettings} visible={this.state.showDialog4} width='80%'
                                            ref={dialog => this.dialogInstance4 = dialog} header='' buttons={this.buttons4} height='auto' minHeight='795px' isModal={true} 
                                            open={this.dialogOpen4.bind(this)} close={this.dialogClose4.bind(this)} content={this.dialogContent3.bind(this)}></DialogComponent>                                 
                                    <DialogComponent id="infoPeritoDialog" showCloseIcon={true} target='body' animationSettings={this.animationSettings} visible={this.state.showDialog3} width='60%'
                                            ref={dialog => this.dialogInstance3 = dialog} header='Visualizzazione info perito' buttons={this.buttons3} height='auto' minHeight='760px' isModal={true}
                                            open={this.dialogOpen3.bind(this)} close={this.dialogClose3.bind(this)} content={this.dialogContent2.bind(this)}></DialogComponent>                                 
                                    <DialogComponent id="cronologiaDialog" showCloseIcon={true} target='body' animationSettings={this.animationSettings} visible={this.state.showDialog2} width='45%'
                                            ref={dialog => this.dialogInstance2 = dialog} header='Cronologia risposte perito' buttons={this.buttons2} height='65%' minHeight='65%' isModal={true}
                                            open={this.dialogOpen2.bind(this)} close={this.dialogClose2.bind(this)} content={this.dialogContent1.bind(this)}></DialogComponent>                                 
                                    <DialogComponent id="emailDialog" showCloseIcon={true} target='body' animationSettings={this.animationSettings} visible={this.state.showDialog} width='50%'
                                            ref={dialog => this.dialogInstance = dialog} header='Invio Email Avviso Perito' buttons={this.buttons} height='68%' minHeight='68%' isModal={true}
                                            open={this.dialogOpen.bind(this)} close={this.dialogClose.bind(this)} content={this.dialogContent0.bind(this)}></DialogComponent> 
                                    <ToastContainer containerId='toastContainer2' transition={Zoom} style={{ fontSize: '1.1vw', color: 'darkred', fontWeight: 'bold', width: '30%' }}></ToastContainer>
                                    <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                        <div className="e-card" id="basic_card" >                                        
                                            <div className="e-card-header">                                        
                                                <div className="e-card-header-caption">
                                                    <div className='row' style={{ paddingTop: '10px' }}>
                                                        <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                                            <img src={LogoRB} alt='' width='150' height='auto'/>
                                                            {/* <div className="e-card-title" style={{ fontSize: '1.2em', color:'#133B95', fontWeight: 'bold' }}>ROSIBOT</div>*/}
                                                        </div>
                                                        <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10">                                                                                                                        
                                                            <AccordionComponent id='accordionParamEmail' expandMode='Single' ref={accord => this.accordInstance = accord} style={{ backgroundColor: 'lightgray'}}>
                                                                <AccordionItemsDirective>
                                                                    <AccordionItemDirective header={this.accordHeader[0]} expanded={true} content={this.accordContent1.bind(this)} iconCss="e-imageAccordion" />
                                                                </AccordionItemsDirective>
                                                            </AccordionComponent>
                                                        </div>                                                                                     
                                                    </div>                                                                                                    
                                                </div>
                                            </div>
                                            <div className="e-card-content">
                                                <div className='row'>
                                                    <div className="col-xs-7 col-sm-5 col-md-3 col-lg-2">
                                                        <DateRangePickerComponent id='RangeDateEstrazione2' readOnly={false} startDate={new Date(dataInizioAnno)} cssClass='e-outline' format='dd/MM/yyyy'
                                                                    allowEdit={false} endDate={new Date(dataCorrente)} ref={drp => { this.dateRangeInstance2 = drp } } placeholder='Data estrazione (Da - A)' floatLabelType='always'></DateRangePickerComponent>
                                                    </div>  
                                                    <div className="col-xs-7 col-sm-5 col-md-2 col-lg-1"> 
                                                        <RadioButtonComponent id='tipoOper1' checked={true} label='Operatività su scadenze Periti' name='sceltaOperativita' change={this.cambioOperativita.bind(this)} value="scadenza"></RadioButtonComponent>  
                                                    </div>   
                                                    <div className="col-xs-7 col-sm-5 col-md-2 col-lg-1"> 
                                                        <RadioButtonComponent id='tipoOper2' checked={false} label='Operatività su interlocutorie' name='sceltaOperativita' change={this.cambioOperativita.bind(this)} value="interloc"></RadioButtonComponent>  
                                                    </div>  
                                                    <div className="col-xs-7 col-sm-5 col-md-2 col-lg-1"> 
                                                        <RadioButtonComponent id='tipoOper3' checked={false} label='Operatività su scadenze Surveyor' name='sceltaOperativita' change={this.cambioOperativita.bind(this)} value="surveyor"></RadioButtonComponent>  
                                                    </div> 
                                                    <div className="col-xs-4 col-sm-4 col-lg-2 col-md-2">   
                                                        <ProgressButtonComponent id='btnSalvaOperDefault' duration='1000' content="Salva Operatività Default" enableProgress spinSettings={{ position: 'Center' }} 
                                                                animationSettings={{effect: 'SlideLeft'}} created={this.ProgrButtCreated8} cssClass="e-success"></ProgressButtonComponent> 
                                                    </div>                                                                                     
                                                </div>
                                                <div className='row'>
                                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                        <TabComponent id='TabRosibot' cssClass='e-fill' headerPlacement='Top' width='100%'  
                                                                    selected={this.tabSelected.bind(this)} ref={tabComp => this.tabInstance = tabComp} created={this.tabCreated.bind(this)}
                                                                    animation={{ previous: { effect: 'none', duration: 500, easing: 'ease' }, next: { effect: 'none', duration: 500, easing: 'ease' } }}>                                                            
                                                        </TabComponent>
                                                    </div>
                                                </div>                                      
                                            </div> 
                                        </div>
                                    </div>
                                </div>     
                            </div>     
                        </BlockUi>
                    </div>          
                </React.Fragment>);
    }  
    componentDidMount() {   
        CustomJS.callAPI(`${APIRosibotURL}/api/Rosibot/leggiParametriEmailRef/${window.localStorage.getItem("IdReferente")}`, '', 'GET',
            '', retData => {   
                    if (retData.length > 0) {                               
                        setTimeout(() => { 
                            this.txtbox1Instance.value = retData[0].smtp_server 
                            this.txtbox2Instance.value = retData[0].smtp_porta
                            this.txtbox3Instance.value = retData[0].smtp_utente
                            this.txtbox4Instance.value = retData[0].smtp_password
                            this.chkboxInstance.checked = retData[0].smtp_SSL === 'S';
                            this.txtbox5Instance.value = retData[0].nome_mittente
                            this.txtbox6Instance.value = retData[0].email_mittente
                            this.txtbox7Instance.value = retData[0].email_cc
                        }, 4000);
                        setTimeout(() => { this.accordInstance.expandItem(false, 0); }, 1000);    
                    }           
        }, error => {
            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? messaggioAPIBackendNonTrovata : messaggioNoBackend;
            toast.warn(messaggio, {
                onClose: () => toast.dismiss(),
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true})
        }, true, false);

        CustomJS.callAPI(`${APIRosibotURL}/api/Rosibot/leggiTipoOperativitaDefault/${window.localStorage.getItem("IdReferente")}`, '', 'GET',
            '', retData => {  
                    if (retData.length > 0) {                                                      
                        setTimeout(() => {
                            localStorage.setItem('tipoOperativita', retData[0].tipo_operativita_default);
                            localStorage.setItem('dataDaDefault', retData[0].data_da_default);
                            localStorage.setItem('dataADefault', retData[0].data_a_default);
                            document.getElementById('tipoOper1').checked = retData[0].tipo_operativita_default === 'S' || CustomJS.isNullEmpty(retData[0].tipo_operativita_default);
                            document.getElementById('tipoOper2').checked = retData[0].tipo_operativita_default === 'I';
                            document.getElementById('tipoOper3').checked = retData[0].tipo_operativita_default === 'SURV';
                            if (!CustomJS.isNullEmpty(retData[0].data_da_default))
                                document.getElementById('RangeDateEstrazione2').ej2_instances[0].startDate =  new Date(retData[0].data_da_default);
                            if (!CustomJS.isNullEmpty(retData[0].data_a_default))
                                document.getElementById('RangeDateEstrazione2').ej2_instances[0].endDate = new Date(retData[0].data_a_default);
                        }, 1700);   
                    }   
                    else { 
                       localStorage.setItem('tipoOperativita', 'S');    
                       localStorage.setItem('dataDaDefault', dataInizioAnno);
                       localStorage.setItem('dataADefault', dataCorrente);
                    } 
        }, error => {
            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? messaggioAPIBackendNonTrovata : messaggioNoBackend;
            toast.warn(messaggio, {
                onClose: () => toast.dismiss(),
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true})
        }, true, false);
        setTimeout(() => { this.tabSelected({ selectedIndex: 0 }) }, 1500);
    }
    scrollAuto(speed) {
        $('html, body').animate({ scrollTop: $(document).height() - $(window).height() - 45 }, speed, function() {
            //$(this).animate({ scrollTop: 0 }, speed);
        });
    } 
}
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ErrorBoundary from './errorBoundary';
import 'bootstrap/dist/css/bootstrap.css';
import 'underscore/underscore-min';
import './Custom.css';
import './BlockUI.css';
import './assets/fontawesome/css/all.min.css';
import { registerLicense } from '@syncfusion/ej2-base';

registerLicense('Ngo9BigBOggjHTQxAR8/V1NGaF1cXGFCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWXZfeHVdRWBcVkJwW0o=');

const rootElement = document.getElementById('rootElement');
const root = createRoot(rootElement); 
root.render(
         <ErrorBoundary>
            <App />
         </ErrorBoundary>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
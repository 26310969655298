import { Ajax, Browser } from '@syncfusion/ej2-base';
import $, { param } from 'jquery';
import * as Loader from "react-loader-spinner";

let axios = require('axios');
let qs = require('qs');

export const APIUrlProd = 'https://sipa.allconsulting.org/new_sipa/api/allc.php';
export const tokenProd = 'zyAyefCyQZnwXVyPbv3TcI3hK3G5RvHve5oF3NcLIEAPdidd';
export const APIUrlSvil = 'https://sipa-svi.gbsweb.online/new_sipa/api/allc.php';
export const tokenSvil = 'hd4AeXSYt1HqTtDsF6dLZjbYEEM9reWk7liHrpp0upw';
export const tokenAPIBingMaps = 'AkF63625LND-HpLl6X3fvShkrye3Qqyyo61qwE4NtG39bebps3kvOCrHXeYtX1xt';
export const tokenAPIMapboxPrivate = 'pk.eyJ1IjoiZGlnaXRhbHByYWVzaWRpdW0iLCJhIjoiY2tndHRkdm40MG9qYTMwcDlldWtzZXZsdSJ9.CbwTlYBsUrxa5vYCTqX9_A';
export const tokenAPIMapboxPublic = 'pk.eyJ1IjoiZGlnaXRhbHByYWVzaWRpdW0iLCJhIjoiY2tndHQ3c2E0MDRjNjJxcnk4ZmV1YTlhciJ9.B3lqOAgkDn2-BitBCXRGhg';
export const APIUrlBingMaps = 'https://dev.virtualearth.net/REST/v1/Locations/{0}?o=json&key=AkF63625LND-HpLl6X3fvShkrye3Qqyyo61qwE4NtG39bebps3kvOCrHXeYtX1xt';
export const APIRosibotURLSvil = 'http://localhost:3005';
export const APIRosibotURLTest = 'https://apicallertest.digitalpraesidium.it';
export const APIRosibotURLProd = 'https://apicaller.digitalpraesidium.it';
export const compagnieVisualDefault = [];
export const compagnieGestioneRamo = ['116'];
export const widthScreenResolution = window.screen.width * window.devicePixelRatio;
export const heightScreenResolution = window.screen.height * window.devicePixelRatio;
export const chartWidth = Browser.isDevice ? '92%' : '92%'; //widthScreenResolution > 1900 ?  Browser.userAgent.indexOf('Firefox') > -1 ? '85%' :  '80%' : Browser.userAgent.indexOf('Firefox') > -1 ? '105%' :  '110%'; 
export const chartWidth2 = Browser.isDevice ? '92%' : '92%'; //widthScreenResolution > 1900 ?  Browser.userAgent.indexOf('Firefox') > -1 ? '115%' :  '120%' : Browser.userAgent.indexOf('Firefox') > -1 ? '95%' :  '100%';
export const chartWidth3 = Browser.isDevice ? '92%' : '92%'; //widthScreenResolution > 1900 ?  Browser.userAgent.indexOf('Firefox') > -1 ? '129%' :  '136%' : Browser.userAgent.indexOf('Firefox') > -1 ? '109%' :  '116%';
export const chartWidth4 = Browser.isDevice ? '97%' : '97%';
let chartHeightTemp = Browser.userAgent.indexOf('Firefox') > -1 ? '570' : Browser.userAgent.indexOf('Edg') > -1 ? '570' : Browser.userAgent.indexOf('OPR') > -1 ? '575' : '540';
let chartHeightTemp2 = Browser.userAgent.indexOf('Firefox') > -1 ? '740' : Browser.userAgent.indexOf('Edg') > -1 ? '745' : Browser.userAgent.indexOf('OPR') > -1 ? '745' : '712';
export const chartHeight = (parseInt(chartHeightTemp) - ((1080 - heightScreenResolution) === 0 ? 0 : (1080 - heightScreenResolution + 50))).toString(); 
export const chartHeight2 = (parseInt(chartHeightTemp2) - ((1080 - heightScreenResolution) === 0 ? 0 : (1080 - heightScreenResolution + 50))).toString();
export const statisticheDefault = [{ Text: 'Statistica di scarico suddivisa per area', Value: 1 }, { Text: 'Statistica di scarico suddivisa per Regione', Value: 2 }, 
                                   { Text: 'Statistica di scarico suddivisa per Provincia', Value: 4 }, { Text: 'Statistica di scarico suddivisa per perito (RE/RC)', Value: 3 },
                                   { Text: 'Statistica sinistri chiusi anno corrente/anno precedente', Value: 5 }, { Text: 'Statistica sinistri entrati/chiusi anno corrente', Value: 6 },
                                   { Text: 'Statistica sinistri pervenuti anno corrente/anno precedente', Value: 7 }, { Text: 'Rappresentazione grafica territoriale sinistri', Value: 8 }];
export const colorsData = [
  { Color: 'AliceBlue', Code: '#F0F8FF' },
  { Color: 'Aqua', Code: '#00FFFF' },
  { Color: 'Aquamarine', Code: '#7FFFD4' },
  { Color: 'Beige', Code: '#F5F5DC' },
  { Color: 'BurlyWood', Code: '#DEB887' },
  { Color: 'Chartreuse', Code: '#7FFF00' },
  { Color: 'Coral', Code: '#FF7F50' },
  { Color: 'Cornsilk', Code: '#FFF8DC' },
  { Color: 'LightSalmon', Code: '#FFA07A' },
  { Color: 'DarkTurquoise', Code: '#00CED1' },
  { Color: 'DodgerBlue', Code: '#1E90FF' },
  { Color: 'Gainsboro', Code: '#DCDCDC' },
  { Color: 'Gold', Code: '#FFD700' },
  { Color: 'GreenYellow', Code: '#ADFF2F' },
  { Color: 'Khaki', Code: '#F0E68C' },
  { Color: 'Lavender', Code: '#E6E6FA' },
  { Color: 'LawnGreen', Code: '#7CFC00' },
  { Color: 'LightBlue', Code: '#ADD8E6' },
  { Color: 'MediumSpringGreen', Code: '#00FA9A' },
  { Color: 'MistyRose', Code: '#FFE4E1' },
  { Color: 'Plum', Code: '#DDA0DD' },
  { Color: 'SandyBrown', Code: '#F4A460' },
  { Color: 'Thistle', Code: '#D8BFD8' },
  { Color: 'Turquoise', Code: '#40E0D0' },
  { Color: 'Yellow', Code: '#FFFF00' },
  { Color: 'Tomato', Code: '#FF745C' },
  { Color: 'Violet', Code: '#EE82EE' },
];
export const loaderSpinners = [<Loader.Audio width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.BallTriangle width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
                               <Loader.Bars width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.Blocks width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
                               <Loader.Circles width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.CirclesWithBar width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
                               <Loader.ColorRing width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.Comment width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, 
                               <Loader.Discuss width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.Dna width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, 
                               <Loader.FallingLines width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.FidgetSpinner width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
                               <Loader.Grid width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.InfinitySpin width={250} height={250} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
                               <Loader.LineWave width={250} height={250} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.MagnifyingGlass width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, 
                               <Loader.MutatingDots width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.Oval width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, 
                               <Loader.ProgressBar width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.Puff width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
                               <Loader.Radio width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.RevolvingDot width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
                               <Loader.Rings width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.RotatingLines width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
                               <Loader.RotatingSquare width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.RotatingTriangles width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
                               <Loader.TailSpin width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.ThreeCircles width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, 
                               <Loader.ThreeDots width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.Triangle width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
                               <Loader.Vortex width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} colors={['red', 'green', 'blue', 'yellow', 'orange', 'purple']}/>, <Loader.Watch width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>];

export async function getRemoteData(url, data, type, header) { //Chiamata con oggetti Syncfusion - Non funziona...dà errore a livello di CORS
  let ajax = new Ajax({
      url: url,
      type: type,       
      mode: false,
      data: data,
      setRequestHeader: header,      
      onFailure: (e) => { return false; }
  });

  const response = await ajax.send();
  let retData = JSON.parse(response);  
  return { response: retData['response'], retData: retData['data'] };
}

export async function getRemoteData2(url, paramData, type, header, cbFunction, async, test) {  //Chiamata Javascript puro XHR - Funziona bene
  let xhr = new XMLHttpRequest();
  xhr.withCredentials = false;
  
  xhr.addEventListener("readystatechange", function() {
    if (this.readyState === 4) {
      if (test) {
        cbFunction(this.responseText);
      }
      else {
        let retData = JSON.parse(this.responseText);
        console.log(this.responseText);
        if (retData['response'].cod === 0)
            cbFunction(retData['data']);
        else 
           console.log(retData['response'].message); 
      }           
    }
  });
  
  xhr.open(type, url);
  xhr.setRequestHeader("Content-Type", header);
  xhr.async = async; 
  
  if (!isNullEmpty(paramData))
      xhr.send(paramData);  
  else
      xhr.send();
}

export async function getRemoteData3(url, paramData, type, header, cbFunction, async, test) {  //Chiamata Jquery - Funziona bene
  let settings = {
    "url": url,
    "method": type,
    "timeout": 0,
    "headers": header,
    "data": paramData,
    "async": async
  };

  $.ajax(settings).done((response) => {
    if (test) {
      cbFunction(response);
    }
    else {
      let retData = JSON.parse(response);
      console.log(response);
      if (retData['response'].cod === 0)
          cbFunction(retData['data']);
      else 
        console.log(retData['response'].message);
    }
  }).fail((response) => {
    console.log(response);    
  });    
}

export async function getRemoteData4(url, paramData, type, header, cbFunction, async, test) {  //Chiamata Axios - Funziona bene (speriamo)
  let data = isNullEmpty(paramData) ? null : qs.stringify(paramData);
  let config = {
    method: type,
    url: url,
    headers: header,    
  };
  
  axios(config)
  .then(response => {       
      debugger;     
      if (test) {
        cbFunction(response.data);
      }
      else {
        let retData = response.data;
        console.log(JSON.stringify(response.data));
        if (retData['response'].cod === 0)
            cbFunction(retData['data']);
        else 
          console.log(retData['response'].message);
      }
  })
  .catch(error => {
    debugger;
    console.log(error);
  }); 
}

export async function callAPI(url, paramData, type, header, cbFunctionOK, cbFunctionKO, async, test) {
  var xhr = new XMLHttpRequest();
  xhr.withCredentials = false;

  xhr.onerror = function(err){
    debugger;
    cbFunctionKO(err);
  };

  xhr.addEventListener("readystatechange", function() {
    if (this.readyState === 4 && this.status === 200) {
      if (test) {
        cbFunctionOK(this.responseText);
      }
      else {
        debugger;
        let retData = isNullEmpty(this.responseText) ? [] : JSON.parse(this.responseText);        
        cbFunctionOK(retData);        
      }           
    }
    else if (this.readyState === 4 && this.status === 404)
        cbFunctionKO(this.responseText + this.statusText);
  });
  
  xhr.open(type, url);
  xhr.setRequestHeader("Content-Type", header);
  xhr.async = async; 

  if (!isNullEmpty(paramData))
      xhr.send(paramData);  
  else
      xhr.send();
}

export function handleMouseMove(event) {
  var eventDoc, doc, body;

  event = event || window.event; // IE-ism

  // If pageX/Y aren't available and clientX/Y are,
  // calculate pageX/Y - logic taken from jQuery.
  // (This is to support old IE)
  if (event.pageX == null && event.clientX != null) {
      eventDoc = (event.target && event.target.ownerDocument) || document;
      doc = eventDoc.documentElement;
      body = eventDoc.body;

      event.pageX = event.clientX +
        (doc && doc.scrollLeft || body && body.scrollLeft || 0) -
        (doc && doc.clientLeft || body && body.clientLeft || 0);
      event.pageY = event.clientY +
        (doc && doc.scrollTop  || body && body.scrollTop  || 0) -
        (doc && doc.clientTop  || body && body.clientTop  || 0 );
  }

  //console.log ('X: ' + event.pageX + ' - Y:' + event.pageY);

  //if (document.getElementById('TabMonitor').ej2_instances[0].selected<Index === 0 && event.pageX >= 680 && event.pageX <= 735 && event.pageY >= 620 && event.pageY <= 635) 
}

export function leapYear(year)
{
  return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
}

export function getDayName(dateStr, locale) { 
  var date = new Date(dateStr); 
  return date.toLocaleDateString(locale, { weekday: 'long' }); 
}

export function isNullEmpty(valore, multiArray) {
  try {
      if (multiArray) {
          var _wRet = false;
          for (var i = 0; i < valore.length; i++) {
              if (isNullEmpty(valore[i])) {
                  _wRet = true;
                  break;
              }
          }
          return _wRet;
      } else {
          switch (true) {
              case valore === null:
                  return true;
                  break;
              case Object.prototype.toString.call(valore) === '[object Date]':
                  return false;
                  break;
              case typeof valore === 'undefined':
              case typeof valore === 'null':
                  return true
                  break;
              case typeof valore === 'string':
                  if (valore.toString().trim() == "null" || valore.toString().trim() == "undefined") {
                      return true;
                  } else if (valore.toString().trim().length > 0) {
                      return false;
                  } else {
                      return true;
                  }
                  break;
              case typeof valore === 'boolean':
                  return false;
                  break;
              case typeof valore === 'number':
                  return false;
                  break;
              case typeof valore === 'object':
                  if (Object.keys(valore).length > 0) {
                      return false;
                  } else {
                      return true;
                  }
                  break;
          }
      }

  }
  catch (ex) {
      return true;
  }
}